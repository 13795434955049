import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../contexts/AuthContext';
import { tempUrl, useStateContext } from '../../../contexts/ContextProvider';
import { ShowTableAnggotaKoperasiSimpananWajib } from '../../../components/ShowTable';
import { FetchErrorHandling } from '../../../components/FetchErrorHandling';
import { SearchBar, Loader, ButtonModifier } from '../../../components';
import { Container, Card, Form, Row, Col } from 'react-bootstrap';
import { Box, Pagination, Button, Card as MuiCard, CardHeader, CardActionArea, CardMedia } from '@mui/material';
import { formatDate } from '../../../constants/helper';
import Carousel from 'react-elastic-carousel';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const TampilAnggotaKoperasiKeluar = () => {
  const { user, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [noAnggota, setNoAnggota] = useState('');
  const [namaAnggota, setNamaAnggota] = useState('');
  const [noRekeningBpr, setNoRekeningBpr] = useState('');
  const [noRekeningSimpanPinjam, setNoRekeningSimpanPinjam] = useState('');
  const [noTelp, setNoTelp] = useState('');
  const [tglMasuk, setTglMasuk] = useState('');
  const [tglKeluar, setTglKeluar] = useState('');
  const [totalSimpananWajib, setTotalSimpananWajib] = useState('');
  const [simpananWajib, setSimpananWajib] = useState('');
  const [simpananPokok, setSimpananPokok] = useState('');
  const [tglMutasiTerakhir, setTglMutasiTerakhir] = useState('');
  const [unitAwal, setUnitAwal] = useState('');
  const [unitAktif, setUnitAktif] = useState('');

  // Detail Data Anggota
  const [tempatLahirAnggota, setTempatLahirAnggota] = useState('');
  const [tglLahirAnggota, setTglLahirAnggota] = useState('');
  const [jenisKelaminAnggota, setJenisKelaminAnggota] = useState('');
  const [golonganDarahAnggota, setGolonganDarahAnggota] = useState('');
  const [alamatAnggota, setAlamatAnggota] = useState('');
  const [rtAnggota, setRtAnggota] = useState('');
  const [rwAnggota, setRwAnggota] = useState('');
  const [kelurahanAnggota, setKelurahanAnggota] = useState('');
  const [kecamatanAnggota, setKecamatanAnggota] = useState('');
  const [agamaAnggota, setAgamaAnggota] = useState('');
  const [statusPerkawinanAnggota, setStatusPerkawinanAnggota] = useState('');
  const [kewarganegaraanAnggota, setKewarganegaraanAnggota] = useState('');
  const [gambar, setGambar] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [simpananWajibChilds, setSimpananWajibChilds] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState('');

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 },
  ];

  useEffect(() => {
    id && getAnggotaKoperasiById();
  }, [id]);

  useEffect(() => {
    getSimpananWajibChilds();
  }, [page, searchTerm]);

  const getSimpananWajibChilds = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/simpananWajibChildsPaginationByAnggota?search_query=${searchTerm}&page=${page}&limit=${limit}`, {
        anggotaKoperasiId: id,
        _id: user.id,
        token: user.token,
      });
      setQuery(searchTerm);
      setSimpananWajibChilds(response.data.simpananWajibChilds);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getAnggotaKoperasiById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/anggotaKoperasis/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoAnggota(response.data.noAnggota);
      setNamaAnggota(response.data.namaAnggota);
      setNoRekeningBpr(response.data.noRekeningBpr);
      setNoRekeningSimpanPinjam(response.data.noRekeningSimpanPinjam);
      setNoTelp(response.data.noTelp);
      setTglMasuk(formatDate(response.data.tglMasuk));
      setTglKeluar(response.data.tglKeluar ? formatDate(response.data.tglKeluar) : '');
      setSimpananWajib(response.data.simpananWajib.toLocaleString('en-US'));
      setSimpananPokok(response.data.simpananPokok.toLocaleString('en-US'));
      setTglMutasiTerakhir(response.data.tglMutasiTerakhir ? formatDate(response.data.tglMutasiTerakhir) : '');
      setUnitAwal(response.data.unitAwal.namaUnit);
      setUnitAktif(response.data.unitAktif.namaUnit);

      // Detail Data Anggota
      setTempatLahirAnggota(response.data.tempatLahirAnggota);
      if (formatDate(response.data.tglLahirAnggota).substring(0, 3) !== 'NaN') {
        setTglLahirAnggota(formatDate(response.data.tglLahirAnggota));
      }
      setJenisKelaminAnggota(response.data.jenisKelaminAnggota);
      setGolonganDarahAnggota(response.data.golonganDarahAnggota);
      setAlamatAnggota(response.data.alamatAnggota);
      setRtAnggota(response.data.rtAnggota);
      setRwAnggota(response.data.rwAnggota);
      setKelurahanAnggota(response.data.kelurahanAnggota);
      setKecamatanAnggota(response.data.kecamatanAnggota);
      setAgamaAnggota(response.data.agamaAnggota);
      setStatusPerkawinanAnggota(response.data.statusPerkawinanAnggota);
      setKewarganegaraanAnggota(response.data.kewarganegaraanAnggota);
      setGambar(response.data.gambar);

      // Find Sum Simpanan Wajib
      const sumSimpananWajib = await axios.post(`${tempUrl}/sumSimpananWajibChildsByAnggota`, {
        anggotaKoperasiId: id,
        _id: user.id,
        token: user.token,
      });
      setTotalSimpananWajib(sumSimpananWajib.data['SUM(`simpananWajib`)'].toLocaleString('en-US'));
    }
    setLoading(false);
  };

  const deleteAnggotaKoperasi = async (id) => {
    setLoading(true);
    try {
      // Delete Gambar Anggota Koperasi
      console.log(gambar.length);
      for (let i = 0; i < gambar.length; i++) {
        let image = firebase.storage().refFromURL(gambar[i].linkGambar);

        image
          .delete()
          .then(function () {
            console.log('image deleted');
          })
          .catch(function (error) {
            // An error occurred!
            console.log('an error occurred');
          });
      }

      // Delete Anggota Koperasi
      await axios.post(`${tempUrl}/deleteAnggotaKoperasi/${id}`, {
        _id: user.id,
        token: user.token,
      });
      getSimpananWajibChilds();
      setNoAnggota('');
      setNamaAnggota('');
      setNoRekeningBpr('');
      setNoRekeningSimpanPinjam('');
      setNoTelp('');
      setTglMasuk('');
      setTglKeluar('');
      setSimpananWajib('');
      setSimpananPokok('');
      setTglMutasiTerakhir('');
      setUnitAwal('');
      setUnitAktif('');
      navigate('/anggotaKoperasi');
    } catch (error) {
      if (error.response.data.message.includes('foreign key')) {
        alert(`${namaAnggota} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const textRight = {
    textAlign: screenSize >= 650 && 'right',
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      <Button variant="outlined" color="secondary" onClick={() => navigate('/anggotaKoperasiKeluar')} sx={{ marginRight: 2 }}>
        {'< Kembali'}
      </Button>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Anggota Koperasi Keluar</h5>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier id={id} kode={id} addLink={null} editLink={`/anggotaKoperasiKeluar/${id}/edit`} editFotoLink={`/anggotaKoperasiKeluar/${id}/editFoto`} deleteUser={deleteAnggotaKoperasi} nameUser={namaAnggota} />
      </Box>

      {id && gambar.length !== 0 && (
        <Carousel breakPoints={breakPoints} sx={carouselStyle}>
          {gambar.length !== 0 &&
            gambar.map((img, i) => (
              <MuiCard sx={carouselCard} elevation={10}>
                <CardActionArea>
                  <CardHeader title={`Gambar`} subheader={`No. ${i + 1}`} />
                  <CardMedia
                    component="img"
                    height="100%"
                    src={img.linkGambar}
                    alt={noAnggota}
                    sx={oldImageCardMedia}
                    onClick={() => {
                      window.open(img.linkGambar, '_blank', 'noopener,noreferrer');
                    }}
                  />
                </CardActionArea>
              </MuiCard>
            ))}
        </Carousel>
      )}

      {id && (
        <Container>
          <hr />
          <Form>
            <Card>
              <Card.Header>Anggota Koperasi</Card.Header>
              <Card.Body>
                <Box sx={showDataContainer}>
                  <Box sx={showDataWrapper}>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            No. Anggota :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control disabled value={noAnggota} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Nama Anggota :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={namaAnggota} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            No. Rekening BPR :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={noRekeningBpr} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            No. Rekening SimTab UUO-SPI :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={noRekeningSimpanPinjam} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            No. Telepon (HP) :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={noTelp} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[showDataWrapper, secondWrapper]}>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Tanggal Masuk :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={tglMasuk} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Tanggal Keluar :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={tglKeluar} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Simpanan Wajib :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={simpananWajib} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Total Simpanan Wajib :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={totalSimpananWajib} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Simpanan Pokok :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={simpananPokok} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Tgl. Mutasi Terakhir :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={tglMutasiTerakhir} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Unit Awal :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={unitAwal} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Unit Aktif :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={unitAktif} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: '15px' }}>
              <Card.Header>Detail Data Anggota</Card.Header>
              <Card.Body>
                <Box sx={showDataContainer}>
                  <Box sx={showDataWrapper}>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Nama Anggota :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control disabled value={namaAnggota} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Tempat/Tgl. Lahir :
                          </Form.Label>
                          <Col sm="3">
                            <Form.Control disabled value={tempatLahirAnggota} />
                          </Col>
                          <Col sm="4">
                            <Form.Control disabled value={tglLahirAnggota} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Jenis Kelamin :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control disabled value={jenisKelaminAnggota} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Golongan Darah :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control disabled value={golonganDarahAnggota} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Agama :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control disabled value={agamaAnggota} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Status Perkawinan :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control disabled value={statusPerkawinanAnggota} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Kewarganegaraan :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control disabled value={kewarganegaraanAnggota} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[showDataWrapper, secondWrapper]}>
                    <Card>
                      <Card.Header>Alamat</Card.Header>
                      <Card.Body>
                        <Row>
                          <Col>
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                              <Form.Label column sm="5" style={textRight}>
                                Alamat :
                              </Form.Label>
                              <Col sm="7">
                                <Form.Control disabled value={alamatAnggota} />
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                              <Form.Label column sm="5" style={textRight}>
                                RT/RW :
                              </Form.Label>
                              <Col sm="3">
                                <Form.Control disabled value={rtAnggota} />
                              </Col>
                              <Col sm="4">
                                <Form.Control disabled value={rwAnggota} />
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                              <Form.Label column sm="5" style={textRight}>
                                Kel/Desa :
                              </Form.Label>
                              <Col sm="7">
                                <Form.Control disabled value={kelurahanAnggota} />
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                              <Form.Label column sm="5" style={textRight}>
                                Kecamatan :
                              </Form.Label>
                              <Col sm="7">
                                <Form.Control disabled value={kecamatanAnggota} />
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button variant="contained" type="submit" color="primary" disableElevation>
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableAnggotaKoperasiSimpananWajib id={id} currentPosts={simpananWajibChilds} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination count={pages} page={page + 1} onChange={handleChange} color="primary" size={screenSize <= 600 ? 'small' : 'large'} />
      </Box>
    </Container>
  );
};

export default TampilAnggotaKoperasiKeluar;

const buttonModifierContainer = {
  mt: 4,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const downloadButtons = {
  mt: 4,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const searchBarContainer = {
  pt: 6,
  display: 'flex',
  justifyContent: 'center',
};

const tableContainer = {
  pt: 4,
  display: 'flex',
  justifyContent: 'center',
};

const showDataContainer = {
  mt: 4,
  display: 'flex',
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
};

const showDataWrapper = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  maxWidth: {
    md: '40vw',
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const carouselStyle = {
  display: 'flex',
  height: '200px',
};

const carouselCard = {
  m: 'auto',
  mt: 2,
  maxWidth: '500px',
  maxHeight: '500px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'primary',
};

const oldImageCardMedia = {
  display: 'flex',
  maxHeight: '150px',
};
