import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader, SearchBar } from "../../../../components";
import { FetchErrorHandling } from "../../../../components/FetchErrorHandling";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Colors } from "../../../../constants/styles";
import {
  Box,
  Paper,
  Alert,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NumericFormat } from "react-number-format";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const UbahSetoranTabunganUmum = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const [tglSetor, setTglSetor] = useState("");
  const [noRekeningTabunganUmum, setNoRekeningTabunganUmum] = useState("");
  const [jumlahSetor, setJumlahSetor] = useState("");
  const [noBuktiSetor, setNoBuktiSetor] = useState("");
  const [kodeKartu, setKodeKartu] = useState("");
  const [hariBulan, setHariBulan] = useState(0);

  const [batasMinValidasiSetoranTab, setBatasMinValidasiSetoranTab] = useState(`${setting.batasMinValidasiSetoranTab}`);

  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");

  const [kodeKartuTabungans, setKodeKartuTabungans] = useState([]);

  const [searchTermKodeKartuTabungan, setSearchTermKodeKartuTabungan] = useState("");
  const [openKodeKartuTabungan, setOpenKodeKartuTabungan] = useState(false);

  const { id } = useParams();

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermKodeKartuTabungan(query);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClickOpenKodeKartuTabungan = () => {
    setOpenKodeKartuTabungan(true);
  };

  const handleCloseKodeKartuTabungan = () => {
    setOpenKodeKartuTabungan(false);
  };

  const lastday = (tglSetor) => {
    let year = tglSetor.getFullYear();
    let month = tglSetor.getMonth();

    let date = new Date(year, month + 1, 0);
    return date.getDate();
  };

  useEffect(() => {
    getKodeKartuTabunganFirstTime();
  }, []);

  useEffect(() => {
    getSetoranTabunganUmumById();
  }, []);

  useEffect(() => {
    getKodeKartuTabungan();
  }, [page, searchTermKodeKartuTabungan]);

  const getSetoranTabunganUmumById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/setoranTabunganUmums/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoRekeningTabunganUmum(response.data.noRekeningTabunganUmum);
      setTglSetor(new Date(response.data.tglSetor));
      setJumlahSetor(response.data.jumlahSetor.toLocaleString("en-US"));
      setNoBuktiSetor(response.data.noBuktiSetor);
      setHariBulan(response.data.hariBulan);

      setNoAnggota(response.data.anggotakoperasis.noAnggota);
      setNamaAnggota(response.data.anggotakoperasis.namaAnggota);
      setKodeKartu(response.data.kodekartutabungan.kodeKodeKartuTabungan);
    }
  };

  const getKodeKartuTabunganFirstTime = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/kodeKartuTabungansPagination?search_query=${searchTermKodeKartuTabungan}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTermKodeKartuTabungan);
      setKodeKartuTabungans(response.data.kodeKartuTabungans);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getKodeKartuTabungan = async () => {
    try {
      const response = await axios.post(
        `${tempUrl}/kodeKartuTabungansPagination?search_query=${searchTermKodeKartuTabungan}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTermKodeKartuTabungan);
      setKodeKartuTabungans(response.data.kodeKartuTabungans);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      setIsFetchError(true);
    }
  };

  const updatePembukaanTabunganUmum = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);

          let isApprove = false;
          if (parseInt(jumlahSetor.replace(/,/g, "")) <= parseInt(batasMinValidasiSetoranTab)) {
            isApprove = true;
          }

          await axios.post(`${tempUrl}/updateSetoranTabunganUmum/${id}`, {
            noRekeningTabunganUmum,
            tglSetor,
            noAnggota,
            hariBulan,
            jumlahSetor: jumlahSetor.replace(/,/g, ""),
            noBuktiSetor,
            kodeKartu,
            isApprove: isApprove,
            tglApprove: isApprove === true && tglSetor,
            userIdApprove: isApprove === true && user.id,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/setoranTabunganUmum/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error);
      }
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Setoran Tabungan Umum</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Setoran Tabungan Umum</h5>
      <hr />
      <Card>
        <Card.Header>Setoran Tabungan Umum</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updatePembukaanTabunganUmum}>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    No. Rekening Tabungan Umum <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control disabled value={noRekeningTabunganUmum} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    Tanggal <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      customInput={<Form.Control required />}
                      selected={tglSetor}
                      onChange={(date) => {
                        setTglSetor(date);
                        setHariBulan(lastday(date));
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    No. Anggota <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={noAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    Nama Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={namaAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    No. Rekening Tabungan Umum <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={noRekeningTabunganUmum}
                      onChange={(e) => setNoRekeningTabunganUmum(e.target.value.toUpperCase())}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    Jumlah Setor <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <NumericFormat
                      required
                      value={jumlahSetor}
                      thousandSeparator
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setJumlahSetor(values.formattedValue);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    No. Bukti Setor <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control disabled value={noBuktiSetor} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    Kode Kartu Tabungan UUO-SPI <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={kodeKartu}
                      readOnly
                      placeholder="Pilih..."
                      onClick={() => handleClickOpenKodeKartuTabungan()}
                      isInvalid={kodeKartu.length === 0 && true}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/setoranTabunganUmum/${id}`)}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button variant="contained" startIcon={<SaveIcon />} type="submit">
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openKodeKartuTabungan}
        onClose={handleCloseKodeKartuTabungan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Kode Kartu Tabungan`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button variant="contained" type="submit" color="primary" disableElevation>
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} className={classes.tableRightBorder}>
                      Kode Kartu Tabungan
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama Kode Kartu Tabungan</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kodeKartuTabungans.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setKodeKartu(user.kodeKodeKartuTabungan);
                        handleCloseKodeKartuTabungan();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.kodeKodeKartuTabungan}
                      </TableCell>
                      <TableCell>{user.namaKodeKartuTabungan}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UbahSetoranTabunganUmum;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
