import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import { FetchErrorHandling } from "../../../../components/FetchErrorHandling";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Colors } from "../../../../constants/styles";
import { Box, Alert, Button, Snackbar } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const ApproveTarikTabunganUmum = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const [tglApprove, setTglApprove] = useState(new Date());

  const { id } = useParams();

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const approveTarikTabunganUmum = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/approveTarikTabunganUmum/${id}`, {
            userIdApprove: user.id,
            tglApprove: tglApprove,
            isApprove: true,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/tarikTabunganUmum/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error);
      }
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Tarik Tabungan Umum</h3>
      <h5 style={{ fontWeight: 400 }}>Approve Tarik Tabungan Umum</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form noValidate validated={validated} onSubmit={approveTarikTabunganUmum}>
          <Row>
            <Col sm={6}>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                <Form.Label column sm="5" style={textRight}>
                  Tanggal Approve :
                </Form.Label>
                <Col sm="7">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    customInput={<Form.Control required />}
                    selected={tglApprove}
                    onChange={(date) => {
                      setTglApprove(date);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(`/tarikTabunganUmum/${id}`)}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Form>
      </Box>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default ApproveTarikTabunganUmum;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};
