import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { findMonth, formatDate } from "../../../constants/helper";
import {
  Box,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Container, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { useDownloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";

const LaporanShuSimpananWajib = () => {
  const tableRef = useRef(null);
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const [perTanggal, setPerTanggal] = useState(new Date());
  const [unitId, setUnitId] = useState("");
  const [namaUnit, setNamaUnit] = useState("");
  const [urut, setUrut] = useState("Group Unit");
  const [saldoShuSimpananWajib, setSaldoShuSimpananWajib] = useState(0);
  const [totalSimpananWajibSemuaAnggota, setTotalSimpananWajibSemuaAnggota] =
    useState(0);

  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lapSimpananWajibDataRekap, setLapSimpananWajibDataRekap] = useState(
    []
  );
  const [lapSimpananWajibDataRinci, setLapSimpananWajibDataRinci] = useState(
    []
  );

  const [previewExcelRekap, setPreviewExcelRekap] = useState(false);
  const [previewExcelRinci, setPreviewExcelRinci] = useState(false);
  const [error, setError] = useState(false);

  let unitOptions = units.map((unit) => ({
    label: `${unit.kodeUnit} - ${unit.namaUnit}`,
    id: unit.id,
  }));

  const handleChangeUrut = (event) => {
    setUrut(event.target.value);
    setPreviewExcelRekap(false);
    setPreviewExcelRinci(false);
    setLapSimpananWajibDataRinci([]);
  };

  const tampilPdfRekap = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      let tempPerTanggal = `${perTanggal.getFullYear()}-12-31`;

      const findLastShuSimpananWajib = await axios.post(
        `${tempUrl}/lastShuSimpananWajib`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      let tempShuSimpananWajibTerakhir =
        findLastShuSimpananWajib.data.shuSimpananWajib;
      setSaldoShuSimpananWajib(tempShuSimpananWajibTerakhir);

      // Find Total Simpanan Wajib Anggota Belum Keluar (filter tanggal)
      const findTotalAllSimpananWajib = await axios.post(
        `${tempUrl}/sumAllSimpananWajibChildsAnggotaBlmKeluarLaporanShuSimwa`,
        {
          tglKeluar: tempPerTanggal,
          tglRapatTahunan: setting.tglRapatTahunan,
          _id: user.id,
          token: user.token,
        }
      );
      setTotalSimpananWajibSemuaAnggota(findTotalAllSimpananWajib.data);

      setLoading(true);

      let laporanSimpananWajib = await axios.post(
        `${tempUrl}/laporanSimpananWajib`,
        {
          perTanggal: tempPerTanggal,
          unitId,
          urut,
          _id: user.id,
          token: user.token,
        }
      );
      setLapSimpananWajibDataRekap(laporanSimpananWajib.data);

      setPreviewExcelRekap(!previewExcelRekap);
      setPreviewExcelRinci(false);
      setLoading(false);
      setUnitId("");
      setNamaUnit("");
    }
  };

  const tampilPdfRinci = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      let tempPerTanggal = `${perTanggal.getFullYear()}-12-31`;

      const findLastShuSimpananWajib = await axios.post(
        `${tempUrl}/lastShuSimpananWajib`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      let tempShuSimpananWajibTerakhir =
        findLastShuSimpananWajib.data.shuSimpananWajib;
      setSaldoShuSimpananWajib(tempShuSimpananWajibTerakhir);

      // Find Total Simpanan Wajib Anggota Belum Keluar (filter tanggal)
      const findTotalAllSimpananWajib = await axios.post(
        `${tempUrl}/sumAllSimpananWajibChildsAnggotaBlmKeluarLaporanShuSimwa`,
        {
          tglKeluar: tempPerTanggal,
          tglRapatTahunan: setting.tglRapatTahunan,
          _id: user.id,
          token: user.token,
        }
      );
      setTotalSimpananWajibSemuaAnggota(findTotalAllSimpananWajib.data);

      setLoading(true);

      let laporanSimpananWajib = await axios.post(
        `${tempUrl}/laporanSimpananWajib`,
        {
          perTanggal: tempPerTanggal,
          unitId,
          urut,
          _id: user.id,
          token: user.token,
        }
      );
      setLapSimpananWajibDataRinci(laporanSimpananWajib.data);

      setPreviewExcelRekap(false);
      setPreviewExcelRinci(!previewExcelRinci);
      setLoading(false);
      setUnitId("");
      setNamaUnit("");
    }
  };

  useEffect(() => {
    getUnitsData();
  }, []);

  const getUnitsData = async () => {
    const response = await axios.post(`${tempUrl}/units`, {
      _id: user.id,
      token: user.token,
    });
    setUnits(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const list = [];
  let tempTotalJanuari = 0;
  let tempTotalFebruari = 0;
  let tempTotalMaret = 0;
  let tempTotalApril = 0;
  let tempTotalMei = 0;
  let tempTotalJuni = 0;
  let tempTotalJuli = 0;
  let tempTotalAgustus = 0;
  let tempTotalSeptember = 0;
  let tempTotalOktober = 0;
  let tempTotalNovember = 0;
  let tempTotalDesember = 0;
  let tempTotalSimwaAnggotaPerTanggal = 0;
  let tempTotalSimwaPerTanggal = 0;
  let tempTotalSaldoSimpananWajib = 0;
  let tempTotalSaldoAkhirMinTwoYear = 0;
  let tempTotalSaldoAkhirMinOneYearTotal = 0;
  let tempTotalSaldoAkhirMinOneYear = 0;
  let tempTotalSimpok = 0;
  let tempTotalShu = 0;
  let tempNo = 1;

  let keysLapSimpananWajibDataRinci = Object.keys(lapSimpananWajibDataRinci);

  let laporanRinciGroupUnit =
    previewExcelRinci === true &&
    urut === "Group Unit" &&
    keysLapSimpananWajibDataRinci.length > 0;

  let laporanRinciNomorAnggota =
    previewExcelRinci === true &&
    urut === "Nomor Anggota" &&
    lapSimpananWajibDataRinci.length > 0;

  if (laporanRinciGroupUnit) {
    let tempTotalSimpokPerUnit = 0;
    let tempTotalSaldoAkhirMinOneYearPerUnit = 0;
    let tempTotalSaldoSimwaAnggotaPerTanggalPerUnit = 0;
    let tempTotalSaldoSimwaAnggotaSdTanggalPerUnit = 0;
    let tempTotalSaldoShuPerUnit = 0;

    list.push(
      <tr style={tableText}>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorderTextRight}>
          {saldoShuSimpananWajib.toLocaleString("en-US")}
        </th>
      </tr>
    );

    for (let i = 0; i < keysLapSimpananWajibDataRinci.length; i++) {
      let tempObjectLapSimpananWajibDataRinci =
        lapSimpananWajibDataRinci[keysLapSimpananWajibDataRinci[i]];

      let tempArrayLapSimpananWajibDataRinci = Object.keys(
        tempObjectLapSimpananWajibDataRinci
      );

      let firstLapData =
        tempObjectLapSimpananWajibDataRinci[
          tempArrayLapSimpananWajibDataRinci[0]
        ];

      for (let j = 0; j < tempArrayLapSimpananWajibDataRinci.length; j++) {
        // Temp Data
        let data =
          tempObjectLapSimpananWajibDataRinci[
            tempArrayLapSimpananWajibDataRinci[j]
          ];

        // Find Simwa Januari
        let findSimpananWajibJanuari = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            1
        );

        // Add Simwa Januari
        if (findSimpananWajibJanuari) {
          tempTotalJanuari += findSimpananWajibJanuari.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibJanuari.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibJanuari.simpananWajib;
        }

        // Find Simwa Februari
        let findSimpananWajibFebruari = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            2
        );

        // Add Simwa Februari
        if (findSimpananWajibFebruari) {
          tempTotalFebruari += findSimpananWajibFebruari.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibFebruari.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibFebruari.simpananWajib;
        }

        // Find Simwa Maret
        let findSimpananWajibMaret = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            3
        );

        // Add Simwa Maret
        if (findSimpananWajibMaret) {
          tempTotalMaret += findSimpananWajibMaret.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibMaret.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibMaret.simpananWajib;
        }

        // Find Simwa April
        let findSimpananWajibApril = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            4
        );

        // Add Simwa April
        if (findSimpananWajibApril) {
          tempTotalApril += findSimpananWajibApril.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibApril.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibApril.simpananWajib;
        }

        // Find Simwa Mei
        let findSimpananWajibMei = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            5
        );

        // Add Simwa Mei
        if (findSimpananWajibMei) {
          tempTotalMei += findSimpananWajibMei.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal += findSimpananWajibMei.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibMei.simpananWajib;
        }

        // Find Simwa Juni
        let findSimpananWajibJuni = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            6
        );

        // Add Simwa Juni
        if (findSimpananWajibJuni) {
          tempTotalJuni += findSimpananWajibJuni.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibJuni.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibJuni.simpananWajib;
        }

        // Find Simwa Juli
        let findSimpananWajibJuli = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            7
        );

        // Add Simwa Juli
        if (findSimpananWajibJuli) {
          tempTotalJuli += findSimpananWajibJuli.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibJuli.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibJuli.simpananWajib;
        }

        // Find Simwa Agustus
        let findSimpananWajibAgustus = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            8
        );

        // Add Simwa Agustus
        if (findSimpananWajibAgustus) {
          tempTotalAgustus += findSimpananWajibAgustus.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibAgustus.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibAgustus.simpananWajib;
        }

        // Find Simwa September
        let findSimpananWajibSeptember = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            9
        );

        // Add Simwa September
        if (findSimpananWajibSeptember) {
          tempTotalSeptember += findSimpananWajibSeptember.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibSeptember.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibSeptember.simpananWajib;
        }

        // Find Simwa Oktober
        let findSimpananWajibOktober = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            10
        );

        // Add Simwa Oktober
        if (findSimpananWajibOktober) {
          tempTotalOktober += findSimpananWajibOktober.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibOktober.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibOktober.simpananWajib;
        }

        // Find Simwa November
        let findSimpananWajibNovember = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            11
        );

        // Add Simwa November
        if (findSimpananWajibNovember) {
          tempTotalNovember += findSimpananWajibNovember.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibNovember.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibNovember.simpananWajib;
        }

        // Find Simwa Desember
        let findSimpananWajibDesember = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            12
        );

        // Add Simwa Desember
        if (findSimpananWajibDesember) {
          tempTotalDesember += findSimpananWajibDesember.simpananWajib;
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibDesember.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibDesember.simpananWajib;
        }

        list.push(
          <tr style={tableText}>
            <th style={tableBorderTextRight}>{tempNo}</th>
            <th style={tableBorder}>{data.namaAnggota}</th>
            <th style={tableBorder}>{data.noAnggota}</th>
            <th style={tableBorder}>{data.noRekeningBpr}</th>
            <th style={tableBorder}>{formatDate(data.tglMasuk)}</th>
            <th style={tableBorderTextRight}>
              {data.simpananPokok.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRight}>
              {data.saldoAkhirMinOneYear.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRight}>
              {tempTotalSimwaAnggotaPerTanggal.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRight}>
              {(
                data.saldoAkhirMinOneYear + tempTotalSimwaAnggotaPerTanggal
              ).toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRight}>
              {parseInt(
                ((data.saldoAkhirMinOneYear + tempTotalSimwaAnggotaPerTanggal) /
                  totalSimpananWajibSemuaAnggota) *
                  saldoShuSimpananWajib
              ).toLocaleString("en-US")}
            </th>
          </tr>
        );
        tempTotalShu += parseInt(
          ((data.saldoAkhirMinOneYear + tempTotalSimwaAnggotaPerTanggal) /
            totalSimpananWajibSemuaAnggota) *
            saldoShuSimpananWajib
        );

        tempTotalSimpok += data.simpananPokok;
        tempTotalSaldoAkhirMinTwoYear += data.saldoAkhirMinTwoYear;
        tempTotalSaldoAkhirMinOneYear += data.saldoAkhirMinOneYear;
        tempTotalSaldoSimpananWajib +=
          data.saldoAkhirMinOneYear + tempTotalSimwaAnggotaPerTanggal;

        // Find Subtotal Unit
        tempTotalSimpokPerUnit += data.simpananPokok;
        tempTotalSaldoAkhirMinOneYearPerUnit += data.saldoAkhirMinOneYear;
        tempTotalSaldoSimwaAnggotaPerTanggalPerUnit +=
          tempTotalSimwaAnggotaPerTanggal;
        tempTotalSaldoSimwaAnggotaSdTanggalPerUnit +=
          data.saldoAkhirMinOneYear + tempTotalSimwaAnggotaPerTanggal;

        tempTotalSimwaAnggotaPerTanggal = 0;

        tempNo++;
      }

      // Tampil Total Unit
      list.push(
        <tr style={tableText}>
          <th colspan="3" style={tableBorderColor}>
            SUBTOTAL {firstLapData.unitAktif.kodeUnit} -{" "}
            {firstLapData.unitAktif.namaUnit}
          </th>
          <th style={tableBorderTextRightColor}></th>
          <th style={tableBorderTextRightColor}></th>
          <th style={tableBorderTextRightColor}>
            {tempTotalSimpokPerUnit.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRightColor}>
            {tempTotalSaldoAkhirMinOneYearPerUnit.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRightColor}>
            {tempTotalSaldoSimwaAnggotaPerTanggalPerUnit.toLocaleString(
              "en-US"
            )}
          </th>
          <th style={tableBorderTextRightColor}>
            {tempTotalSaldoSimwaAnggotaSdTanggalPerUnit.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRightColor}>
            {parseInt(
              ((tempTotalSaldoAkhirMinOneYear +
                tempTotalSaldoSimwaAnggotaPerTanggalPerUnit) /
                totalSimpananWajibSemuaAnggota) *
                saldoShuSimpananWajib
            ).toLocaleString("en-US")}
          </th>
        </tr>
      );

      tempTotalSimpokPerUnit = 0;
      tempTotalSaldoAkhirMinOneYearPerUnit = 0;
      tempTotalSaldoSimwaAnggotaPerTanggalPerUnit = 0;
      tempTotalSaldoSimwaAnggotaSdTanggalPerUnit = 0;
      tempTotalSaldoShuPerUnit = 0;
    }
    list.push(
      <tr style={tableText}>
        <th style={tableBorder}></th>
        <th style={tableBorder}>TOTAL AKUMULASI</th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorderTextRight}>
          {tempTotalSimpok.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalSaldoAkhirMinOneYear.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalSimwaPerTanggal.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalSaldoSimpananWajib.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalShu.toLocaleString("en-US")}
        </th>
      </tr>
    );
  } else if (laporanRinciNomorAnggota) {
    list.push(
      <tr style={tableText}>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorderTextRight}>
          {saldoShuSimpananWajib.toLocaleString("en-US")}
        </th>
      </tr>
    );

    for (let i = 0; i < lapSimpananWajibDataRinci.length; i++) {
      // Temp Data
      let data = lapSimpananWajibDataRinci[i];

      // Find Simwa Januari
      let findSimpananWajibJanuari = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          1
      );

      // Add Simwa Januari
      if (findSimpananWajibJanuari) {
        tempTotalJanuari += findSimpananWajibJanuari.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal +=
          findSimpananWajibJanuari.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibJanuari.simpananWajib;
      }

      // Find Simwa Februari
      let findSimpananWajibFebruari = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          2
      );

      // Add Simwa Februari
      if (findSimpananWajibFebruari) {
        tempTotalFebruari += findSimpananWajibFebruari.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal +=
          findSimpananWajibFebruari.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibFebruari.simpananWajib;
      }

      // Find Simwa Maret
      let findSimpananWajibMaret = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          3
      );

      // Add Simwa Maret
      if (findSimpananWajibMaret) {
        tempTotalMaret += findSimpananWajibMaret.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal += findSimpananWajibMaret.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibMaret.simpananWajib;
      }

      // Find Simwa April
      let findSimpananWajibApril = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          4
      );

      // Add Simwa April
      if (findSimpananWajibApril) {
        tempTotalApril += findSimpananWajibApril.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal += findSimpananWajibApril.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibApril.simpananWajib;
      }

      // Find Simwa Mei
      let findSimpananWajibMei = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          5
      );

      // Add Simwa Mei
      if (findSimpananWajibMei) {
        tempTotalMei += findSimpananWajibMei.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal += findSimpananWajibMei.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibMei.simpananWajib;
      }

      // Find Simwa Juni
      let findSimpananWajibJuni = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          6
      );

      // Add Simwa Juni
      if (findSimpananWajibJuni) {
        tempTotalJuni += findSimpananWajibJuni.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal += findSimpananWajibJuni.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibJuni.simpananWajib;
      }

      // Find Simwa Juli
      let findSimpananWajibJuli = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          7
      );

      // Add Simwa Juli
      if (findSimpananWajibJuli) {
        tempTotalJuli += findSimpananWajibJuli.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal += findSimpananWajibJuli.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibJuli.simpananWajib;
      }

      // Find Simwa Agustus
      let findSimpananWajibAgustus = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          8
      );

      // Add Simwa Agustus
      if (findSimpananWajibAgustus) {
        tempTotalAgustus += findSimpananWajibAgustus.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal +=
          findSimpananWajibAgustus.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibAgustus.simpananWajib;
      }

      // Find Simwa September
      let findSimpananWajibSeptember = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          9
      );

      // Add Simwa September
      if (findSimpananWajibSeptember) {
        tempTotalSeptember += findSimpananWajibSeptember.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal +=
          findSimpananWajibSeptember.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibSeptember.simpananWajib;
      }

      // Find Simwa Oktober
      let findSimpananWajibOktober = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          10
      );

      // Add Simwa Oktober
      if (findSimpananWajibOktober) {
        tempTotalOktober += findSimpananWajibOktober.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal +=
          findSimpananWajibOktober.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibOktober.simpananWajib;
      }

      // Find Simwa November
      let findSimpananWajibNovember = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          11
      );

      // Add Simwa November
      if (findSimpananWajibNovember) {
        tempTotalNovember += findSimpananWajibNovember.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal +=
          findSimpananWajibNovember.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibNovember.simpananWajib;
      }

      // Find Simwa Desember
      let findSimpananWajibDesember = data.simpananWajibChilds.find(
        (simpananWajib) =>
          new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
            1 ==
          12
      );

      // Add Simwa Desember
      if (findSimpananWajibDesember) {
        tempTotalDesember += findSimpananWajibDesember.simpananWajib;
        tempTotalSimwaAnggotaPerTanggal +=
          findSimpananWajibDesember.simpananWajib;
        tempTotalSimwaPerTanggal += findSimpananWajibDesember.simpananWajib;
      }

      list.push(
        <tr style={tableText}>
          <th style={tableBorderTextRight}>{tempNo}</th>
          <th style={tableBorder}>{data.namaAnggota}</th>
          <th style={tableBorder}>{data.noAnggota}</th>
          <th style={tableBorder}>{data.noRekeningBpr}</th>
          <th style={tableBorder}>{formatDate(data.tglMasuk)}</th>
          <th style={tableBorderTextRight}>
            {data.simpananPokok.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {data.saldoAkhirMinOneYear.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {tempTotalSimwaAnggotaPerTanggal.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {(
              data.saldoAkhirMinOneYear + tempTotalSimwaAnggotaPerTanggal
            ).toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {parseInt(
              ((data.saldoAkhirMinOneYear + tempTotalSimwaAnggotaPerTanggal) /
                totalSimpananWajibSemuaAnggota) *
                saldoShuSimpananWajib
            ).toLocaleString("en-US")}
          </th>
        </tr>
      );

      tempTotalShu += parseInt(
        ((data.saldoAkhirMinOneYear + tempTotalSimwaAnggotaPerTanggal) /
          totalSimpananWajibSemuaAnggota) *
          saldoShuSimpananWajib
      );

      tempTotalSimpok += data.simpananPokok;
      tempTotalSaldoAkhirMinTwoYear += data.saldoAkhirMinTwoYear;
      tempTotalSaldoAkhirMinOneYear += data.saldoAkhirMinOneYear;
      tempTotalSaldoSimpananWajib +=
        data.saldoAkhirMinOneYear + tempTotalSimwaAnggotaPerTanggal;
      tempTotalSimwaAnggotaPerTanggal = 0;

      tempNo++;
    }
    list.push(
      <tr style={tableText}>
        <th style={tableBorder}></th>
        <th style={tableBorder}>TOTAL AKUMULASI</th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorder}></th>
        <th style={tableBorderTextRight}>
          {tempTotalSimpok.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalSaldoAkhirMinOneYear.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalSimwaPerTanggal.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalSaldoSimpananWajib.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalShu.toLocaleString("en-US")}
        </th>
      </tr>
    );
  }

  const listRekap = [];

  let keysLapSimpananWajibDataRekap = Object.keys(lapSimpananWajibDataRekap);

  let laporanRekapGroupUnit =
    previewExcelRekap === true &&
    urut === "Group Unit" &&
    keysLapSimpananWajibDataRekap.length > 0;

  let tempTotalAnggota = 0;
  let tempTotalSimpokPerUnit = 0;
  let tempTotalSaldoAkhirMinOneYearPerUnit = 0;
  let tempTotalSimwaPerTanggalPerUnit = 0;
  let tempTotalSaldoSimpananWajibPerUnit = 0;
  let tempTotalSimwaAkumulasi = 0;

  if (laporanRekapGroupUnit) {
    let tempLastWilayah = "";
    let tempTotalAnggotaPerWilayah = 0;
    let tempTotalSimpokPerWilayah = 0;
    let tempTotalSaldoAkhirMinOneYearTotalPerWilayah = 0;
    let tempTotalSaldoSimpananWajibPerWilayah = 0;
    let tempTotalSimwaAkumulasiPerWilayah = 0;

    for (let i = 0; i < keysLapSimpananWajibDataRekap.length; i++) {
      let tempObjectLapSimpananWajibDataRekap =
        lapSimpananWajibDataRekap[keysLapSimpananWajibDataRekap[i]];

      tempTotalAnggota += tempObjectLapSimpananWajibDataRekap.length;

      let tempArrayLapSimpananWajibDataRekap = Object.keys(
        tempObjectLapSimpananWajibDataRekap
      );

      let firstLapData =
        tempObjectLapSimpananWajibDataRekap[
          tempArrayLapSimpananWajibDataRekap[0]
        ];

      if (i == 0) {
        tempLastWilayah = `${firstLapData.unitAktif.wilayah.kodeWilayah} - ${firstLapData.unitAktif.wilayah.namaWilayah}`;
      }

      // Tampil Total Wilayah
      if (
        tempLastWilayah !==
        `${firstLapData.unitAktif.wilayah.kodeWilayah} - ${firstLapData.unitAktif.wilayah.namaWilayah}`
      ) {
        listRekap.push(
          <tr style={tableText}>
            <th colspan="3" style={tableBorderColor}>
              {tempLastWilayah}
            </th>
            <th style={tableBorderTextRightColor}>
              {tempTotalAnggotaPerWilayah.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRightColor}>
              {tempTotalSimpokPerWilayah.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRightColor}>
              {tempTotalSaldoAkhirMinOneYearTotalPerWilayah.toLocaleString(
                "en-US"
              )}
            </th>
            <th style={tableBorderTextRightColor}>
              {tempTotalSaldoSimpananWajibPerWilayah.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRightColor}>
              {tempTotalSimwaAkumulasiPerWilayah.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRightColor}>
              {parseInt(
                ((tempTotalSaldoAkhirMinOneYearTotalPerWilayah +
                  tempTotalSaldoSimpananWajibPerWilayah) /
                  totalSimpananWajibSemuaAnggota) *
                  saldoShuSimpananWajib
              ).toLocaleString("en-US")}
            </th>
          </tr>
        );

        tempLastWilayah = `${firstLapData.unitAktif.wilayah.kodeWilayah} - ${firstLapData.unitAktif.wilayah.namaWilayah}`;
        tempTotalAnggotaPerWilayah = 0;
        tempTotalSimpokPerWilayah = 0;
        tempTotalSaldoAkhirMinOneYearTotalPerWilayah = 0;
        tempTotalSaldoSimpananWajibPerWilayah = 0;
        tempTotalSimwaAkumulasiPerWilayah = 0;
      }

      tempTotalAnggotaPerWilayah += tempObjectLapSimpananWajibDataRekap.length;

      for (let j = 0; j < tempArrayLapSimpananWajibDataRekap.length; j++) {
        // Temp Data
        let data =
          tempObjectLapSimpananWajibDataRekap[
            tempArrayLapSimpananWajibDataRekap[j]
          ];

        // Find Simwa Januari
        let findSimpananWajibJanuari = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            1
        );

        // Count Simwa Januari
        if (findSimpananWajibJanuari) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibJanuari.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibJanuari.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibJanuari.simpananWajib;
        }

        // Find Simwa Februari
        let findSimpananWajibFebruari = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            2
        );

        // Count Simwa Februari
        if (findSimpananWajibFebruari) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibFebruari.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibFebruari.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibFebruari.simpananWajib;
        }

        // Find Simwa Maret
        let findSimpananWajibMaret = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            3
        );

        // Count Simwa Maret
        if (findSimpananWajibMaret) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibMaret.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibMaret.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibMaret.simpananWajib;
        }

        // Find Simwa April
        let findSimpananWajibApril = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            4
        );

        // Count Simwa April
        if (findSimpananWajibApril) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibApril.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibApril.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibApril.simpananWajib;
        }

        // Find Simwa Mei
        let findSimpananWajibMei = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            5
        );

        // Count Simwa Mei
        if (findSimpananWajibMei) {
          tempTotalSimwaAnggotaPerTanggal += findSimpananWajibMei.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibMei.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit += findSimpananWajibMei.simpananWajib;
        }

        // Find Simwa Juni
        let findSimpananWajibJuni = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            6
        );

        // Count Simwa Juni
        if (findSimpananWajibJuni) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibJuni.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibJuni.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibJuni.simpananWajib;
        }

        // Find Simwa Juli
        let findSimpananWajibJuli = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            7
        );

        // Count Simwa Juli
        if (findSimpananWajibJuli) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibJuli.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibJuli.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibJuli.simpananWajib;
        }

        // Find Simwa Agustus
        let findSimpananWajibAgustus = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            8
        );

        // Count Simwa Agustus
        if (findSimpananWajibAgustus) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibAgustus.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibAgustus.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibAgustus.simpananWajib;
        }

        // Find Simwa September
        let findSimpananWajibSeptember = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            9
        );

        // Count Simwa September
        if (findSimpananWajibSeptember) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibSeptember.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibSeptember.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibSeptember.simpananWajib;
        }

        // Find Simwa Oktober
        let findSimpananWajibOktober = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            10
        );

        // Count Simwa Oktober
        if (findSimpananWajibOktober) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibOktober.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibOktober.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibOktober.simpananWajib;
        }

        // Find Simwa November
        let findSimpananWajibNovember = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            11
        );

        // Count Simwa November
        if (findSimpananWajibNovember) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibNovember.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibNovember.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibNovember.simpananWajib;
        }

        // Find Simwa Desember
        let findSimpananWajibDesember = data.simpananWajibChilds.find(
          (simpananWajib) =>
            new Date(simpananWajib.simpananwajib.tglSimpananWajib).getMonth() +
              1 ==
            12
        );

        // Count Simwa Desember
        if (findSimpananWajibDesember) {
          tempTotalSimwaAnggotaPerTanggal +=
            findSimpananWajibDesember.simpananWajib;
          tempTotalSimwaPerTanggal += findSimpananWajibDesember.simpananWajib;
          tempTotalSimwaPerTanggalPerUnit +=
            findSimpananWajibDesember.simpananWajib;
        }

        tempTotalSimpok += data.simpananPokok;
        tempTotalSimpokPerWilayah += data.simpananPokok;
        tempTotalSimpokPerUnit += data.simpananPokok;
        tempTotalSaldoAkhirMinTwoYear += data.saldoAkhirMinTwoYear;
        tempTotalSaldoAkhirMinOneYearPerUnit += data.saldoAkhirMinOneYear;
        tempTotalSaldoAkhirMinOneYearTotal += data.saldoAkhirMinOneYear;
        tempTotalSaldoAkhirMinOneYearTotalPerWilayah +=
          data.saldoAkhirMinOneYear;
        tempTotalSaldoAkhirMinOneYear += data.saldoAkhirMinOneYear;
        tempTotalSaldoSimpananWajibPerUnit += data.totalSimpananWajib;
        tempTotalSaldoSimpananWajib += data.totalSimpananWajib;
        tempTotalSaldoSimpananWajibPerWilayah += data.totalSimpananWajib;
        tempTotalSimwaAnggotaPerTanggal = 0;
      }

      // akumulasi sd thn skrg
      let tempTotalSimwaAkumulasiPerUnit =
        tempTotalSaldoAkhirMinOneYear + tempTotalSaldoSimpananWajibPerUnit;
      tempTotalSimwaAkumulasi += tempTotalSimwaAkumulasiPerUnit;
      tempTotalSimwaAkumulasiPerWilayah += tempTotalSimwaAkumulasiPerUnit;

      listRekap.push(
        <tr style={tableText}>
          <th style={tableBorder}>{tempNo}</th>
          <th style={tableBorder}>{firstLapData.unitAktif.kodeUnit}</th>
          <th style={tableBorder}>{firstLapData.unitAktif.namaUnit}</th>
          <th style={tableBorderTextRight}>
            {tempObjectLapSimpananWajibDataRekap.length.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {tempTotalSimpokPerUnit.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {tempTotalSaldoAkhirMinOneYear.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {tempTotalSaldoSimpananWajibPerUnit.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {tempTotalSimwaAkumulasiPerUnit.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {parseInt(
              ((tempTotalSaldoAkhirMinOneYear +
                tempTotalSaldoSimpananWajibPerUnit) /
                totalSimpananWajibSemuaAnggota) *
                saldoShuSimpananWajib
            ).toLocaleString("en-US")}
          </th>
        </tr>
      );

      tempNo++;
      tempTotalSimpokPerUnit = 0;
      tempTotalSaldoSimpananWajibPerUnit = 0;
      tempTotalSaldoAkhirMinOneYear = 0;
      tempTotalSimwaPerTanggalPerUnit = 0;
      tempTotalSimwaAkumulasiPerUnit = 0;

      // Wilayah Terakhir
      if (i + 1 === keysLapSimpananWajibDataRekap.length) {
        tempLastWilayah = `${firstLapData.unitAktif.wilayah.kodeWilayah} - ${firstLapData.unitAktif.wilayah.namaWilayah}`;

        listRekap.push(
          <tr style={tableText}>
            <th colspan="3" style={tableBorderColor}>
              {tempLastWilayah}
            </th>
            <th style={tableBorderTextRightColor}>
              {tempTotalAnggotaPerWilayah.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRightColor}>
              {tempTotalSimpokPerWilayah.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRightColor}>
              {tempTotalSaldoAkhirMinOneYearTotalPerWilayah.toLocaleString(
                "en-US"
              )}
            </th>
            <th style={tableBorderTextRightColor}>
              {tempTotalSaldoSimpananWajibPerWilayah.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRightColor}>
              {tempTotalSimwaAkumulasiPerWilayah.toLocaleString("en-US")}
            </th>
            <th style={tableBorderTextRightColor}>
              {parseInt(
                ((tempTotalSaldoAkhirMinOneYearTotalPerWilayah +
                  tempTotalSaldoSimpananWajibPerWilayah) /
                  totalSimpananWajibSemuaAnggota) *
                  saldoShuSimpananWajib
              ).toLocaleString("en-US")}
            </th>
          </tr>
        );

        tempLastWilayah = `${firstLapData.unitAktif.wilayah.kodeWilayah} - ${firstLapData.unitAktif.wilayah.namaWilayah}`;
        tempTotalAnggotaPerWilayah = 0;
        tempTotalSimpokPerWilayah = 0;
        tempTotalSaldoAkhirMinOneYearTotalPerWilayah = 0;
        tempTotalSaldoSimpananWajibPerWilayah = 0;
        tempTotalSimwaAkumulasiPerWilayah = 0;
      }
    }
    listRekap.push(
      <tr style={tableText}>
        <th style={tableBorder}></th>
        <th style={tableBorder}>TOTAL AKUMULASI</th>
        <th style={tableBorder}></th>
        <th style={tableBorderTextRight}>
          {tempTotalAnggota.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalSimpok.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalSaldoAkhirMinOneYearTotal.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalSaldoSimpananWajib.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {tempTotalSimwaAkumulasi.toLocaleString("en-US")}
        </th>
        <th style={tableBorderTextRight}>
          {parseInt(
            ((parseInt(tempTotalSaldoAkhirMinOneYearTotal) +
            parseInt(tempTotalSaldoSimpananWajib)) /
            parseInt(totalSimpananWajibSemuaAnggota)) *
            parseInt(saldoShuSimpananWajib)
          ).toLocaleString("en-US")}
        </th>
      </tr>
    );
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanShuSimpananWajib",
    sheet: "LaporanShuSimpananWajib",
  });

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    let doc = new jsPDF("l", "mm", [200, 300]);
    if (previewExcelRinci) {
      doc = new jsPDF("l", "mm", [594, 420]);
    }
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    if (previewExcelRinci) {
      doc.text(`Laporan SHU Simpanan Wajib`, 280, 30);
    } else {
      doc.text(`Laporan SHU Simpanan Wajib`, 125, 30);
    }
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    function alignColRinci(data) {
      var col = data.column.index;
      if (col >= 7 && col <= 25) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didParseCell: function (data, i) {
        let filterHeadTable = data.row.index === 0;

        if (previewExcelRinci) {
          filterHeadTable = data.row.index <= 2;
        }

        if (filterHeadTable) {
          data.cell.styles.fillColor = "gray";
          data.cell.styles.textColor = "white";
        }

        // Text Align Right for Number
        alignColRinci(data, i);
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan SHU Simpanan Wajib</h5>
      <hr />
      <Box sx={spacingTop}>
        <Row>
          <FormControl sx={{ marginTop: 1, marginBottom: 2 }}>
            <FormLabel id="demo-controlled-radio-buttons-group">Urut</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              defaultValue="Group Unit"
              value={urut}
              onChange={handleChangeUrut}
            >
              <FormControlLabel
                value="Group Unit"
                control={<Radio />}
                label="Group Unit"
              />
              <FormControlLabel
                value="Nomor Anggota"
                control={<Radio />}
                label="Nomor Anggota"
              />
            </RadioGroup>
          </FormControl>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Per Tahun :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  required
                  dateFormat="yyyy"
                  selected={perTanggal}
                  showYearPicker
                  customInput={<Form.Control required />}
                  onChange={(date) => {
                    setPerTanggal(date);
                    setPreviewExcelRinci(false);
                    setPreviewExcelRekap(false);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Group Unit :
              </Form.Label>
              <Col sm="8">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={unitOptions}
                  renderInput={(params) => (
                    <TextField
                      error={error && unitId.length === 0 && true}
                      size="small"
                      placeholder="SEMUA UNIT"
                      {...params}
                    />
                  )}
                  onChange={(e, value) => {
                    if (value) {
                      setUnitId(value.id);
                      setNamaUnit(value.label);
                    } else {
                      setUnitId("");
                      setNamaUnit("");
                    }
                    setPreviewExcelRekap(false);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <ButtonGroup variant="contained" style={{ marginTop: "20px" }}>
          <Button startIcon={<SearchIcon />} onClick={tampilPdfRekap}>
            REKAP
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={tampilPdfRinci}
          >
            RINCI
          </Button>
        </ButtonGroup>
      </Box>

      <div style={{ marginTop: "10px" }}>
        {(previewExcelRinci || previewExcelRekap) && (
          <ButtonGroup variant="outlined">
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={onDownload}
            >
              Excel
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={() => downloadPdf()}
            >
              PDF
            </Button>
          </ButtonGroup>
        )}

        <table ref={tableRef}>
          {previewExcelRinci && (
            <tbody>
              <tr>
                <th style={tableBorderTitle} colspan="3">
                  {setting.namaPerusahaan}
                </th>
              </tr>
              <tr>
                <th style={tableBorderTitle} colspan="3">
                  RINCI DAFTAR PEMBAGIAN SHU KEPADA ANGGOTA BERDASARKAN
                  PERBANDINGAN SIMPANAN WAJIBNYA
                </th>
              </tr>
              <tr>
                <th style={tableBorderTitle} colspan="3">
                  TAHUN BUKU {perTanggal.getFullYear()}
                </th>
              </tr>
              <tbody id="table">
                <tr>
                  <th style={tableBorder} rowspan="2">
                    No.
                  </th>
                  <th style={tableBorder} rowspan="2">
                    Nama Anggota
                  </th>
                  <th style={tableBorder} rowspan="2">
                    No. Anggota
                  </th>
                  <th style={tableBorder} rowspan="2">
                    No. Rekening
                  </th>
                  <th style={tableBorder} rowspan="2">
                    Tgl. Masuk
                  </th>
                  <th style={tableBorder} rowspan="2">
                    Simpok
                  </th>
                  <th style={tableBorder} colspan="3">
                    Saldo Simpanan Wajib Anggota
                  </th>
                  <th style={tableBorder} rowspan="2">
                    SHU berdasarkan SIMWA
                  </th>
                </tr>
                <tr>
                  <th style={tableBorder}>
                    S/D {perTanggal.getFullYear() - 1}
                  </th>
                  <th style={tableBorder}>{perTanggal.getFullYear()}</th>
                  <th style={tableBorder}>s.d. {perTanggal.getFullYear()}</th>
                </tr>
                {list}
              </tbody>
            </tbody>
          )}
          {previewExcelRekap && (
            <tbody>
              <tr>
                <th style={tableBorderTitle} colspan="3">
                  {setting.namaPerusahaan}
                </th>
              </tr>
              <tr>
                <th style={tableBorderTitle} colspan="3">
                  REKAP DAFTAR PEMBAGIAN SHU KEPADA ANGGOTA BERDASARKAN
                  PERBANDINGAN SIMPANAN WAJIBNYA
                </th>
              </tr>
              <tr>
                <th style={tableBorderTitle} colspan="3">
                  TAHUN BUKU {perTanggal.getFullYear()}
                </th>
              </tr>
              <tbody id="table">
                <tr>
                  <th style={tableBorder}>No.</th>
                  <th style={tableBorder}>Kode Group Unit</th>
                  <th style={tableBorder}>Nama Group Unit</th>
                  <th style={tableBorder}>Anggota</th>
                  <th style={tableBorder}>Total Simpok</th>
                  <th style={tableBorder}>
                    Total Simwa s/d {perTanggal.getFullYear() - 1}
                  </th>
                  <th style={tableBorder}>
                    Total Simwa {perTanggal.getFullYear()}
                  </th>
                  <th style={tableBorder}>
                    Total Simwa s/d {perTanggal.getFullYear()}
                  </th>
                  <th style={tableBorder}>SHU berdasarkan SIMWA</th>
                </tr>
                {listRekap}
              </tbody>
            </tbody>
          )}
        </table>
      </div>
    </Container>
  );
};

export default LaporanShuSimpananWajib;

const spacingTop = {
  mt: 4,
};

const tableBorderTitle = {
  textAlign: "left",
};

const tableBorder = {
  border: "1px solid black",
  textAlign: "center",
  minWidth: "100px",
};

const tableBorderTextRight = {
  border: "1px solid black",
  textAlign: "right",
  minWidth: "100px",
};

const tableBorderTextRightColor = {
  border: "1px solid black",
  textAlign: "right",
  backgroundColor: "#96D4D4",
  minWidth: "100px",
};

const tableBorderColor = {
  border: "1px solid black",
  backgroundColor: "#96D4D4",
  textAlign: "left",
};
