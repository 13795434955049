import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableMutasiUnit } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Box, Pagination, Button, ButtonGroup } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { formatDate } from "../../../constants/helper";

const TampilMutasiUnit = () => {
  const tableRef = useRef(null);
  const reportTemplateRef = useRef(null);
  const { user, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [noSkMutasiUnit, setNoSkMutasiUnit] = useState("");
  const [tglMutasiUnit, setTglMutasiUnit] = useState("");
  const [simpananPokokMutasiUnit, setSimpananPokokMutasiUnit] = useState("");
  const [simpananWajibMutasiUnit, setSimpananWajibMutasiUnit] = useState("");
  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");
  const [unitLama, setUnitLama] = useState("");
  const [unitBaru, setUnitBaru] = useState("");

  const [mutasiUnitReport, setMutasiUnitReport] = useState([]);

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [previewBuktiMutasiAnggota, setPreviewBuktiMutasiAnggota] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [mutasiUnits, setMutasiUnits] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");
  const PER_PAGE = 20;

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id ? getMutasiUnitById() : setPreviewBuktiMutasiAnggota(false);
  }, [id]);

  useEffect(() => {
    getMutasiUnits();
  }, [page, searchTerm]);

  const getMutasiUnits = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/mutasiUnitsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setMutasiUnits(response.data.mutasiUnits);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
      setPreviewBuktiMutasiAnggota(false);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getMutasiUnitReportData = async () => {
    try {
      const response = await axios.post(`${tempUrl}/mutasiUnits`, {
        _id: user.id,
        token: user.token,
      });
      setMutasiUnitReport(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const getMutasiUnitById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/mutasiUnits/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoSkMutasiUnit(response.data.noSkMutasiUnit);
      setTglMutasiUnit(formatDate(response.data.tglMutasiUnit));
      setSimpananPokokMutasiUnit(response.data.simpananPokokMutasiUnit);
      setSimpananWajibMutasiUnit(response.data.simpananWajibMutasiUnit);
      setNoAnggota(response.data.anggotakoperasis.noAnggota);
      setNamaAnggota(response.data.anggotakoperasis.namaAnggota);
      setUnitLama(response.data.unitLama.namaUnit);
      setUnitBaru(response.data.unitBaru.namaUnit);
      setPreviewBuktiMutasiAnggota(false);
    }
    setLoading(false);
  };

  const deleteMutasiUnit = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteMutasiUnit/${id}`, {
        noAnggota,
        _id: user.id,
        token: user.token,
      });
      getMutasiUnits();
      setNoSkMutasiUnit("");
      setTglMutasiUnit("");
      setSimpananPokokMutasiUnit("");
      setSimpananWajibMutasiUnit("");
      setNoAnggota("");
      setNamaAnggota("");
      setUnitLama("");
      setUnitBaru("");
      navigate("/mutasiUnit");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${namaAnggota} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Mutasi Unit`, 85, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "MutasiUnit",
    sheet: "DaftarMutasiUnit",
  });

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      <h3>Mutasi Anggota</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Mutasi Unit</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          {id ? (
            <Button
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => {
                setPreviewBuktiMutasiAnggota(!previewBuktiMutasiAnggota);
              }}
            >
              Cetak Bukti
            </Button>
          ) : (
            <>
              <Button
                color="primary"
                startIcon={<SearchIcon />}
                onClick={() => {
                  setPreviewPdf(!previewPdf);
                  getMutasiUnitReportData();
                  setPreviewExcel(false);
                }}
              >
                PDF
              </Button>
              <Button
                color="secondary"
                startIcon={<SearchIcon />}
                onClick={() => {
                  setPreviewExcel(!previewExcel);
                  getMutasiUnitReportData();
                  setPreviewPdf(false);
                }}
              >
                Excel
              </Button>
            </>
          )}
        </ButtonGroup>
      </Box>
      {previewBuktiMutasiAnggota && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} id="content" style={pdfContainer}>
            <h5 style={titleCetak}>
              BUKTI MUTASI KEANGGOTAAN KOPERASI KARYAWAN INSANI
            </h5>
            <p style={marginTopText}>
              Telah dilakukan pemindahan saldo Simpok dan Simwa Anggota Koperasi
              Karyawan Insani pada tanggal {tglMutasiUnit} karena mutasi unit
              kerja dengan data sebagai berikut :
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <table style={borderTable}>
                <thead>
                  <tr style={borderTable}>
                    <th style={borderTable}>Nama</th>
                    <th style={borderTable}>No. Anggota</th>
                    <th style={borderTable}>Unit Lama</th>
                    <th style={borderTable}>Unit Baru</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={borderTable}>
                    <td style={borderTable}>{namaAnggota}</td>
                    <td style={borderTable}>{noAnggota}</td>
                    <td style={borderTable}>{unitLama}</td>
                    <td style={borderTable}>{unitBaru}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={containerPerincian}>
              <div style={wrapperPerincian}>
                <p style={marginTopText}>
                  Dengan perincian pemindahan saldo sbb:
                </p>
                <div style={perincianStyle}>
                  <p style={textFontTitle}>a. Simpanan Pokok</p>
                  <p style={textFontNumber}>: Rp</p>
                  <div style={wrapperTextFontNumber}>
                    <p style={textFontTitle}>
                      {simpananPokokMutasiUnit.toLocaleString("en-US")}
                    </p>
                  </div>
                </div>
                <div style={perincianStyle}>
                  <p style={textFontTitleSecond}>b. Simpanan Wajib</p>
                  <div style={textFontNumberSecond}>
                    <p style={textFontTitle}>: Rp</p>
                    <div style={wrapperTextFontNumber}>
                      <p style={textFontTitle}>
                        {simpananWajibMutasiUnit.toLocaleString("en-US")}
                      </p>
                    </div>
                    <p style={{ marginLeft: "10px" }}>+</p>
                  </div>
                </div>
                <div style={displayFlexDiv}>
                  <p style={textFontTitleTotal}>Total</p>
                  <p style={textFontTitle}>: Rp</p>
                  <div style={wrapperTextFontNumber}>
                    <p style={textFontTitle}>
                      {(
                        simpananPokokMutasiUnit + simpananWajibMutasiUnit
                      ).toLocaleString("en-US")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <table style={borderTable}>
                <thead>
                  <tr style={borderTable}>
                    <th style={borderTable}>Dibuat</th>
                    <th style={borderTable}>Diperiksa</th>
                    <th style={borderTable}>Disetujui</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={borderTable}>
                    <td style={borderTable} width="150" height="80"></td>
                    <td style={borderTable} width="150" height="80"></td>
                    <td style={borderTable} width="150" height="80"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>No. SK</th>
                <th>Tgl.</th>
                <th>No. Anggota</th>
                <th>Nama Anggota</th>
                <th>Simpok</th>
                <th>Simwa</th>
                <th>Unit Lama</th>
                <th>Unit Baru</th>
              </tr>
            </thead>
            <tbody>
              {mutasiUnitReport.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.noSkMutasiUnit}</td>
                  <td>{formatDate(user.tglMutasiUnit)}</td>
                  <td>{user.anggotakoperasis.noAnggota}</td>
                  <td>{user.anggotakoperasis.namaAnggota}</td>
                  <td>
                    {user.simpananPokokMutasiUnit.toLocaleString("en-US")}
                  </td>
                  <td>
                    {user.simpananWajibMutasiUnit.toLocaleString("en-US")}
                  </td>
                  <td>{user.unitLama.namaUnit}</td>
                  <td>{user.unitBaru.namaUnit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>No. SK</th>
                <th>Tgl.</th>
                <th>No. Anggota</th>
                <th>Nama Anggota</th>
                <th>Simpok</th>
                <th>Simwa</th>
                <th>Unit Lama</th>
                <th>Unit Baru</th>
              </tr>
              {mutasiUnitReport.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.noSkMutasiUnit}</td>
                  <td>{formatDate(user.tglMutasiUnit)}</td>
                  <td>{user.anggotakoperasis.noAnggota}</td>
                  <td>{user.anggotakoperasis.namaAnggota}</td>
                  <td>
                    {user.simpananPokokMutasiUnit.toLocaleString("en-US")}
                  </td>
                  <td>
                    {user.simpananWajibMutasiUnit.toLocaleString("en-US")}
                  </td>
                  <td>{user.unitLama.namaUnit}</td>
                  <td>{user.unitBaru.namaUnit}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/mutasiUnit/tambahMutasiUnit`}
          editLink={`/mutasiUnit/${id}/edit`}
          deleteUser={deleteMutasiUnit}
          nameUser={namaAnggota}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. SK :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control disabled value={noSkMutasiUnit} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tanggal :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglMutasiUnit} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Anggota :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control disabled value={noAnggota} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Anggota :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaAnggota} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Simpanan Pokok :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={simpananPokokMutasiUnit.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Simpanan Wajib :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={simpananWajibMutasiUnit.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={(
                        simpananPokokMutasiUnit + simpananWajibMutasiUnit
                      ).toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Unit Lama :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={unitLama} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Unit Baru :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={unitBaru} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableMutasiUnit currentPosts={mutasiUnits} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilMutasiUnit;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

const titleCetak = {
  textAlign: "center",
  fontWeight: "bold",
  marginTop: "100px",
};

const containerPerincian = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const wrapperPerincian = {
  width: "500px",
  marginLeft: "150px",
};

const marginTopText = {
  marginTop: "30px",
  fontSize: "16px",
};

const perincianStyle = {
  display: "flex",
  marginBottom: "-10px",
};

const textFontTitle = {
  fontSize: "16px",
};

const textFontTitleSecond = {
  fontSize: "16px",
  marginRight: "45px",
};

const textFontTitleTotal = {
  fontSize: "16px",
  marginLeft: "97px",
  marginRight: "45px",
  fontWeight: "bold",
};

const textFontNumber = {
  fontSize: "16px",
  marginLeft: "40px",
};

const textFontNumberSecond = {
  display: "flex",
  height: "20px",
  borderBottom: "1px solid black",
};

const wrapperTextFontNumber = {
  textAlign: "right",
  width: "100px",
};

const displayFlexDiv = {
  display: "flex",
};

const borderTable = {
  fontSize: "16px",
  border: "1px solid",
  padding: "6px",
};
