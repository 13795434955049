import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UbahMutasiUnit = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noSkMutasiUnit, setNoSkMutasiUnit] = useState("");
  const [tglMutasiUnit, setTglMutasiUnit] = useState(null);
  const [simpananPokokMutasiUnit, setSimpananPokokMutasiUnit] = useState("");
  const [simpananWajibMutasiUnit, setSimpananWajibMutasiUnit] = useState("");
  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");
  const [unitLama, setUnitLama] = useState("");
  const [kodeUnitBaru, setKodeUnitBaru] = useState("");

  const [units, setUnits] = useState([]);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const unitOption = units.map((unit) => ({
    label: `${unit.kodeUnit} - ${unit.namaUnit}`,
  }));

  useEffect(() => {
    getMutasiUnitById();
    getUnits();
  }, []);

  const getMutasiUnitById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/mutasiUnits/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoSkMutasiUnit(response.data.noSkMutasiUnit);
      setTglMutasiUnit(new Date(response.data.tglMutasiUnit));
      setSimpananPokokMutasiUnit(response.data.simpananPokokMutasiUnit);
      setSimpananWajibMutasiUnit(response.data.simpananWajibMutasiUnit);
      setNoAnggota(response.data.anggotakoperasis.noAnggota);
      setNamaAnggota(response.data.anggotakoperasis.namaAnggota);
      setUnitLama(response.data.unitLama.namaUnit);
      setKodeUnitBaru(
        `${response.data.unitBaru.kodeUnit} - ${response.data.unitBaru.namaUnit}`
      );
    }
  };

  const getUnits = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/units`, {
        _id: user.id,
        token: user.token,
      });
      setUnits(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const updateMutasiUnit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      tglMutasiUnit !== null &&
      kodeUnitBaru.length != 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateMutasiUnit/${id}`, {
            noSkMutasiUnit,
            tglMutasiUnit,
            noAnggota,
            kodeUnitBaru: kodeUnitBaru.split(" -", 1)[0],
            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/mutasiUnit/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Mutasi Anggota</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Mutasi Unit</h5>
      <hr />
      <Card>
        <Card.Header>Mutasi Unit</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateMutasiUnit}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. SK :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noSkMutasiUnit} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tanggal <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      customInput={<Form.Control required />}
                      selected={tglMutasiUnit}
                      onChange={(date) => setTglMutasiUnit(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Anggota <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Anggota <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Simpanan Pokok :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={simpananPokokMutasiUnit.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Simpanan Wajib :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={simpananWajibMutasiUnit.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={(
                        simpananPokokMutasiUnit + simpananWajibMutasiUnit
                      ).toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Unit Lama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={unitLama} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Unit Baru <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={unitOption}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          error={error && kodeUnitBaru.length === 0 && true}
                          helperText={
                            error &&
                            kodeUnitBaru.length === 0 &&
                            "Unit Baru harus diisi!"
                          }
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeUnitBaru(value);
                      }}
                      defaultValue={kodeUnitBaru}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/mutasiUnit/${id}`)}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahMutasiUnit;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};
