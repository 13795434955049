import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahSimpananWajib = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [validated, setValidated] = useState(false);
  const [open, setOpen] = useState(false);
  const [tglSimpananWajib, setTglSimpananWajib] = useState(new Date());
  const [unitId, setUnitId] = useState("");
  const [kodeUnit, setKodeUnit] = useState("");
  const [totalSimpananWajib, setTotalSimpananWajib] = useState("");

  const [openUnit, setOpenUnit] = useState(false);
  const [searchTermUnit, setSearchTermUnit] = useState("");
  const [error, setError] = useState(false);
  const [unitsData, setUnitsData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermUnit(query);
  };

  const handleClickOpenUnit = () => {
    setOpenUnit(true);
  };

  const handleCloseUnit = () => {
    setOpenUnit(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getUnitsData();
  }, [page, searchTermUnit]);

  const getUnitsData = async () => {
    const response = await axios.post(
      `${tempUrl}/unitsPagination?search_query=${searchTermUnit}&page=${page}&limit=${limit}`,
      {
        _id: user.id,
        token: user.token,
      }
    );
    setQuery(searchTermUnit);
    setUnitsData(response.data.units);
    setPage(response.data.page);
    setPages(response.data.totalPage);
    setRows(response.data.totalRows);
  };

  const getAllAnggotaFromInputUnit = async (unitAktifId) => {
    setLoading(true);
    const allAnggota = await axios.post(
      `${tempUrl}/anggotaKoperasisByUnitSudahMasuk`,
      {
        tglSimpananWajib,
        unitAktifId,
        _id: user.id,
        token: user.token,
      }
    );
    setTotalSimpananWajib(allAnggota.data * setting.simpananWajib);
    setLoading(false);
  };

  const getAllAnggotaFromInputTgl = async (date) => {
    setLoading(true);
    const allAnggota = await axios.post(
      `${tempUrl}/anggotaKoperasisByUnitSudahMasuk`,
      {
        tglSimpananWajib: date,
        unitAktifId: unitId,
        _id: user.id,
        token: user.token,
      }
    );
    setTotalSimpananWajib(allAnggota.data * setting.simpananWajib);
    setLoading(false);
  };

  const saveSimpananWajib = async (e) => {
    e.preventDefault();

    let isFailedValidation = tglSimpananWajib === null || kodeUnit.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        let savedSimpananWajib = await axios.post(
          `${tempUrl}/saveSimpananWajib`,
          {
            tglSimpananWajib,
            totalSimpananWajib,
            kodeUnit: kodeUnit.split(" ", 1)[0],
            unitId,
            userIdInput: user.id,
            _id: user.id,
            token: user.token,
          }
        );
        setLoading(false);
        navigate(
          `/daftarSimpananWajib/simpananWajib/${savedSimpananWajib.data.id}`
        );
      } catch (error) {
        if (error.response.data.message) {
          alert(error.response.data.message);
          setLoading(false);
        }
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Simpan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Simpanan Wajib
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveSimpananWajib}>
          <Box sx={textFieldContainer}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Kode Unit <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={kodeUnit}
                      readOnly
                      placeholder="Pilih..."
                      onClick={() => handleClickOpenUnit()}
                      isInvalid={kodeUnit.length === 0 && true}
                    />
                    <Form.Control.Feedback type="invalid">
                      Kode Unit harus diisi!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Tanggal <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={tglSimpananWajib}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setTglSimpananWajib(date);
                        getAllAnggotaFromInputTgl(date);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Tanggal harus diisi!
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total Simpanan Wajib :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={totalSimpananWajib.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={textFieldStyle}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/daftarSimpananWajib")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={dividerStyle} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openUnit}
        onClose={handleCloseUnit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Unit`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode Unit
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unitsData.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setKodeUnit(`${user.kodeUnit} - ${user.namaUnit}`);
                        setUnitId(user.id);

                        getAllAnggotaFromInputUnit(user.id);
                        handleCloseUnit();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.kodeUnit}
                      </TableCell>
                      <TableCell>{user.namaUnit}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TambahSimpananWajib;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const textFieldContainer = {
  mt: 4,
  // display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldStyle = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
