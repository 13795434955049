import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader, SearchBar } from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Colors } from "../../../constants/styles";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Paper,
  Alert,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahPembayaranKeluar = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");

  const [noTandaTerima, setNoTandaTerima] = useState("");
  const [tglTandaTerima, setTglTandaTerima] = useState("");

  const [keluars, setKeluars] = useState([]);

  const [simpananPokok, setSimpananPokok] = useState(0);
  const [totalSimpananWajib, setTotalSimpananWajib] = useState(0);
  const [dja, setDja] = useState(0);
  const [total, setTotal] = useState(0);

  const [searchTermAnggotaKoperasi, setSearchTermAnggotaKoperasi] =
    useState("");
  const [openAnggotaKoperasi, setOpenAnggotaKoperasi] = useState(false);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermAnggotaKoperasi(query);
  };

  const handleClickOpenAnggotaKoperasi = () => {
    setOpenAnggotaKoperasi(true);
  };

  const handleCloseAnggotaKoperasi = () => {
    setOpenAnggotaKoperasi(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getKeluarsFirstTime();
  }, []);

  useEffect(() => {
    getKeluars();
  }, [page, searchTermAnggotaKoperasi]);

  // get anggota keluar yang blm bayar
  const getKeluarsFirstTime = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/keluarsBelumBayar`, {
        _id: user.id,
        token: user.token,
      });
      setQuery(searchTermAnggotaKoperasi);
      setKeluars(response.data);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getKeluars = async () => {
    try {
      const response = await axios.post(`${tempUrl}/keluarsBelumBayar`, {
        _id: user.id,
        token: user.token,
      });
      setQuery(searchTermAnggotaKoperasi);
      setKeluars(response.data);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);

      console.log(keluars);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const getPembayaranKeluarNextKode = async (noAnggota) => {
    try {
      const response = await axios.post(`${tempUrl}/pembayaranKeluarNextKode`, {
        noAnggota,
        tglTandaTerima,
        _id: user.id,
        token: user.token,
      });
      setNoTandaTerima(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const getPembayaranKeluarNextKodeByTglTandaTerima = async (
    tglTandaTerima
  ) => {
    try {
      const response = await axios.post(`${tempUrl}/pembayaranKeluarNextKode`, {
        noAnggota,
        tglTandaTerima,
        _id: user.id,
        token: user.token,
      });
      setNoTandaTerima(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const savePembayaranKeluar = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      noTandaTerima.length != 0 &&
      tglTandaTerima !== null
    ) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/savePembayaranKeluar`, {
          noTandaTerima,
          tglTandaTerima,
          noAnggota,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/pembayaranKeluar");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Mutasi Anggota</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Pembayaran Keluar</h5>
      <hr />
      <Card>
        <Card.Header>Pembayaran Keluar</Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={savePembayaranKeluar}
          >
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Tanggal <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      customInput={<Form.Control required />}
                      selected={tglTandaTerima}
                      onChange={(date) => {
                        setTglTandaTerima(date);
                        getPembayaranKeluarNextKodeByTglTandaTerima(date);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    No. Anggota <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={noAnggota}
                      readOnly
                      placeholder="Pilih..."
                      onClick={() => handleClickOpenAnggotaKoperasi()}
                      isInvalid={noAnggota.length === 0 && true}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Nama Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={namaAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    No. Tanda Terima <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={noTandaTerima}
                      onChange={(e) =>
                        setNoTandaTerima(e.target.value.toUpperCase())
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Simpanan Pokok <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={simpananPokok.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total Simpanan Wajib <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={totalSimpananWajib.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Dana Jasa Anggota <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={dja.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total Pembayaran Keluar <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={total.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/pembayaranKeluar")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openAnggotaKoperasi}
        onClose={handleCloseAnggotaKoperasi}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Anggota Koperasi`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No.
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keluars?.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNoAnggota(user.anggotakoperasis.noAnggota);
                        setNamaAnggota(user.anggotakoperasis.namaAnggota);
                        getPembayaranKeluarNextKode(
                          user.anggotakoperasis.noAnggota
                        );

                        setSimpananPokok(user.anggotakoperasis.simpananPokok);
                        setTotalSimpananWajib(
                          user.total -
                            user.anggotakoperasis.simpananPokok -
                            user.dja
                        );
                        setDja(user.dja);
                        setTotal(user.total);

                        handleCloseAnggotaKoperasi();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.anggotakoperasis.noAnggota}
                      </TableCell>
                      <TableCell>{user.anggotakoperasis.namaAnggota}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TambahPembayaranKeluar;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
