import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahSimpananWajibChild = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [validated, setValidated] = useState(false);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [noBuktiSetor, setNoBuktiSetor] = useState("");
  const [tglSimpananWajib, setTglSimpananWajib] = useState("");
  const [tglSimpananWajibDate, setTglSimpananWajibDate] = useState("");
  const [anggotaKoperasiId, setAnggotaKoperasiId] = useState("");
  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");
  const [simpananWajib, setSimpananWajib] = useState(
    `${setting.simpananWajib.toLocaleString("en-US")}`
  );
  const [anggotaKoperasiData, setAnggotaKoperasiData] = useState([]);
  const [searchTermAnggotaKoperasi, setSearchTermAnggotaKoperasi] =
    useState("");
  const [openAnggotaKoperasi, setOpenAnggotaKoperasi] = useState(false);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermAnggotaKoperasi(query);
  };

  const handleClickOpenAnggotaKoperasi = () => {
    setOpenAnggotaKoperasi(true);
  };

  const handleCloseCOA = () => {
    setOpenAnggotaKoperasi(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getSimpananWajib();
  }, [page, searchTermAnggotaKoperasi]);

  const getSimpananWajib = async () => {
    setLoading(true);
    const pickedSimpananWajib = await axios.post(
      `${tempUrl}/simpananWajibs/${id}`,
      {
        _id: user.id,
        token: user.token,
      }
    );
    setNoBuktiSetor(pickedSimpananWajib.data.noBuktiSetor);
    setTglSimpananWajib(formatDate(pickedSimpananWajib.data.tglSimpananWajib));
    setTglSimpananWajibDate(pickedSimpananWajib.data.tglSimpananWajib);
    getAnggotaKoperasiData(pickedSimpananWajib.data.unit.id);
    setLoading(false);
  };

  const getAnggotaKoperasiData = async (unitAktifId) => {
    const response = await axios.post(
      `${tempUrl}/anggotaKoperasisByUnitBlmKeluarPagination?search_query=${searchTermAnggotaKoperasi}&page=${page}&limit=${limit}`,
      {
        unitAktifId,
        _id: user.id,
        token: user.token,
      }
    );
    setQuery(searchTermAnggotaKoperasi);
    setAnggotaKoperasiData(response.data.anggotaKoperasis);
    setPage(response.data.page);
    setPages(response.data.totalPage);
    setRows(response.data.totalRows);
  };

  const saveSimpananWajibChild = async (e) => {
    e.preventDefault();

    if (simpananWajib.length === 0) {
      alert(`Simpanan Wajib belum diisi!`);
      return;
    }

    let isFailedValidation = noAnggota.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveSimpananWajibChild`, {
          tglSimpananWajib: tglSimpananWajibDate,
          simpananWajibId: id,
          anggotaKoperasiId,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/daftarSimpananWajib/simpananWajib/${id}`);
      } catch (error) {
        if (error.response.data.message) {
          alert(error.response.data.message);
          setLoading(false);
        }
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Simpan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Simpanan Wajib Anggota
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form
          noValidate
          validated={validated}
          onSubmit={saveSimpananWajibChild}
        >
          <Box sx={textFieldContainer}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Anggota <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noAnggota}
                      readOnly
                      placeholder="Pilih..."
                      onClick={() => handleClickOpenAnggotaKoperasi()}
                      isInvalid={noAnggota.length === 0 && true}
                    />
                  </Col>
                  <Form.Control.Feedback type="invalid">
                    No. Anggota harus diisi!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Anggota :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={namaAnggota}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noBuktiSetor}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tanggal :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tglSimpananWajib}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Simpanan Wajib :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      disabled
                      value={simpananWajib}
                      thousandSeparator
                      customInput={Form.Control}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={textFieldStyle}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() =>
                navigate(`/daftarSimpananWajib/simpananWajib/${id}`)
              }
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={dividerStyle} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openAnggotaKoperasi}
        onClose={handleCloseCOA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Anggota`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Anggota
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode Unit
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama Unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anggotaKoperasiData.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setAnggotaKoperasiId(user.id);
                        setNoAnggota(user.noAnggota);
                        setNamaAnggota(user.namaAnggota);
                        handleCloseCOA();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noAnggota}
                      </TableCell>
                      <TableCell>{user.namaAnggota}</TableCell>
                      <TableCell>{user.unitAktif.kodeUnit}</TableCell>
                      <TableCell>{user.unitAktif.namaUnit}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TambahSimpananWajibChild;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const textFieldContainer = {
  mt: 4,
  // display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldStyle = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
