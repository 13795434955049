import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Alert, Button, Snackbar } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UbahKeluar = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");

  const [noTandaTerima, setNoTandaTerima] = useState("");
  const [tglTandaTerima, setTglTandaTerima] = useState("");
  const [keluarId, setKeluarId] = useState(0);

  const [simpananPokok, setSimpananPokok] = useState(0);
  const [totalSimpananWajib, setTotalSimpananWajib] = useState(0);
  const [dja, setDja] = useState(0);
  const [total, setTotal] = useState(0);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getPembayaranKeluarById();
  }, []);

  const getPembayaranKeluarById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/pembayaranKeluars/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoTandaTerima(response.data.noTandaTerima);
      setTglTandaTerima(new Date(response.data.tglTandaTerima));
      setKeluarId(response.data.keluarId);
      setNamaAnggota(response.data.keluar.anggotakoperasis.namaAnggota);
      setNoAnggota(response.data.keluar.anggotakoperasis.noAnggota);

      let tempTotalSimpananPokok =
        response.data.keluar.total -
        response.data.keluar.anggotakoperasis.simpananPokok -
        response.data.keluar.dja;
      setSimpananPokok(response.data.keluar.anggotakoperasis.simpananPokok);
      setTotalSimpananWajib(tempTotalSimpananPokok);
      setDja(response.data.keluar.dja);
      setTotal(response.data.keluar.total);
    }
  };

  const updatePembayaranKeluar = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && tglTandaTerima !== null) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updatePembayaranKeluar/${id}`, {
            noTandaTerima,
            tglTandaTerima,
            keluarId,
            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/pembayaranKeluar/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Mutasi Anggota</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Pembayaran Keluar</h5>
      <hr />
      <Card>
        <Card.Header>Pembayaran Keluar</Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={updatePembayaranKeluar}
          >
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    No. Tanda Terima :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={noTandaTerima} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Tanggal Tanda Terima <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      customInput={<Form.Control required />}
                      selected={tglTandaTerima}
                      onChange={(date) => setTglTandaTerima(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    No. Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={noAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Nama Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={namaAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Simpanan Pokok :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={simpananPokok.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total Simpanan Wajib :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={totalSimpananWajib.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Dana Jasa Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={dja.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total Pembayaran Keluar :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={total.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/pembayaranKeluar/${id}`)}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahKeluar;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};
