import React, { useContext, useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./styles.scss";
import { Sidebar, Footer, ScrollToTop } from "./components";
import { AuthContext } from "./contexts/AuthContext";
import { useStateContext } from "./contexts/ContextProvider";
import {
  Login,
  ProfilUser,
  UbahProfilUser,
  DaftarUser,
  TambahUser,
  UbahUser,
  TampilSetting,
  UbahSetting,
  TampilWilayah,
  TambahWilayah,
  UbahWilayah,
  TampilUnit,
  TambahUnit,
  UbahUnit,
  TampilDaftarAnggotaKoperasi,
  TampilAnggotaKoperasi,
  TambahAnggotaKoperasi,
  UbahAnggotaKoperasi,
  UbahFotoAnggotaKoperasi,
  TampilDaftarAnggotaKoperasiKeluar,
  TampilAnggotaKoperasiKeluar,
  UbahAnggotaKoperasiKeluar,
  UbahFotoAnggotaKoperasiKeluar,
  TampilKodeKartuTabungan,
  TambahKodeKartuTabungan,
  UbahKodeKartuTabungan,
  TampilMutasiUnit,
  TambahMutasiUnit,
  UbahMutasiUnit,
  TampilKeluar,
  TambahKeluar,
  UbahKeluar,
  TampilPembayaranKeluar,
  TambahPembayaranKeluar,
  UbahPembayaranKeluar,
  TampilSaldoDja,
  TambahSaldoDja,
  UbahSaldoDja,
  TampilShuSimpananWajib,
  TambahShuSimpananWajib,
  UbahShuSimpananWajib,
  TampilDaftarSimpananWajib,
  TambahSimpananWajibSemuaUnit,
  TambahSimpananWajib,
  TampilSimpananWajib,
  TambahSimpananWajibChild,
  TampilSimpananWajibChild,
  LaporanSimpananWajib,
  RekapPerhitungan,
  LaporanShuSimpananWajib,
  TampilPembukaanTabunganUmum,
  TambahPembukaanTabunganUmum,
  UbahPembukaanTabunganUmum,
  ApprovePembukaanTabunganUmum,
  TampilSetoranTabunganUmum,
  TambahSetoranTabunganUmum,
  TambahSetoranTabunganUmumKolektif,
  UbahSetoranTabunganUmum,
  ApproveSetoranTabunganUmum,
  TampilTarikTabunganUmum,
  TambahTarikTabunganUmum,
  UbahTarikTabunganUmum,
  ApproveTarikTabunganUmum,
  KartuTabunganUmum,
} from "./pages/index";

import { FaBars } from "react-icons/fa";

const App = () => {
  const { screenSize, setScreenSize } = useStateContext();
  // const { collapseSidebar } = useProSidebar();
  const { user } = useContext(AuthContext);
  // const [open, setOpen] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const USERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user) {
      return children;
    }

    return <Navigate to="/login" />;
  };

  const PROFILUSERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.profilUser) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SETTINGRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.setting) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const DAFTARUSERRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.daftarUser) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const WILAYAHRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.wilayah) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const UNITRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.unit) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const ANGGOTAKOPERASIRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.anggotaKoperasi) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const ANGGOTAKOPERASIKELUARRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.anggotaKoperasiKeluar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KODEKARTUTABUNGANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.kodeKartuTabungan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const MUTASIUNITRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.mutasiUnit) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KELUARRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.keluar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PEMBAYARANKELUARRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.pembayaranKeluar) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SALDODJARoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.saldoDja) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SHUSIMPANANWAJIBRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.shuSimpananWajib) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SIMPANANWAJIBRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.simpananWajib) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPORANSIMPANANWAJIBRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.laporanSimpananWajib) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const REKAPPERHITUNGANRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.rekapPerhitungan) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const LAPORANSHUSIMPANANWAJIBRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.laporanShuSimpananWajib) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const PEMBUKAANTABUNGANUMUMRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.tabunganUmum) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const SETORANTABUNGANUMUMRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.tabunganUmum) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const TARIKTABUNGANUMUMRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.tabunganUmum) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  const KARTUTABUNGANUMUMRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (user.akses.tabunganUmum) {
      return children;
    }

    return <Navigate to="/unauthorized" />;
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      {user && (
        <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
      )}

      <main>
        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          {/* Profil User */}
          <Route
            path="/profilUser"
            element={
              <PROFILUSERRoute>
                <ProfilUser />
              </PROFILUSERRoute>
            }
          />
          <Route
            path="/profilUser/:id/edit"
            element={
              <PROFILUSERRoute>
                <UbahProfilUser />
              </PROFILUSERRoute>
            }
          />
          {/* Daftar User */}
          <Route
            path="/daftarUser"
            element={
              <DAFTARUSERRoute>
                <DaftarUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/:id"
            element={
              <DAFTARUSERRoute>
                <DaftarUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/:id/edit"
            element={
              <DAFTARUSERRoute>
                <UbahUser />
              </DAFTARUSERRoute>
            }
          />
          <Route
            path="/daftarUser/tambahUser"
            element={
              <DAFTARUSERRoute>
                <TambahUser />
              </DAFTARUSERRoute>
            }
          />
          {/* Setting */}
          <Route
            path="/setting"
            element={
              <SETTINGRoute>
                <TampilSetting />
              </SETTINGRoute>
            }
          />
          <Route
            path="/setting/:id/edit"
            element={
              <SETTINGRoute>
                <UbahSetting />
              </SETTINGRoute>
            }
          />
          {/* Wilayah */}
          <Route
            path="/wilayah"
            element={
              <WILAYAHRoute>
                <TampilWilayah />
              </WILAYAHRoute>
            }
          />
          <Route
            path="/wilayah/:id"
            element={
              <WILAYAHRoute>
                <TampilWilayah />
              </WILAYAHRoute>
            }
          />
          <Route
            path="/wilayah/:id/edit"
            element={
              <WILAYAHRoute>
                <UbahWilayah />
              </WILAYAHRoute>
            }
          />
          <Route
            path="/wilayah/tambahWilayah"
            element={
              <WILAYAHRoute>
                <TambahWilayah />
              </WILAYAHRoute>
            }
          />
          {/* Unit */}
          <Route
            path="/unit"
            element={
              <UNITRoute>
                <TampilUnit />
              </UNITRoute>
            }
          />
          <Route
            path="/unit/:id"
            element={
              <UNITRoute>
                <TampilUnit />
              </UNITRoute>
            }
          />
          <Route
            path="/unit/:id/edit"
            element={
              <UNITRoute>
                <UbahUnit />
              </UNITRoute>
            }
          />
          <Route
            path="/unit/tambahUnit"
            element={
              <UNITRoute>
                <TambahUnit />
              </UNITRoute>
            }
          />
          {/* Anggota Koperasi */}
          <Route
            path="/anggotaKoperasi"
            element={
              <ANGGOTAKOPERASIRoute>
                <TampilDaftarAnggotaKoperasi />
              </ANGGOTAKOPERASIRoute>
            }
          />
          <Route
            path="/anggotaKoperasi/:id"
            element={
              <ANGGOTAKOPERASIRoute>
                <TampilAnggotaKoperasi />
              </ANGGOTAKOPERASIRoute>
            }
          />
          <Route
            path="/anggotaKoperasi/:id/edit"
            element={
              <ANGGOTAKOPERASIRoute>
                <UbahAnggotaKoperasi />
              </ANGGOTAKOPERASIRoute>
            }
          />
          <Route
            path="/anggotaKoperasi/:id/editFoto"
            element={
              <ANGGOTAKOPERASIRoute>
                <UbahFotoAnggotaKoperasi />
              </ANGGOTAKOPERASIRoute>
            }
          />
          <Route
            path="/anggotaKoperasi/tambahAnggotaKoperasi"
            element={
              <ANGGOTAKOPERASIRoute>
                <TambahAnggotaKoperasi />
              </ANGGOTAKOPERASIRoute>
            }
          />
          {/* Anggota Koperasi Keluar */}
          <Route
            path="/anggotaKoperasiKeluar"
            element={
              <ANGGOTAKOPERASIKELUARRoute>
                <TampilDaftarAnggotaKoperasiKeluar />
              </ANGGOTAKOPERASIKELUARRoute>
            }
          />
          <Route
            path="/anggotaKoperasiKeluar/:id"
            element={
              <ANGGOTAKOPERASIKELUARRoute>
                <TampilAnggotaKoperasiKeluar />
              </ANGGOTAKOPERASIKELUARRoute>
            }
          />
          <Route
            path="/anggotaKoperasiKeluar/:id/edit"
            element={
              <ANGGOTAKOPERASIKELUARRoute>
                <UbahAnggotaKoperasiKeluar />
              </ANGGOTAKOPERASIKELUARRoute>
            }
          />
          <Route
            path="/anggotaKoperasiKeluar/:id/editFoto"
            element={
              <ANGGOTAKOPERASIKELUARRoute>
                <UbahFotoAnggotaKoperasiKeluar />
              </ANGGOTAKOPERASIKELUARRoute>
            }
          />
          {/* Kode Kartu Tabungan */}
          <Route
            path="/kodeKartuTabungan"
            element={
              <KODEKARTUTABUNGANRoute>
                <TampilKodeKartuTabungan />
              </KODEKARTUTABUNGANRoute>
            }
          />
          <Route
            path="/kodeKartuTabungan/:id"
            element={
              <KODEKARTUTABUNGANRoute>
                <TampilKodeKartuTabungan />
              </KODEKARTUTABUNGANRoute>
            }
          />
          <Route
            path="/kodeKartuTabungan/:id/edit"
            element={
              <KODEKARTUTABUNGANRoute>
                <UbahKodeKartuTabungan />
              </KODEKARTUTABUNGANRoute>
            }
          />
          <Route
            path="/kodeKartuTabungan/tambahKodeKartuTabungan"
            element={
              <KODEKARTUTABUNGANRoute>
                <TambahKodeKartuTabungan />
              </KODEKARTUTABUNGANRoute>
            }
          />
          {/* Mutasi Unit */}
          <Route
            path="/mutasiUnit"
            element={
              <MUTASIUNITRoute>
                <TampilMutasiUnit />
              </MUTASIUNITRoute>
            }
          />
          <Route
            path="/mutasiUnit/tambahMutasiUnit"
            element={
              <MUTASIUNITRoute>
                <TambahMutasiUnit />
              </MUTASIUNITRoute>
            }
          />
          <Route
            path="/mutasiUnit/:id"
            element={
              <MUTASIUNITRoute>
                <TampilMutasiUnit />
              </MUTASIUNITRoute>
            }
          />
          <Route
            path="/mutasiUnit/:id/edit"
            element={
              <MUTASIUNITRoute>
                <UbahMutasiUnit />
              </MUTASIUNITRoute>
            }
          />
          {/* Keluar */}
          <Route
            path="/keluar"
            element={
              <KELUARRoute>
                <TampilKeluar />
              </KELUARRoute>
            }
          />
          <Route
            path="/keluar/tambahKeluar"
            element={
              <KELUARRoute>
                <TambahKeluar />
              </KELUARRoute>
            }
          />
          <Route
            path="/keluar/:id"
            element={
              <KELUARRoute>
                <TampilKeluar />
              </KELUARRoute>
            }
          />
          <Route
            path="/keluar/:id/edit"
            element={
              <KELUARRoute>
                <UbahKeluar />
              </KELUARRoute>
            }
          />
          {/* Pembayaran Keluar */}
          <Route
            path="/pembayaranKeluar"
            element={
              <PEMBAYARANKELUARRoute>
                <TampilPembayaranKeluar />
              </PEMBAYARANKELUARRoute>
            }
          />
          <Route
            path="/pembayaranKeluar/tambahPembayaranKeluar"
            element={
              <PEMBAYARANKELUARRoute>
                <TambahPembayaranKeluar />
              </PEMBAYARANKELUARRoute>
            }
          />
          <Route
            path="/pembayaranKeluar/:id"
            element={
              <PEMBAYARANKELUARRoute>
                <TampilPembayaranKeluar />
              </PEMBAYARANKELUARRoute>
            }
          />
          <Route
            path="/pembayaranKeluar/:id/edit"
            element={
              <PEMBAYARANKELUARRoute>
                <UbahPembayaranKeluar />
              </PEMBAYARANKELUARRoute>
            }
          />
          {/* Saldo Dja */}
          <Route
            path="/saldoDja"
            element={
              <SALDODJARoute>
                <TampilSaldoDja />
              </SALDODJARoute>
            }
          />
          <Route
            path="/saldoDja/tambahSaldoDja"
            element={
              <SALDODJARoute>
                <TambahSaldoDja />
              </SALDODJARoute>
            }
          />
          <Route
            path="/saldoDja/:id"
            element={
              <SALDODJARoute>
                <TampilSaldoDja />
              </SALDODJARoute>
            }
          />
          <Route
            path="/saldoDja/:id/edit"
            element={
              <SALDODJARoute>
                <UbahSaldoDja />
              </SALDODJARoute>
            }
          />
          {/* SHU Simpanan Wajib */}
          <Route
            path="/shuSimpananWajib"
            element={
              <SHUSIMPANANWAJIBRoute>
                <TampilShuSimpananWajib />
              </SHUSIMPANANWAJIBRoute>
            }
          />
          <Route
            path="/shuSimpananWajib/tambahShuSimpananWajib"
            element={
              <SHUSIMPANANWAJIBRoute>
                <TambahShuSimpananWajib />
              </SHUSIMPANANWAJIBRoute>
            }
          />
          <Route
            path="/shuSimpananWajib/:id"
            element={
              <SHUSIMPANANWAJIBRoute>
                <TampilShuSimpananWajib />
              </SHUSIMPANANWAJIBRoute>
            }
          />
          <Route
            path="/shuSimpananWajib/:id/edit"
            element={
              <SHUSIMPANANWAJIBRoute>
                <UbahShuSimpananWajib />
              </SHUSIMPANANWAJIBRoute>
            }
          />
          {/* Simpanan Wajib */}
          <Route
            path="/daftarSimpananWajib"
            element={
              <SIMPANANWAJIBRoute>
                <TampilDaftarSimpananWajib />
              </SIMPANANWAJIBRoute>
            }
          />
          <Route
            path="/daftarSimpananWajib/simpananWajib/tambahSimpananWajibSemuaUnit"
            element={
              <SIMPANANWAJIBRoute>
                <TambahSimpananWajibSemuaUnit />
              </SIMPANANWAJIBRoute>
            }
          />
          <Route
            path="/daftarSimpananWajib/simpananWajib/tambahSimpananWajib"
            element={
              <SIMPANANWAJIBRoute>
                <TambahSimpananWajib />
              </SIMPANANWAJIBRoute>
            }
          />
          <Route
            path="/daftarSimpananWajib/simpananWajib/:id"
            element={
              <SIMPANANWAJIBRoute>
                <TampilSimpananWajib />
              </SIMPANANWAJIBRoute>
            }
          />
          <Route
            path="/daftarSimpananWajib/simpananWajib/:id/tambahSimpananWajibChild"
            element={
              <SIMPANANWAJIBRoute>
                <TambahSimpananWajibChild />
              </SIMPANANWAJIBRoute>
            }
          />
          <Route
            path="/daftarSimpananWajib/simpananWajib/:id/:idSimpananWajibChild"
            element={
              <SIMPANANWAJIBRoute>
                <TampilSimpananWajibChild />
              </SIMPANANWAJIBRoute>
            }
          />
          {/* Laporan Simpanan Wajib */}
          <Route
            path="/laporanSimpananWajib"
            element={
              <LAPORANSIMPANANWAJIBRoute>
                <LaporanSimpananWajib />
              </LAPORANSIMPANANWAJIBRoute>
            }
          />
          {/* Rekap Perhitungan */}
          <Route
            path="/rekapPerhitungan"
            element={
              <REKAPPERHITUNGANRoute>
                <RekapPerhitungan />
              </REKAPPERHITUNGANRoute>
            }
          />
          {/* Laporan SHU Simpanan Wajib */}
          <Route
            path="/laporanShuSimpananWajib"
            element={
              <LAPORANSHUSIMPANANWAJIBRoute>
                <LaporanShuSimpananWajib />
              </LAPORANSHUSIMPANANWAJIBRoute>
            }
          />
          {/* Pembukaan Tabungan Umum */}
          <Route
            path="/pembukaanTabunganUmum"
            element={
              <PEMBUKAANTABUNGANUMUMRoute>
                <TampilPembukaanTabunganUmum />
              </PEMBUKAANTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/pembukaanTabunganUmum/:id"
            element={
              <PEMBUKAANTABUNGANUMUMRoute>
                <TampilPembukaanTabunganUmum />
              </PEMBUKAANTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/pembukaanTabunganUmum/tambahPembukaanTabunganUmum"
            element={
              <PEMBUKAANTABUNGANUMUMRoute>
                <TambahPembukaanTabunganUmum />
              </PEMBUKAANTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/pembukaanTabunganUmum/:id/edit"
            element={
              <PEMBUKAANTABUNGANUMUMRoute>
                <UbahPembukaanTabunganUmum />
              </PEMBUKAANTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/pembukaanTabunganUmum/:id/approve"
            element={
              <PEMBUKAANTABUNGANUMUMRoute>
                <ApprovePembukaanTabunganUmum />
              </PEMBUKAANTABUNGANUMUMRoute>
            }
          />
          {/* Setoran Tabungan Umum */}
          <Route
            path="/setoranTabunganUmum"
            element={
              <SETORANTABUNGANUMUMRoute>
                <TampilSetoranTabunganUmum />
              </SETORANTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/setoranTabunganUmum/:id"
            element={
              <SETORANTABUNGANUMUMRoute>
                <TampilSetoranTabunganUmum />
              </SETORANTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/setoranTabunganUmum/tambahSetoranTabunganUmum"
            element={
              <SETORANTABUNGANUMUMRoute>
                <TambahSetoranTabunganUmum />
              </SETORANTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/setoranTabunganUmum/tambahSetoranTabunganUmumKolektif"
            element={
              <SETORANTABUNGANUMUMRoute>
                <TambahSetoranTabunganUmumKolektif />
              </SETORANTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/setoranTabunganUmum/:id/edit"
            element={
              <SETORANTABUNGANUMUMRoute>
                <UbahSetoranTabunganUmum />
              </SETORANTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/setoranTabunganUmum/:id/approve"
            element={
              <SETORANTABUNGANUMUMRoute>
                <ApproveSetoranTabunganUmum />
              </SETORANTABUNGANUMUMRoute>
            }
          />
          {/* Tarik Tabungan Umum */}
          <Route
            path="/tarikTabunganUmum"
            element={
              <TARIKTABUNGANUMUMRoute>
                <TampilTarikTabunganUmum />
              </TARIKTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/tarikTabunganUmum/:id"
            element={
              <TARIKTABUNGANUMUMRoute>
                <TampilTarikTabunganUmum />
              </TARIKTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/tarikTabunganUmum/tambahTarikTabunganUmum"
            element={
              <TARIKTABUNGANUMUMRoute>
                <TambahTarikTabunganUmum />
              </TARIKTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/tarikTabunganUmum/:id/edit"
            element={
              <TARIKTABUNGANUMUMRoute>
                <UbahTarikTabunganUmum />
              </TARIKTABUNGANUMUMRoute>
            }
          />
          <Route
            path="/tarikTabunganUmum/:id/approve"
            element={
              <TARIKTABUNGANUMUMRoute>
                <ApproveTarikTabunganUmum />
              </TARIKTABUNGANUMUMRoute>
            }
          />
          {/* Kartu Tabungan Umum */}
          <Route
            path="/kartuTabunganTabunganUmum"
            element={
              <KARTUTABUNGANUMUMRoute>
                <KartuTabunganUmum />
              </KARTUTABUNGANUMUMRoute>
            }
          />
        </Routes>
        <Footer />
      </main>
    </div>
  );
};

export default App;
