import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  Divider,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Container, Col, Row, Form } from "react-bootstrap";

const TampilSimpananWajibChild = () => {
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const { user, dispatch } = useContext(AuthContext);
  const { id, idSimpananWajibChild } = useParams();
  const navigate = useNavigate();
  const [noBuktiSetor, setNoBuktiSetor] = useState("");
  const [tglSimpananWajib, setTglSimpananWajib] = useState("");
  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");
  const [simpananWajib, setSimpananWajib] = useState("");

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getSimpananWajibChildById();
  }, []);

  const getSimpananWajibChildById = async () => {
    if (id) {
      const response = await axios.post(
        `${tempUrl}/simpananWajibChilds/${idSimpananWajibChild}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setNoBuktiSetor(response.data.simpananwajib.noBuktiSetor);
      setTglSimpananWajib(
        formatDate(response.data.simpananwajib.tglSimpananWajib)
      );
      setNoAnggota(response.data.anggotakoperasis.noAnggota);
      setNamaAnggota(response.data.anggotakoperasis.namaAnggota);
      setSimpananWajib(response.data.simpananWajib);
    }
  };

  const deleteSimpananWajibChild = async (id) => {
    try {
      setLoading(true);
      // Delete Kas Masuk
      await axios.post(
        `${tempUrl}/deleteSimpananWajibChild/${idSimpananWajibChild}`,
        {
          simpananWajib,
          simpananWajibId: id,
          _id: user.id,
          token: user.token,
        }
      );
      setNoBuktiSetor("");
      setTglSimpananWajib("");
      setNoAnggota("");
      setNamaAnggota("");
      setSimpananWajib("");
      setLoading(false);
      navigate(`/daftarSimpananWajib/simpananWajib/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/daftarSimpananWajib/simpananWajib/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Simpan</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Simpanan Wajib Anggota
        </Typography>
        <Box sx={deleteButtonContainer}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {`Yakin ingin menghapus data ${noBuktiSetor}?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => deleteSimpananWajibChild(id)}>Ok</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
          <ButtonGroup variant="contained">
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteOutlineIcon />}
              sx={{ textTransform: "none" }}
              onClick={handleClickOpen}
            >
              Hapus
            </Button>
          </ButtonGroup>
        </Box>
        <Divider sx={dividerStyle} />
        <Box sx={[textFieldContainer, spacingTop]}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Anggota :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noAnggota}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Anggota :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={namaAnggota}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noBuktiSetor}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tanggal :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tglSimpananWajib}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Simpanan Wajib :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={simpananWajib.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default TampilSimpananWajibChild;

const subTitleText = {
  fontWeight: "900",
};

const deleteButtonContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
