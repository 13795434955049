import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../contexts/AuthContext';
import { tempUrl, useStateContext } from '../../../contexts/ContextProvider';
import { ShowTableAnggotaKoperasi } from '../../../components/ShowTable';
import { FetchErrorHandling } from '../../../components/FetchErrorHandling';
import { SearchBar, Loader, ButtonModifier } from '../../../components';
import { Container, Form } from 'react-bootstrap';
import { Box, Pagination, Button, ButtonGroup } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useDownloadExcel } from 'react-export-table-to-excel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import { formatDate } from '../../../constants/helper';

const TampilDaftarAnggotaKoperasi = () => {
  const tableRef = useRef(null);
  const { user, setting } = useContext(AuthContext);
  const { screenSize } = useStateContext();
  let navigate = useNavigate();

  const [isFetchError, setIsFetchError] = useState(false);

  const [anggotaReport, setAnggotaReport] = useState([]);

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [anggotaKoperasis, setAnggotaKoperasis] = useState([]);

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState('');

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    getAnggotaKoperasis();
  }, [page, searchTerm]);

  const getAnggotaKoperasis = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/anggotaKoperasisBlmKeluarPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`, {
        _id: user.id,
        token: user.token,
      });
      setQuery(searchTerm);
      setAnggotaKoperasis(response.data.anggotaKoperasis);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getAnggotaReportData = async () => {
    try {
      const response = await axios.post(`${tempUrl}/anggotaKoperasis`, {
        _id: user.id,
        token: user.token,
      });
      setAnggotaReport(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      '-' +
      (date.getMonth() + 1).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      '-' +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ':' +
      date.getMinutes().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ':' +
      date.getSeconds().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF('l', 'mm', [297, 210]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Anggota Koperasi`, 120, 30);
    doc.setFontSize(10);
    doc.text(`Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`, 15, 290);
    doc.autoTable({
      html: '#table',
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output('blob')));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'AnggotaKoperasi',
    sheet: 'DaftarAnggotaKoperasi',
  });

  const textRight = {
    textAlign: screenSize >= 650 && 'right',
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Anggota Koperasi</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              setPreviewPdf(!previewPdf);
              getAnggotaReportData();
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              setPreviewExcel(!previewExcel);
              getAnggotaReportData();
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button variant="outlined" startIcon={<PrintIcon />} onClick={() => downloadPdf()}>
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>No.</th>
                <th>No. Anggota</th>
                <th>Nama</th>
                <th>Alamat</th>
                <th>No. Telp</th>
                <th>Unit</th>
                <th>Tgl. Masuk</th>
                <th>No. Rek BPR</th>
                <th>No. Rek SimTab UUO-SPI</th>
              </tr>
            </thead>
            <tbody>
              {anggotaReport.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.no}</td>
                  <td>{user.noAnggota}</td>
                  <td>{user.namaAnggota}</td>
                  <td>{user.alamatAnggota}</td>
                  <td>{user.noTelp}</td>
                  <td>{user.unitAktif.namaUnit}</td>
                  <td>{formatDate(user.tglMasuk)}</td>
                  <td>{user.noRekeningBpr}</td>
                  <td>{user.noRekeningSimpanPinjam}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button variant="outlined" startIcon={<DownloadIcon />} onClick={onDownload}>
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>No.</th>
                <th>No. Anggota</th>
                <th>Nama</th>
                <th>Alamat</th>
                <th>No. Telp</th>
                <th>Unit</th>
                <th>Tgl. Masuk</th>
                <th>No. Rek BPR</th>
                <th>No. Rek SimTab UUO-SPI</th>
              </tr>
              {anggotaReport.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.no}</td>
                  <td>{user.noAnggota}</td>
                  <td>{user.namaAnggota}</td>
                  <td>{user.alamatAnggota}</td>
                  <td>{user.noTelp}</td>
                  <td>{user.unitAktif.namaUnit}</td>
                  <td>{formatDate(user.tglMasuk)}</td>
                  <td>{user.noRekeningBpr}</td>
                  <td>{user.noRekeningSimpanPinjam}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <Button
          variant="contained"
          color="success"
          sx={{ bgcolor: 'success.light', textTransform: 'none' }}
          startIcon={<AddCircleOutlineIcon />}
          size="small"
          onClick={() => {
            navigate(`/anggotaKoperasi/tambahAnggotaKoperasi`);
          }}
        >
          Tambah
        </Button>
      </Box>
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button variant="contained" type="submit" color="primary" disableElevation>
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableAnggotaKoperasi currentPosts={anggotaKoperasis} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination count={pages} page={page + 1} onChange={handleChange} color="primary" size={screenSize <= 600 ? 'small' : 'large'} />
      </Box>
    </Container>
  );
};

export default TampilDaftarAnggotaKoperasi;

const buttonModifierContainer = {
  mt: 4,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const downloadButtons = {
  mt: 4,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const searchBarContainer = {
  pt: 6,
  display: 'flex',
  justifyContent: 'center',
};

const tableContainer = {
  pt: 4,
  display: 'flex',
  justifyContent: 'center',
};
