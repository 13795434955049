import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Alert, Button, Snackbar } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UbahKeluar = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noSkKeluar, setNoSkKeluar] = useState("");
  const [tglKeluar, setTglKeluar] = useState(null);
  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");

  const [saldoDjaTerakhir, setSaldoDjaTerakhir] = useState("");
  const [totalSimpananWajib, setTotalSimpananWajib] = useState("");
  const [totalSimpananWajibSemuaAnggota, setTotalSimpananWajibSemuaAnggota] =
    useState("");
  const [dja, setDja] = useState("");
  const [total, setTotal] = useState("");

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getKeluarById();
  }, []);

  const getKeluarById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/keluars/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoSkKeluar(response.data.noSkKeluar);
      setTglKeluar(new Date(response.data.tglKeluar));
      setNoAnggota(response.data.anggotakoperasis.noAnggota);
      setNamaAnggota(response.data.anggotakoperasis.namaAnggota);
      setSaldoDjaTerakhir(
        response.data.saldoDjaTerakhir.toLocaleString("en-US")
      );
      setTotalSimpananWajib(
        response.data.totalSimpananWajib.toLocaleString("en-US")
      );
      setTotalSimpananWajibSemuaAnggota(
        response.data.totalSimpananWajibSemuaAnggota.toLocaleString("en-US")
      );
      setDja(response.data.dja.toLocaleString("en-US"));
      setTotal(response.data.total.toLocaleString("en-US"));
    }
  };

  const updateKeluar = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && tglKeluar !== null) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateKeluar/${id}`, {
            noSkKeluar,
            tglKeluar,
            noAnggota,
            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/keluar/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Mutasi Anggota</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Keluar</h5>
      <hr />
      <Card>
        <Card.Header>Keluar</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateKeluar}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    No. SK :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={noSkKeluar} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Tanggal <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      customInput={<Form.Control required />}
                      selected={tglKeluar}
                      onChange={(date) => setTglKeluar(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    No. Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={noAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Nama Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={namaAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Saldo DJA Terakhir :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={saldoDjaTerakhir} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total Simpanan Wajib :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={totalSimpananWajib}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total Simpanan Wajib Semua Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={totalSimpananWajibSemuaAnggota}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    DJA :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={dja} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={total} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/keluar/${id}`)}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahKeluar;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};
