import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableKeluar } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Box, Pagination, Button, ButtonGroup } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { formatDate } from "../../../constants/helper";

const TampilKeluar = () => {
  const tableRef = useRef(null);
  const reportTemplateRef = useRef(null);
  const { user, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [noSkKeluar, setNoSkKeluar] = useState("");
  const [tglKeluar, setTglKeluar] = useState("");
  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");

  const [saldoDjaTerakhir, setSaldoDjaTerakhir] = useState("");
  const [totalSimpananWajib, setTotalSimpananWajib] = useState("");
  const [totalSimpananWajibSemuaAnggota, setTotalSimpananWajibSemuaAnggota] =
    useState("");
  const [dja, setDja] = useState("");
  const [total, setTotal] = useState("");

  const [noTandaTerima, setNoTandaTerima] = useState("");
  const [simpananPokok, setSimpananPokok] = useState(0);
  const [tglMasuk, setTglMasuk] = useState("");
  const [tahunTandaTerima, setTahunTandaTerima] = useState("");

  const [keluarReport, setKeluarReport] = useState([]);

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [previewTandaTerima, setPreviewTandaTerima] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [keluars, setKeluars] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");
  const PER_PAGE = 20;

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id && getKeluarById();
  }, [id]);

  useEffect(() => {
    getKeluars();
  }, [page, searchTerm]);

  const getKeluars = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/keluarsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setKeluars(response.data.keluars);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getKeluarReportData = async () => {
    try {
      const response = await axios.post(`${tempUrl}/keluars`, {
        _id: user.id,
        token: user.token,
      });
      setKeluarReport(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const getKeluarById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/keluars/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoSkKeluar(response.data.noSkKeluar);
      setTglKeluar(formatDate(response.data.tglKeluar));
      setNoAnggota(response.data.anggotakoperasis.noAnggota);
      setNamaAnggota(response.data.anggotakoperasis.namaAnggota);
      setSaldoDjaTerakhir(response.data.saldoDjaTerakhir);

      let tempTotalSimpananWajib =
        response.data.total -
        response.data.anggotakoperasis.simpananPokok -
        response.data.dja;
      setTotalSimpananWajib(tempTotalSimpananWajib);
      setTotalSimpananWajibSemuaAnggota(
        response.data.totalSimpananWajibSemuaAnggota
      );
      setDja(response.data.dja);
      setTotal(response.data.total);

      setNoTandaTerima(response.data.noSkKeluar);
      setSimpananPokok(response.data.anggotakoperasis.simpananPokok);
      setTglMasuk(formatDate(response.data.anggotakoperasis.tglMasuk));
      let tempDate = new Date(response.data.tglKeluar);
      setTahunTandaTerima(tempDate.getFullYear());
    }
    setLoading(false);
  };

  const deleteKeluar = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteKeluar/${id}`, {
        noAnggota,
        _id: user.id,
        token: user.token,
      });
      getKeluars();
      setNoSkKeluar("");
      setTglKeluar("");
      setNoAnggota("");
      setNamaAnggota("");
      setSaldoDjaTerakhir("");
      setTotalSimpananWajib("");
      setTotalSimpananWajibSemuaAnggota("");
      setDja("");
      setTotal("");
      setNoTandaTerima("");
      navigate("/keluar");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${namaAnggota} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Keluar`, 85, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Keluar",
    sheet: "DaftarKeluar",
  });

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      <h3>Mutasi Anggota</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Keluar</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          {id ? (
            <Button
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => {
                setPreviewTandaTerima(!previewTandaTerima);
              }}
            >
              Cetak Tanda Terima
            </Button>
          ) : (
            <>
              <Button
                color="primary"
                startIcon={<SearchIcon />}
                onClick={() => {
                  setPreviewPdf(!previewPdf);
                  getKeluarReportData();
                  setPreviewExcel(false);
                }}
              >
                PDF
              </Button>
              <Button
                color="secondary"
                startIcon={<SearchIcon />}
                onClick={() => {
                  setPreviewExcel(!previewExcel);
                  getKeluarReportData();
                  setPreviewPdf(false);
                }}
              >
                Excel
              </Button>
            </>
          )}
        </ButtonGroup>
      </Box>
      {previewTandaTerima && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} id="content" style={pdfContainer}>
            <h2 style={titleCetak}>TANDA TERIMA</h2>
            <h5 style={noTandaTerimaCetak}>No. {noTandaTerima}</h5>
            <div style={containerContent}>
              <div style={wrapperContent}>
                <div style={{ display: "flex" }}>
                  <p>Telah terima dari</p>
                  <p style={{ marginLeft: "40px" }}>
                    : {setting.namaPerusahaan}
                  </p>
                </div>
                <div style={{ display: "flex", marginTop: "-10px" }}>
                  <p>Uang sejumlah</p>
                  <p style={{ marginLeft: "50px" }}>
                    : Rp{" "}
                    {(simpananPokok + totalSimpananWajib + dja).toLocaleString(
                      "en-US"
                    )}
                    ,-(
                    {angkaTerbilang(
                      simpananPokok + totalSimpananWajib + dja
                    )}{" "}
                    Rupiah)
                  </p>
                </div>
                <p style={{ marginTop: "-10px" }}>
                  Untuk pembayaran kepada Anggota {setting.namaPerusahaan} yang
                  mengundurkan diri / berhenti / pensiun dari keanggotaan{" "}
                  {setting.namaPerusahaan} sebagai berikut:{" "}
                </p>
                <div style={{ marginTop: "-10px" }}>
                  <div
                    style={{
                      width: "600px",
                      marginLeft: "100px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <p>Nama Anggota</p>
                      <p style={{ marginLeft: "150px" }}>: {namaAnggota}</p>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <p>No. Anggota</p>
                      <p style={{ marginLeft: "166px" }}>: {noAnggota}</p>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <p>Keanggotaan sejak</p>
                      <p style={{ marginLeft: "120px" }}>: {tglMasuk}</p>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <p>Berhenti menjadi Anggota sejak</p>
                      <p style={{ marginLeft: "40px" }}>: {tglKeluar}</p>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <p>Perincian Pembayaran :</p>
                    </div>
                    <div style={{ marginTop: "-15px" }}>
                      <div style={{ display: "flex", marginLeft: "20px" }}>
                        <p>a. Simpanan Pokok</p>
                        <p style={{ marginLeft: "60px" }}>: Rp</p>
                        <div style={numberContent}>
                          <p>{simpananPokok.toLocaleString("en-US")}</p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "20px",
                          marginTop: "-15px",
                        }}
                      >
                        <p>b. Simpanan Wajib</p>
                        <p style={{ marginLeft: "65px" }}>: Rp</p>
                        <div style={numberContent}>
                          <p>{totalSimpananWajib.toLocaleString("en-US")}</p>
                        </div>
                      </div>
                      <div style={thirdNumberContent}>
                        <p style={{ marginRight: "44px" }}>
                          c. Dana Jasa Anggota
                        </p>
                        <div
                          style={{
                            display: "flex",
                            height: "20px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <p>: Rp</p>
                          <div
                            style={{
                              marginLeft: "20px",
                              width: "100px",
                              display: "flex",
                              justifyContent: "right",
                            }}
                          >
                            <p>{dja.toLocaleString("en-US")}</p>
                          </div>
                          <p style={{ marginLeft: "10px" }}>+</p>
                        </div>
                      </div>
                      <div style={totalContainer}>
                        <p style={totalText}>Total</p>
                        <div style={totalWrapper}>
                          <p>: Rp</p>
                          <div style={totalNumber}>
                            <p>
                              {(
                                parseInt(simpananPokok) +
                                parseInt(totalSimpananWajib) +
                                parseInt(dja)
                              ).toLocaleString("en-US")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <p>
                        Karanganyar, ...... .............. {tahunTandaTerima}
                      </p>
                      <p style={{ marginLeft: "300px" }}>Yang Menyerahkan,</p>
                    </div>
                  </div>
                  <div style={{ marginTop: "-10px" }}>
                    <div style={{ display: "flex" }}>
                      <p>Yang Menerima,</p>
                      <p style={{ marginLeft: "360px" }}>
                        {setting.namaPerusahaan}
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginTop: "100px" }}>
                    <p>
                      (........................................................)
                    </p>
                    <p
                      style={{
                        textDecoration: "underline",
                        marginLeft: "200px",
                      }}
                    >
                      {setting.bendahara1}
                    </p>
                    <p
                      style={{
                        textDecoration: "underline",
                        marginLeft: "50px",
                      }}
                    >
                      {setting.manager}
                    </p>
                  </div>
                  <div
                    style={{
                      marginTop: "-10px",
                      display: "flex",
                      fontSize: "12px",
                    }}
                  >
                    <p style={{ marginLeft: "480px" }}>
                      {setting.bendahara1Keterangan}
                    </p>
                    <p style={{ marginLeft: "140px" }}>
                      {setting.managerKeterangan}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>No. SK</th>
                <th>Tgl.</th>
                <th>No. Anggota</th>
                <th>Nama Anggota</th>
                <th>Saldo DJA Terakhir</th>
                <th>Total Simpanan Wajib</th>
                <th>Total Simpanan Wajib Semua Anggota</th>
                <th>DJA</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {keluarReport.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.noSkKeluar}</td>
                  <td>{formatDate(user.tglKeluar)}</td>
                  <td>{user.anggotakoperasis.noAnggota}</td>
                  <td>{user.anggotakoperasis.namaAnggota}</td>
                  <td>{user.saldoDjaTerakhir.toLocaleString("en-US")}</td>
                  <td>{user.totalSimpananWajib.toLocaleString("en-US")}</td>
                  <td>
                    {user.totalSimpananWajibSemuaAnggota.toLocaleString(
                      "en-US"
                    )}
                  </td>
                  <td>{user.dja.toLocaleString("en-US")}</td>
                  <td>{user.total.toLocaleString("en-US")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>No. SK</th>
                <th>Tgl.</th>
                <th>No. Anggota</th>
                <th>Nama Anggota</th>
                <th>Saldo DJA Terakhir</th>
                <th>Total Simpanan Wajib</th>
                <th>Total Simpanan Wajib Semua Anggota</th>
                <th>DJA</th>
                <th>Total</th>
              </tr>
              {keluarReport.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.noSkKeluar}</td>
                  <td>{formatDate(user.tglKeluar)}</td>
                  <td>{user.anggotakoperasis.noAnggota}</td>
                  <td>{user.anggotakoperasis.namaAnggota}</td>
                  <td>{user.saldoDjaTerakhir}</td>
                  <td>{user.totalSimpananWajib}</td>
                  <td>{user.totalSimpananWajibSemuaAnggota}</td>
                  <td>{user.dja}</td>
                  <td>{user.total}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/keluar/tambahKeluar`}
          editLink={`/keluar/${id}/edit`}
          deleteUser={deleteKeluar}
          nameUser={namaAnggota}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    No. SK :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control disabled value={noSkKeluar} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Tanggal :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={tglKeluar} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    No. Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control disabled value={noAnggota} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Nama Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={namaAnggota} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Saldo DJA Terakhir :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={saldoDjaTerakhir.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total Simpanan Wajib :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={totalSimpananWajib.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total Simpanan Wajib Semua Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={totalSimpananWajibSemuaAnggota.toLocaleString(
                        "en-US"
                      )}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    DJA :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={dja.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={total.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableKeluar currentPosts={keluars} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilKeluar;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

const titleCetak = {
  textAlign: "center",
  fontWeight: "bold",
  marginTop: "100px",
};

const noTandaTerimaCetak = {
  textAlign: "center",
  fontWeight: "500",
};

const containerContent = {
  display: "flex",
  marginLeft: "120px",
};

const wrapperContent = {
  marginTop: "20px",
  width: "800px",
  fontSize: "15px",
};

const numberContent = {
  marginLeft: "20px",
  width: "100px",
  display: "flex",
  justifyContent: "right",
};

const thirdNumberContent = {
  display: "flex",
  marginLeft: "20px",
  marginTop: "-15px",
};

const totalContainer = {
  display: "flex",
  marginLeft: "20px",
  marginTop: "-15px",
};

const totalText = {
  marginLeft: "110px",
  marginRight: "46px",
  fontWeight: "bold",
};

const totalWrapper = {
  display: "flex",
  height: "20px",
  borderBottom: "1px solid black",
};

const totalNumber = {
  marginLeft: "20px",
  width: "100px",
  display: "flex",
  justifyContent: "right",
};
