import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Paper,
  Card as MuiCard,
  CardHeader,
  CardActionArea,
  CardMedia,
  CardActions,
  IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { KeyOffRounded } from "@mui/icons-material";
import Carousel from "react-elastic-carousel";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const UbahFotoAnggotaKoperasi = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  let [gambar, setGambar] = useState(null);
  let [arrayImage, setArrayImage] = useState([]);
  let [arrayImageUrl, setArrayImageUrl] = useState([]);
  let arrayImageSubmitted = [];
  const [invalidImage, setinvalidImage] = useState(null);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getUrlImg();
    getAnggotaKoperasiById();
  }, [arrayImage]);

  const getUrlImg = () => {
    let tempArrayImageUrl = [];
    Object.keys(arrayImage).map(function (key, index) {
      tempArrayImageUrl.push(URL.createObjectURL(arrayImage[key]));
      setArrayImageUrl(tempArrayImageUrl);
    });
  };

  const getAnggotaKoperasiById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/anggotaKoperasis/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setGambar(response.data.gambar);
    }
  };

  const hapusGambar = async (img, i, id) => {
    if (window.confirm(`Yakin ingin menghapus gambar?`) == false) {
      return;
    }

    let tempGambar = gambar.filter((val) => {
      return val !== img;
    });

    setGambar(tempGambar);

    let image = firebase.storage().refFromURL(img);

    image
      .delete()
      .then(function () {
        console.log("image deleted");
      })
      .catch(function (error) {
        // An error occurred!
        console.log("an error occurred");
      });

    // Delete Gambar
    try {
      await axios.post(`${tempUrl}/deleteGambarAnggotaKoperasi/${id}`, {
        _id: user.id,
        token: user.token,
      });
      getAnggotaKoperasiById();
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      console.log(error);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (arrayImage.length === 0) {
        setLoading(false);
        navigate(`/anggotaKoperasi/${id}`);
      }

      for (let i = 0; i < arrayImage.length; i++) {
        const selectedFile = arrayImage[i];
        if (selectedFile) {
          const storageRef = firebase.storage().ref();
          const fileRef = storageRef.child(
            `${Math.floor(Math.random() * 1000 + 1)}${selectedFile.name}`
          );

          fileRef.put(selectedFile).then((snapshot) => {
            snapshot.ref.getDownloadURL().then(async (downloadURL) => {
              arrayImageSubmitted.push(downloadURL);

              // Update Foto Customer
              await axios.post(`${tempUrl}/saveGambarAnggotaKoperasi`, {
                linkGambar: downloadURL,
                anggotaKoperasiId: id,
                _id: user.id,
                token: user.token,
              });

              if (arrayImageSubmitted.length === arrayImage.length) {
                setLoading(false);
                navigate(`/anggotaKoperasi/${id}`);
              }
            });
          });
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Anggota Koperasi</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={submit}>
        <Card style={mainContainer}>
          <Card.Header>Foto Lama</Card.Header>
          <Card.Body>
            <Box sx={showDataContainer}>
              {gambar && (
                <Carousel breakPoints={breakPoints} sx={carouselStyle}>
                  {gambar &&
                    gambar.map((img, i) => (
                      <MuiCard sx={oldImageCard} elevation={10}>
                        <CardActionArea disableRipple>
                          <CardHeader
                            title={`Gambar`}
                            subheader={`No. ${i + 1}`}
                          />
                          <CardMedia
                            component="img"
                            height="100%"
                            src={img.linkGambar}
                            alt="gambar"
                            sx={oldImageCardMedia}
                            onClick={() => {
                              window.open(
                                img.linkGambar,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          />
                        </CardActionArea>
                        <CardActions sx={oldImageCardAction}>
                          <IconButton aria-label="delete">
                            <DeleteIcon
                              color="error"
                              onClick={() =>
                                hapusGambar(img.linkGambar, i, img.id)
                              }
                            />
                          </IconButton>
                        </CardActions>
                      </MuiCard>
                    ))}
                </Carousel>
              )}
            </Box>
          </Card.Body>
        </Card>

        <Card style={mainContainer}>
          <Card.Header>Upload Foto</Card.Header>
          <Card.Body>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                {invalidImage !== null ? (
                  <h4 className="error"> {invalidImage} </h4>
                ) : null}
                <label className="text-white">Pilih Gambar :</label>
                <input
                  accept="image/*"
                  type="file"
                  id="select-image"
                  style={{ display: "none" }}
                  onChange={(e) => setArrayImage(e.target.files)}
                  multiple
                />
                <label htmlFor="select-image">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    endIcon={<FileUploadIcon />}
                  >
                    Unggah Gambar
                  </Button>
                </label>
              </Box>
            </Box>
            <Box sx={listImageContainer}>
              {arrayImageUrl &&
                arrayImage &&
                arrayImageUrl.map((key, i) => (
                  <MuiCard sx={imageCard} elevation={10}>
                    <CardActionArea>
                      <CardHeader title={`Gambar`} subheader={`No. ${i + 1}`} />
                      <CardMedia
                        component="img"
                        height="200px"
                        src={key}
                        alt={KeyOffRounded.name}
                      />
                    </CardActionArea>
                  </MuiCard>
                ))}
            </Box>
          </Card.Body>
        </Card>

        <Box sx={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(`/anggotaKoperasi/${id}`)}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Ubah
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahFotoAnggotaKoperasi;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const mainContainer = {
  padding: 3,
  marginTop: "15px",
};

const listImageContainer = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
};

const imageCard = {
  m: "auto",
  mt: 2,
};

const carouselStyle = {
  display: "flex",
  height: "400px",
};

const oldImageCard = {
  m: "auto",
  mt: 2,
  maxWidth: "500px",
  maxHeight: "500px",
  display: "flex",
  flexDirection: "column",
};

const oldImageCardMedia = {
  display: "flex",
  maxHeight: "150px",
};

const oldImageCardAction = {
  display: "flex",
  justifyContent: "center",
  mt: "auto",
};
