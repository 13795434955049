import { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../contexts/AuthContext';
import { tempUrl, useStateContext } from '../../../contexts/ContextProvider';
import { Loader } from '../../../components';
import { FetchErrorHandling } from '../../../components/FetchErrorHandling';
import { Container, Card, Form, Row, Col } from 'react-bootstrap';
import { Box, Alert, Button, Snackbar, Autocomplete, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { NumericFormat } from 'react-number-format';

const TambahAnggotaKoperasi = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeUnit, setKodeUnit] = useState('');
  const [noAnggota, setNoAnggota] = useState('');
  const [nikAnggota, setNikAnggota] = useState('');
  const [namaAnggota, setNamaAnggota] = useState('');
  const [noRekeningBpr, setNoRekeningBpr] = useState('');
  const [noRekeningSimpanPinjam, setNoRekeningSimpanPinjam] = useState('');
  const [noTelp, setNoTelp] = useState('');
  const [tglMasuk, setTglMasuk] = useState(new Date());
  const [simpananWajib, setSimpananWajib] = useState(`${setting.simpananWajib.toLocaleString('en-US')}`);
  const [simpananPokok, setSimpananPokok] = useState(`${setting.simpananPokok.toLocaleString('en-US')}`);

  // Detail Data Anggota
  const [tempatLahirAnggota, setTempatLahirAnggota] = useState('');
  const [tglLahirAnggota, setTglLahirAnggota] = useState('');
  const [jenisKelaminAnggota, setJenisKelaminAnggota] = useState('');
  const [golonganDarahAnggota, setGolonganDarahAnggota] = useState('');
  const [alamatAnggota, setAlamatAnggota] = useState('');
  const [rtAnggota, setRtAnggota] = useState('');
  const [rwAnggota, setRwAnggota] = useState('');
  const [kelurahanAnggota, setKelurahanAnggota] = useState('');
  const [kecamatanAnggota, setKecamatanAnggota] = useState('');
  const [agamaAnggota, setAgamaAnggota] = useState('');
  const [statusPerkawinanAnggota, setStatusPerkawinanAnggota] = useState('');
  const [kewarganegaraanAnggota, setKewarganegaraanAnggota] = useState('');

  const [units, setUnits] = useState([]);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const jenisKelaminOption = ['LAKI-LAKI', 'PEREMPUAN'];
  const golonganDarahOption = ['A', 'B', 'AB', 'O'];
  const agamaOption = ['ISLAM', 'KRISTEN', 'KATOLIK', 'HINDU', 'BUDDHA', 'KHONGHUCU'];
  const statusperkawinanOption = ['KAWIN', 'BELUM KAWIN'];
  const kewarganegaraanOption = ['WNI', 'WNA'];

  useEffect(() => {
    getUnits();
  }, []);

  const getUnits = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/units`, {
        _id: user.id,
        token: user.token,
      });
      setUnits(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getAnggotaKoperasiNextKode = async (kodeUnit) => {
    const response = await axios.post(`${tempUrl}/anggotaKoperasiNextKode`, {
      kodeUnit,
      tglMasuk,
      _id: user.id,
      token: user.token,
    });
    setNoAnggota(response.data);
  };

  const getAnggotaKoperasiNextKodeByTglMasuk = async (tglMasuk) => {
    const response = await axios.post(`${tempUrl}/anggotaKoperasiNextKode`, {
      kodeUnit,
      tglMasuk,
      _id: user.id,
      token: user.token,
    });
    setNoAnggota(response.data);
  };

  const saveAnggotaKoperasi = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    if (simpananWajib.length === 0) {
      alert(`Simpanan Wajib belum diisi!`);
      return;
    }

    if (simpananPokok.length === 0) {
      alert(`Simpanan Pokok belum diisi!`);
      return;
    }

    if (form.checkValidity() && kodeUnit.length != 0 && tglMasuk !== null) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveAnggotaKoperasi`, {
          kodeUnit,
          noAnggota,
          nikAnggota,
          namaAnggota,
          noRekeningBpr,
          noRekeningSimpanPinjam,
          noTelp,
          tglMasuk,
          simpananWajib: simpananWajib.replace(/,/g, ''),
          simpananPokok: simpananPokok.replace(/,/g, ''),

          // Detail Data Anggota
          tempatLahirAnggota,
          tglLahirAnggota,
          jenisKelaminAnggota,
          golonganDarahAnggota,
          alamatAnggota,
          rtAnggota,
          rwAnggota,
          kelurahanAnggota,
          kecamatanAnggota,
          agamaAnggota,
          statusPerkawinanAnggota,
          kewarganegaraanAnggota,

          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate('/anggotaKoperasi');
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const unitOption = units.map((unit) => ({
    label: `${unit.kodeUnit} - ${unit.namaUnit}`,
  }));

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && 'right',
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Anggota Koperasi</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={saveAnggotaKoperasi}>
        <Card>
          <Card.Header>Anggota Koperasi</Card.Header>
          <Card.Body>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Nama Unit <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={unitOption}
                          renderInput={(params) => <TextField size="small" error={error && kodeUnit.length === 0 && true} helperText={error && kodeUnit.length === 0 && 'Nama Unit harus diisi!'} {...params} />}
                          onInputChange={(e, value) => {
                            setKodeUnit(value.split(' ', 1)[0]);
                            getAnggotaKoperasiNextKode(value.split(' ', 1)[0]);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        No. Anggota <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control required value={noAnggota} onChange={(e) => setNoAnggota(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        NIK Anggota <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control required value={nikAnggota} onChange={(e) => setNikAnggota(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Nama Anggota <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control required value={namaAnggota} onChange={(e) => setNamaAnggota(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        No. Rekening BPR <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control required value={noRekeningBpr} onChange={(e) => setNoRekeningBpr(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        No. Rekening SimTab UUO-SPI <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control required value={noRekeningSimpanPinjam} onChange={(e) => setNoRekeningSimpanPinjam(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        No. Telepon (HP) :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={noTelp} onChange={(e) => setNoTelp(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Tanggal Masuk <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          customInput={<Form.Control required />}
                          selected={tglMasuk}
                          onChange={(date) => {
                            setTglMasuk(date);
                            getAnggotaKoperasiNextKodeByTglMasuk(date);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Simpanan Wajib <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <NumericFormat
                          value={simpananWajib}
                          thousandSeparator
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setSimpananWajib(values.formattedValue);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Simpanan Pokok <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <NumericFormat
                          value={simpananPokok}
                          thousandSeparator
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setSimpananPokok(values.formattedValue);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: '15px' }}>
          <Card.Header>Detail Data Anggota</Card.Header>
          <Card.Body>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Nama Anggota :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control disabled value={namaAnggota} onChange={(e) => setNamaAnggota(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Tempat/Tgl. Lahir :
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control value={tempatLahirAnggota} onChange={(e) => setTempatLahirAnggota(e.target.value.toUpperCase())} />
                      </Col>
                      <Col sm="3">
                        <DatePicker dateFormat="dd/MM/yyyy" customInput={<Form.Control required />} selected={tglLahirAnggota} onChange={(date) => setTglLahirAnggota(date)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={jenisKelaminOption}
                          renderInput={(params) => <TextField size="small" {...params} />}
                          onInputChange={(e, value) => {
                            setJenisKelaminAnggota(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Golongan Darah :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={golonganDarahOption}
                          renderInput={(params) => <TextField size="small" {...params} />}
                          onInputChange={(e, value) => {
                            setGolonganDarahAnggota(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Agama :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={agamaOption}
                          renderInput={(params) => <TextField size="small" {...params} />}
                          onInputChange={(e, value) => {
                            setAgamaAnggota(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={statusperkawinanOption}
                          renderInput={(params) => <TextField size="small" {...params} />}
                          onInputChange={(e, value) => {
                            setStatusPerkawinanAnggota(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={kewarganegaraanOption}
                          renderInput={(params) => <TextField size="small" {...params} />}
                          onInputChange={(e, value) => {
                            setKewarganegaraanAnggota(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={alamatAnggota} onChange={(e) => setAlamatAnggota(e.target.value.toUpperCase())} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            RT/RW :
                          </Form.Label>
                          <Col sm="3">
                            <Form.Control value={rtAnggota} onChange={(e) => setRtAnggota(e.target.value.toUpperCase())} />
                          </Col>
                          <Col sm="4">
                            <Form.Control value={rwAnggota} onChange={(e) => setRwAnggota(e.target.value.toUpperCase())} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Kel/Desa :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={kelurahanAnggota} onChange={(e) => setKelurahanAnggota(e.target.value.toUpperCase())} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={kecamatanAnggota} onChange={(e) => setKecamatanAnggota(e.target.value.toUpperCase())} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>
        <Box sx={spacingTop}>
          <Button variant="outlined" color="secondary" onClick={() => navigate('/anggotaKoperasi')} sx={{ marginRight: 2 }}>
            {'< Kembali'}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahAnggotaKoperasi;

const colorRed = {
  color: 'red',
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: '100%',
};

const showDataContainer = {
  mt: 4,
  display: 'flex',
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
};

const showDataWrapper = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  maxWidth: {
    md: '40vw',
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
