import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { findMonth, formatDate } from "../../../constants/helper";
import { Box, ButtonGroup, Button } from "@mui/material";
import { Container, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { useDownloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";

const RekapPerhitungan = () => {
  const tableRef = useRef(null);
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const [dariTanggal, setDariTanggal] = useState(new Date());
  const [sampaiTanggal, setSampaiTanggal] = useState(new Date());

  const [loading, setLoading] = useState(false);
  const [lapRekapPerhitungan, setLapRekapPerhitungan] = useState([]);
  const [previewExcel, setPreviewExcel] = useState(false);

  const tampilPdfRekap = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let rekapPerhitungan = await axios.post(`${tempUrl}/rekapPerhitungans`, {
        dariTanggal,
        sampaiTanggal,
        _id: user.id,
        token: user.token,
      });
      setLapRekapPerhitungan(rekapPerhitungan.data);
      setPreviewExcel(!previewExcel);
      setLoading(false);
    }
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const list = [];
  let tempTotalAnggota = 0;
  let tempTotalSimwa = 0;
  let tempTotalSimpok = 0;

  let keysLapRekapPerhitungan = Object.keys(lapRekapPerhitungan);

  for (let i = 0; i < keysLapRekapPerhitungan.length; i++) {
    let tempObjectLapRekapPerhitungan =
      lapRekapPerhitungan[keysLapRekapPerhitungan[i]];

    let tempArrayLapRekapPerhitungan = Object.keys(
      tempObjectLapRekapPerhitungan
    );

    list.push(
      <tr style={tableText}>
        <th colspan="28" style={tableBorderTextLeft}>
          {keysLapRekapPerhitungan[i]}
        </th>
      </tr>
    );

    let tempTotalAnggotaPerWilayah = 0;
    let tempTotalSimwaPerWilayah = 0;
    let tempTotalSimpokPerWilayah = 0;

    for (let j = 0; j < tempArrayLapRekapPerhitungan.length; j++) {
      let tempData =
        tempObjectLapRekapPerhitungan[tempArrayLapRekapPerhitungan[j]];

      let tempTotalSimwaPerUnit = 0;
      let tempTotalSimpokPerUnit = 0;
      let tempTotalAnggotaPerUnit = 0;

      for (let simwa of tempData) {
        tempTotalSimwaPerUnit += simwa.simpananWajib;
        tempTotalSimwaPerWilayah += simwa.simpananWajib;

        if (simwa.simpananWajib) {
          tempTotalAnggotaPerUnit += 1;
          tempTotalAnggotaPerWilayah += 1;
          tempTotalAnggota += 1;
        }

        tempTotalSimpokPerUnit += simwa.simpananPokok;
        tempTotalSimpokPerWilayah += simwa.simpananPokok;

        tempTotalSimwa += simwa.simpananWajib;
        tempTotalSimpok += simwa.simpananPokok;
      }

      let tempTotalPerUnit = tempTotalSimpokPerUnit + tempTotalSimwaPerUnit;

      list.push(
        <tr style={tableText}>
          <th style={tableBorderTextLeft}>{tempArrayLapRekapPerhitungan[j]}</th>
          <th style={tableBorderTextRight}>
            {tempTotalAnggotaPerUnit.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {tempTotalSimpokPerUnit.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>
            {tempTotalSimwaPerUnit.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>0</th>
          <th style={tableBorderTextRight}>
            {tempTotalSimwaPerUnit.toLocaleString("en-US")}
          </th>
          <th style={tableBorderTextRight}>0</th>
          <th style={tableBorderTextRight}>
            {tempTotalPerUnit.toLocaleString("en-US")}
          </th>
        </tr>
      );
    }

    let tempTotalPerWilayah =
      tempTotalSimpokPerWilayah + tempTotalSimwaPerWilayah;

    list.push(
      <tr style={tableText}>
        <th style={tableBorderColor}>Total {keysLapRekapPerhitungan[i]}</th>
        <th style={tableBorderColorRight}>
          {tempTotalAnggotaPerWilayah.toLocaleString("en-US")}
        </th>
        <th style={tableBorderColorRight}>
          {tempTotalSimpokPerWilayah.toLocaleString("en-US")}
        </th>
        <th style={tableBorderColorRight}>
          {tempTotalSimwaPerWilayah.toLocaleString("en-US")}
        </th>
        <th style={tableBorderColorRight}>0</th>
        <th style={tableBorderColorRight}>
          {tempTotalSimwaPerWilayah.toLocaleString("en-US")}
        </th>
        <th style={tableBorderColorRight}>0</th>
        <th style={tableBorderColorRight}>
          {tempTotalPerWilayah.toLocaleString("en-US")}
        </th>
      </tr>
    );
  }

  list.push(
    <tr style={tableText}>
      <th style={tableBorderColor}>TOTAL</th>
      <th style={tableBorderColorRight}>
        {tempTotalAnggota.toLocaleString("en-US")}
      </th>
      <th style={tableBorderColorRight}>
        {tempTotalSimpok.toLocaleString("en-US")}
      </th>
      <th style={tableBorderColorRight}>
        {tempTotalSimwa.toLocaleString("en-US")}
      </th>
      <th style={tableBorderColorRight}>0</th>
      <th style={tableBorderColorRight}>
        {tempTotalSimwa.toLocaleString("en-US")}
      </th>
      <th style={tableBorderColorRight}>0</th>
      <th style={tableBorderColorRight}>
        {(tempTotalSimpok + tempTotalSimwa).toLocaleString("en-US")}
      </th>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "RekapPerhitungan",
    sheet: "RekapPerhitungan",
  });

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    let doc = new jsPDF("l", "mm", [200, 300]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.text(
      `BULAN ${
        findMonth(dariTanggal.getMonth() + 1) + " " + dariTanggal.getFullYear()
      }`,
      15,
      20
    );
    doc.setFontSize(16);
    doc.text(`Rekap Perhitungan`, 125, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    function alignColRinci(data) {
      var col = data.column.index;
      if (col >= 7 && col <= 25) {
        data.cell.styles.halign = "right";
      }
    }
    function alignColRekap(data) {
      var col = data.column.index;
      if (col >= 3 && col <= 7) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didParseCell: function (data, i) {
        let filterHeadTable = data.row.index === 0;

        if (filterHeadTable) {
          data.cell.styles.fillColor = "gray";
          data.cell.styles.textColor = "white";
        }
        alignColRekap(data, i);
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Rekap Perhitungan</h5>
      <hr />
      <Box sx={spacingTop}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  required
                  dateFormat="dd/MM/yyyy"
                  selected={dariTanggal}
                  customInput={<Form.Control required />}
                  onChange={(date) => {
                    setDariTanggal(date);
                    setPreviewExcel(false);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  required
                  dateFormat="dd/MM/yyyy"
                  selected={sampaiTanggal}
                  customInput={<Form.Control required />}
                  onChange={(date) => {
                    setSampaiTanggal(date);
                    setPreviewExcel(false);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <ButtonGroup variant="contained" style={{ marginTop: "20px" }}>
          <Button startIcon={<SearchIcon />} onClick={tampilPdfRekap}>
            REKAP
          </Button>
        </ButtonGroup>
      </Box>

      <div style={{ marginTop: "10px" }}>
        {previewExcel && (
          <ButtonGroup variant="outlined">
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={onDownload}
            >
              Excel
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={() => downloadPdf()}
            >
              PDF
            </Button>
          </ButtonGroup>
        )}

        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th style={tableBorderTitle} colspan="3">
                  {setting.namaPerusahaan}
                </th>
              </tr>
              <tr>
                <th style={tableBorderTitle} colspan="3">
                  REKAP PERHITUNGAN SIMPOK, SIMWA & UUT
                </th>
              </tr>
              <tr>
                <th style={tableBorderTitle} colspan="3">
                  {formatDate(dariTanggal)} - {formatDate(sampaiTanggal)}
                </th>
              </tr>
              <tbody id="table">
                <tr style={tableBorder}>
                  <th style={tableBorder}>Kantor</th>
                  <th style={tableBorder}></th>
                  <th style={tableBorder}>Simpok</th>
                  <th style={tableBorder}>Simwa</th>
                  <th style={tableBorder}>CEK</th>
                  <th style={tableBorder}>Simwa</th>
                  <th style={tableBorder}>UUT</th>
                  <th style={tableBorder}>Jumlah</th>
                </tr>
                {list}
              </tbody>
            </tbody>
          )}
        </table>
      </div>
    </Container>
  );
};

export default RekapPerhitungan;

const spacingTop = {
  mt: 4,
};

const tableBorderTitle = {
  textAlign: "left",
};

const tableBorder = {
  border: "1px solid black",
  textAlign: "center",
  minWidth: "100px",
};

const tableBorderTextLeft = {
  border: "1px solid black",
  textAlign: "left",
  minWidth: "100px",
};

const tableBorderTextRight = {
  border: "1px solid black",
  textAlign: "right",
  minWidth: "100px",
};

const tableBorderColor = {
  border: "1px solid black",
  backgroundColor: "#96D4D4",
  textAlign: "left",
};

const tableBorderColorRight = {
  border: "1px solid black",
  backgroundColor: "#96D4D4",
  textAlign: "right",
};
