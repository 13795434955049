import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";

const UbahKodeKartuTabungan = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [namaKodeKartuTabungan, setNamaKodeKartuTabungan] = useState("");
  const [kodeKodeKartuTabungan, setKodeKodeKartuTabungan] = useState("");
  const [jenisKodeKartuTabungan, setJenisKodeKartuTabungan] = useState("");

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getKodeKartuTabungansById();
  }, []);

  const jenisKodeKartuTabunganOption = ["DEBET", "KREDIT"];

  const getKodeKartuTabungansById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/kodeKartuTabungans/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeKodeKartuTabungan(response.data.kodeKodeKartuTabungan);
      setNamaKodeKartuTabungan(response.data.namaKodeKartuTabungan);
      setJenisKodeKartuTabungan(response.data.jenisKodeKartuTabungan);
    }
  };

  const updateKodeKartuTabungan = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && jenisKodeKartuTabungan.length != 0) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateKodeKartuTabungan/${id}`, {
            namaKodeKartuTabungan,
            jenisKodeKartuTabungan,
            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/kodeKartuTabungan/${id}`);
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      } catch (error) {
        alert(error);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Kode Kartu Tab.</h5>
      <hr />
      <Card>
        <Card.Header>Kode Kartu Tab.</Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={updateKodeKartuTabungan}
          >
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Kartu Tab. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control disabled value={kodeKodeKartuTabungan} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Kartu Tab. <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={namaKodeKartuTabungan}
                      onChange={(e) =>
                        setNamaKodeKartuTabungan(e.target.value.toUpperCase())
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Kode Kartu Tab. <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisKodeKartuTabunganOption}
                      value={jenisKodeKartuTabungan}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          error={
                            error && jenisKodeKartuTabungan.length === 0 && true
                          }
                          helperText={
                            error &&
                            jenisKodeKartuTabungan.length === 0 &&
                            "Jenis Kode Kartu Tab. harus diisi!"
                          }
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setJenisKodeKartuTabungan(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/kodeKartuTabungan")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahKodeKartuTabungan;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};
