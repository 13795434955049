import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { ShowTablePembukaanTabunganUmum } from "../../../../components/ShowTable";
import { FetchErrorHandling } from "../../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../../components";
import { formatDate } from "../../../../constants/helper";
import { Colors } from "../../../../constants/styles";
import { Container, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Pagination,
  Button,
  ButtonGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import ContentCutIcon from "@mui/icons-material/ContentCut";

const TampilPembukaanTabunganUmum = () => {
  const tableRef = useRef(null);
  const reportTemplateRef = useRef(null);
  const { user, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [tglPembukaanTabunganUmum, setTglPembukaanTabunganUmum] = useState("");
  const [noRekeningTabunganUmum, setNoRekeningTabunganUmum] = useState("");
  const [jumlahSetor, setJumlahSetor] = useState("");
  const [noBuktiSetor, setNoBuktiSetor] = useState("");
  const [kodeKartu, setKodeKartu] = useState("");
  const [usernameApprove, setUsernameApprove] = useState("");
  const [tglApprove, setTglApprove] = useState("");
  const [isApprove, setIsApprove] = useState(false);

  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");
  const [alamatAnggota, setAlamatAnggota] = useState("");
  const [noTelpAnggota, setNoTelpAnggota] = useState("");

  const [namaWilayah, setNamaWilayah] = useState("");
  const [namaUnit, setNamaUnit] = useState("");

  const [pembukaanTabunganUmums, setPembukaanTabunganUmums] = useState([]);

  const [pembukaanTabunganUmumReport, setPembukaanTabunganUmumReport] =
    useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [previewTandaTerima, setPreviewTandaTerima] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const [urut, setUrut] = useState("Semua");

  const handleChangeUrut = (event) => {
    setUrut(event.target.value);
  };

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id && getPembukaanTabunganUmumById();
  }, [id]);

  useEffect(() => {
    if (urut === "Belum Approve") {
      getPembukaanTabunganUmumsBelumApprove();
    } else if (urut === "Sudah Approve") {
      getPembukaanTabunganUmumsSudahApprove();
    } else {
      getPembukaanTabunganUmums();
    }
  }, [page, searchTerm, urut]);

  const getPembukaanTabunganUmums = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/pembukaanTabunganUmumsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setPembukaanTabunganUmums(response.data.pembukaanTabunganUmums);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getPembukaanTabunganUmumsSudahApprove = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/pembukaanTabunganUmumsSudahApprove?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setPembukaanTabunganUmums(response.data.pembukaanTabunganUmums);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getPembukaanTabunganUmumsBelumApprove = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/pembukaanTabunganUmumsBelumApprove?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setPembukaanTabunganUmums(response.data.pembukaanTabunganUmums);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getPembukaanTabunganUmumReportData = async () => {
    try {
      const response = await axios.post(`${tempUrl}/pembukaanTabunganUmums`, {
        _id: user.id,
        token: user.token,
      });
      setPembukaanTabunganUmumReport(response.data);
    } catch (error) {
      setIsFetchError(true);
    }
  };

  const getPembukaanTabunganUmumById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(
        `${tempUrl}/pembukaanTabunganUmums/${id}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setNoRekeningTabunganUmum(response.data.noRekeningTabunganUmum);
      setTglPembukaanTabunganUmum(
        formatDate(response.data.tglPembukaanTabunganUmum)
      );
      setJumlahSetor(response.data.jumlahSetor);
      setNoBuktiSetor(response.data.noBuktiSetor);
      setUsernameApprove(response.data.usernameApprove);
      setTglApprove(
        response.data.tglApprove ? formatDate(response.data.tglApprove) : ""
      );
      setIsApprove(response.data.isApprove);

      setNamaAnggota(response.data.anggotakoperasis.namaAnggota);
      setNoAnggota(response.data.anggotakoperasis.noAnggota);
      setAlamatAnggota(response.data.anggotakoperasis.alamatAnggota);
      setNoTelpAnggota(response.data.anggotakoperasis.noTelp);

      setNamaUnit(response.data.anggotakoperasis.unitAktif.namaUnit);
      setNamaWilayah(
        response.data.anggotakoperasis.unitAktif.wilayah.namaWilayah
      );

      setKodeKartu(response.data.kodekartutabungan.kodeKodeKartuTabungan);
    }
    setLoading(false);
  };

  const batalApprovePembukaanTabunganUmum = async (id) => {
    setLoading(true);
    try {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/batalApprovePembukaanTabunganUmum/${id}`, {
          _id: user.id,
          token: user.token,
        });
        setIsApprove(false);
        setLoading(false);
        alert("Berhasil batal approve pembukaan tabungan umum!");
        navigate(`/pembukaanTabunganUmum`);

        if (urut === "Belum Approve") {
          getPembukaanTabunganUmumsBelumApprove();
        } else if (urut === "Sudah Approve") {
          getPembukaanTabunganUmumsSudahApprove();
        } else {
          getPembukaanTabunganUmums();
        }
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  const deletePembukaanTabunganUmum = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deletePembukaanTabunganUmum/${id}`, {
        noAnggota: noAnggota,
        _id: user.id,
        token: user.token,
      });
      getPembukaanTabunganUmums();
      setTglPembukaanTabunganUmum("");
      setNoRekeningTabunganUmum("");
      setJumlahSetor("");
      setNoBuktiSetor("");
      setUsernameApprove("");
      setTglApprove("");
      setIsApprove("");
      navigate("/pembukaanTabunganUmum");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${noRekeningTabunganUmum} tidak bisa dihapus!`);
      } else {
        alert(error.response.data.message);
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Pembukaan Tabungan Umum`, 85, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Pembukaan Tabungan Umum",
    sheet: "DaftarPembukaanTabunganUmum",
  });

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      {id && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/pembukaanTabunganUmum")}
          sx={{ marginRight: 2 }}
        >
          {"< Kembali"}
        </Button>
      )}
      <h3>Pembukaan Tabungan Umum</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Pembukaan Tabungan Umum</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          {id ? (
            <Button
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => {
                setPreviewTandaTerima(!previewTandaTerima);
              }}
            >
              Cetak Tanda Terima
            </Button>
          ) : (
            <>
              <Button
                color="primary"
                startIcon={<SearchIcon />}
                onClick={() => {
                  setPreviewPdf(!previewPdf);
                  getPembukaanTabunganUmumReportData();
                  setPreviewExcel(false);
                }}
              >
                PDF
              </Button>
              <Button
                color="secondary"
                startIcon={<SearchIcon />}
                onClick={() => {
                  setPreviewExcel(!previewExcel);
                  getPembukaanTabunganUmumReportData();
                  setPreviewPdf(false);
                }}
              >
                Excel
              </Button>
            </>
          )}
        </ButtonGroup>
      </Box>
      {previewTandaTerima && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} id="content" style={pdfContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <b style={{ fontSize: "16px" }}>
                  Tanggal {tglPembukaanTabunganUmum}
                </b>
                <b style={{ fontSize: "16px", marginTop: "12px" }}>
                  Kepada Yth.
                </b>
                <b style={{ fontSize: "16px" }}>
                  Unit Usaha Otonom-Simpan Pinjam Insani
                </b>
                <b style={{ fontSize: "16px" }}>Wilayah {namaWilayah}</b>
              </div>
            </div>
            <h5 style={titleCetak}>
              HAL: PERMOHONAN PEMBUKAAN TABUNGAN UUO-SPI
            </h5>
            <div style={containerContent}>
              <div style={wrapperContent}>
                <p>Yang bertanda tangan di bawah ini,</p>
                <div style={{ marginTop: "-10px" }}>
                  <div
                    style={{
                      width: "600px",
                      marginLeft: "20px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <p>Nama</p>
                      <p style={{ marginLeft: "100px" }}>: </p>
                      <div style={divAnggotaDataCetak}>
                        <p>{namaAnggota}</p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "-10px",
                      }}
                    >
                      <div style={{ width: "150px" }}>
                        <p>Alamat rumah</p>
                      </div>
                      <p style={{ marginLeft: "26px" }}>: </p>
                      <div style={divAnggotaDataCetak}>
                        <p>{alamatAnggota}</p>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <div style={{ width: "150px" }}>
                        <p style={{ visibility: "hidden" }}>hidden</p>
                      </div>
                      <p style={{ marginLeft: "26px", visibility: "hidden" }}>
                        :
                      </p>
                      <div style={divAnggotaDataCetak}>
                        <p style={{ marginLeft: "300px" }}>
                          Telp {noTelpAnggota}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <div style={{ width: "150px" }}>
                        <p>Karyawan di</p>
                      </div>
                      <p style={{ marginLeft: "26px" }}>: </p>
                      <div style={divAnggotaDataCetak}>
                        <p>BPR {namaUnit}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p style={{ marginTop: "-10px" }}>
                  Dengan ini mengajukan permohonan untuk membuka tabungan
                  UUO-SPI, dengan setoran awal sebesar:
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      border: "1px solid black",
                      display: "flex",
                      padding: "5px",
                      height: "30px",
                      width: "700px",
                    }}
                  >
                    <div style={{ width: "300px" }}>
                      <p>Rp. {jumlahSetor.toLocaleString("en-US")}</p>
                    </div>
                    <div>
                      <p>({angkaTerbilang(jumlahSetor)} rupiah)</p>
                    </div>
                  </div>
                </div>
                <p style={{ marginTop: "20px" }}>
                  Atas permohonan kami tersebut di atas, kami sanggup untuk
                  mematuhi peraturan yang ditetapkan oleh UUO-SPI, sebagai
                  berikut:
                  <div style={{ marginLeft: "15px" }}>
                    <thead>
                      <tr>
                        <td scope="col">1.</td>
                        <td scope="col">
                          Setoran awal minimal sebesar Rp 2.500 (dua ribu lima
                          ratus rupiah).
                        </td>
                      </tr>
                      <tr>
                        <td scope="col">
                          <p style={{ marginTop: "-8px" }}>2.</p>
                        </td>
                        <td scope="col">
                          Menyerahkan fotocopi Kartu Pengenal dan membubuhkan
                          tanda tangan yang sesuai dengan yang tercantum di
                          Kartu Pengenal untuk keperluan pencocokan tanda tangan
                          penarikan dana.
                        </td>
                      </tr>
                      <tr>
                        <td scope="col">3.</td>
                        <td scope="col">
                          Setoran selanjutnya minimal Rp 1.000 (seribu rupiah).
                        </td>
                      </tr>
                      <tr>
                        <td scope="col">4.</td>
                        <td scope="col">
                          Menyediakan dana yang cukup atas penarikan dana yang
                          dilakukan.
                        </td>
                      </tr>
                      <tr>
                        <td scope="col">5.</td>
                        <td scope="col">
                          Peraturan-peraturan lain yang diberlakukan oleh
                          UUO-SPI.
                        </td>
                      </tr>
                    </thead>
                  </div>
                </p>
                <p style={{ marginTop: "10px" }}>Demikian permohonan kami.</p>
                <div style={{ marginTop: "20px" }}>
                  <div>
                    <div style={{ marginLeft: "600px" }}>
                      <p>Hormat Kami,</p>
                    </div>
                  </div>
                  <div style={{ marginTop: "-10px", display: "flex" }}>
                    <p style={{ marginLeft: "70px" }}>Diterima oleh,</p>
                    <p style={{ marginLeft: "500px" }}>Pemohon,</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "100px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p>
                        (........................................................)
                      </p>
                      <p>Pelaksana UUO-SPI</p>
                    </div>
                    <p>
                      (........................................................)
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <p>
                    ..............................................................................................................................................................................................
                  </p>
                </div>
                <div>
                  <table
                    style={{
                      border: "1px solid black",
                      width: "100%",
                      marginTop: "40px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          colspan="2"
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          DIISI PEMOHON
                        </th>
                      </tr>
                      <tr>
                        <th
                          colspan="2"
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          SPECIMEN TANDA TANGAN
                        </th>
                      </tr>
                      <tr>
                        <th
                          colspan="2"
                          style={{
                            border: "1px solid black",
                          }}
                        >
                          <p style={{ marginLeft: "250px", height: "10px" }}>
                            Nama :
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <td style={tableTtdPemohon}></td>
                      <td style={tableTtdPemohon}></td>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>Tgl. Pembukaan Tabungan Umum</th>
                <th>No. Rekening</th>
                <th>No. Anggota</th>
                <th>Nama. Anggota</th>
                <th>Jumlah Setor</th>
                <th>No. Bukti Setor</th>
                <th>Kode Kartu Tabungan</th>
              </tr>
            </thead>
            <tbody>
              {pembukaanTabunganUmumReport.map((pembukaan, index) => {
                return (
                  <tr key={pembukaan.id}>
                    <td>{formatDate(pembukaan.tglPembukaanTabunganUmum)}</td>
                    <td>{pembukaan.noRekeningTabunganUmum}</td>
                    <td>{pembukaan.anggotakoperasis.noAnggota}</td>
                    <td>{pembukaan.anggotakoperasis.namaAnggota}</td>
                    <td>{pembukaan.jumlahSetor.toLocaleString("en-US")}</td>
                    <td>{pembukaan.noBuktiSetor}</td>
                    <td>{pembukaan.kodekartutabungan.kodeKodeKartuTabungan}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>Tgl. Pembukaan Tabungan Umum</th>
                <th>No. Rekening</th>
                <th>No. Anggota</th>
                <th>Nama. Anggota</th>
                <th>Jumlah Setor</th>
                <th>No. Bukti Setor</th>
                <th>Kode Kartu Tabungan</th>
              </tr>
              {pembukaanTabunganUmumReport.map((pembukaan, index) => {
                return (
                  <tr key={pembukaan.id}>
                    <td>{formatDate(pembukaan.tglPembukaanTabunganUmum)}</td>
                    <td>{pembukaan.noRekeningTabunganUmum}</td>
                    <td>{pembukaan.anggotakoperasis.noAnggota}</td>
                    <td>{pembukaan.anggotakoperasis.namaAnggota}</td>
                    <td>{pembukaan.jumlahSetor.toLocaleString("en-US")}</td>
                    <td>{pembukaan.noBuktiSetor}</td>
                    <td>{pembukaan.kodekartutabungan.kodeKodeKartuTabungan}</td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/pembukaanTabunganUmum/tambahPembukaanTabunganUmum`}
          editLink={`/pembukaanTabunganUmum/${id}/edit`}
          approveLink={`/pembukaanTabunganUmum/${id}/approve`}
          isApprove={isApprove}
          batalApprove={batalApprovePembukaanTabunganUmum}
          deleteUser={deletePembukaanTabunganUmum}
          nameUser={namaAnggota}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Tgl. :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={tglPembukaanTabunganUmum}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No. Rekening :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control disabled value={noRekeningTabunganUmum} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Anggota:
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={namaAnggota} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No Anggota:
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={noAnggota} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Jumlah Setor :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={jumlahSetor.toLocaleString("en-US")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No. Bukti Setor :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={noBuktiSetor} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Kode Kartu Tabungan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={kodeKartu} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        User Approve :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={usernameApprove}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Tanggal Approve :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={tglApprove} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Form>
        </Container>
      )}
      <hr />
      <FormControl sx={{ marginTop: 1, marginBottom: 2 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Filter</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="Semua"
          value={urut}
          onChange={handleChangeUrut}
        >
          <FormControlLabel value="Semua" control={<Radio />} label="Semua" />
          <FormControlLabel
            value="Belum Approve"
            control={<Radio />}
            label="Belum Approve"
          />
          <FormControlLabel
            value="Sudah Approve"
            control={<Radio />}
            label="Sudah Approve"
          />
        </RadioGroup>
      </FormControl>
      <Box sx={{ mt: 2 }}>
        <Chip
          label="Sudah Approve"
          sx={{
            backgroundColor: Colors.green400,
          }}
        />
      </Box>
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTablePembukaanTabunganUmum currentPosts={pembukaanTabunganUmums} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilPembukaanTabunganUmum;

const spacingTop = {
  mt: 4,
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

const titleCetak = {
  textAlign: "center",
  fontWeight: "bold",
  marginTop: "30px",
};

const noTandaTerimaCetak = {
  textAlign: "center",
  fontWeight: "500",
};

const containerContent = {
  display: "flex",
  marginLeft: "120px",
};

const wrapperContent = {
  marginTop: "20px",
  width: "800px",
  fontSize: "15px",
};

const numberContent = {
  marginLeft: "20px",
  width: "100px",
  display: "flex",
  justifyContent: "right",
};

const thirdNumberContent = {
  display: "flex",
  marginLeft: "20px",
  marginTop: "-15px",
};

const totalContainer = {
  display: "flex",
  marginLeft: "20px",
  marginTop: "-15px",
};

const totalText = {
  marginLeft: "110px",
  marginRight: "46px",
  fontWeight: "bold",
};

const totalWrapper = {
  display: "flex",
  height: "20px",
  borderBottom: "1px solid black",
};

const totalNumber = {
  marginLeft: "20px",
  width: "100px",
  display: "flex",
  justifyContent: "right",
};

const divAnggotaDataCetak = {
  marginLeft: "4px",
  width: "100%",
  height: "20px",
  borderBottom: "1px solid black",
};

const tableTtdPemohon = {
  border: "1px solid black",
  height: "100px",
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
