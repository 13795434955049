import {
  formatDate,
  findTotalDays,
  lastday,
  days_of_a_year,
} from "../../../../constants/helper";
import { useEffect, useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader, SearchBar } from "../../../../components";
import { Colors } from "../../../../constants/styles";
import {
  Box,
  Paper,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import { Container, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { useDownloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const KartuTabunganUmum = () => {
  const tableRef = useRef(null);
  const reportTemplateRef = useRef(null);
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const [noRekeningTabunganUmum, setNoRekeningTabunganUmum] = useState("");
  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");

  const [namaWilayah, setNamaWilayah] = useState("");

  const [anggotaKoperasis, setAnggotaKoperasis] = useState([]);
  const [kartuTabunganUmums, setKartuTabunganUmums] = useState([]);

  const [searchTermAnggotaKoperasi, setSearchTermAnggotaKoperasi] =
    useState("");
  const [openAnggotaKoperasi, setOpenAnggotaKoperasi] = useState(false);

  const [dariTanggal, setDariTanggal] = useState(new Date());
  const [sampaiTanggal, setSampaiTanggal] = useState(new Date());

  const [previewExcel, setPreviewExcel] = useState(false);
  const [previewLaporan, setPreviewLaporan] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermAnggotaKoperasi(query);
  };

  const handleClickOpenAnggotaKoperasi = () => {
    setOpenAnggotaKoperasi(true);
  };

  const handleCloseAnggotaKoperasi = () => {
    setOpenAnggotaKoperasi(false);
  };

  useEffect(() => {
    getAnggotaKoperasisFirstTime();
  }, []);

  useEffect(() => {
    getAnggotaKoperasis();
  }, [page, searchTermAnggotaKoperasi]);

  const getAnggotaKoperasisFirstTime = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/anggotaKoperasisBukaRekeningPagination?search_query=${searchTermAnggotaKoperasi}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTermAnggotaKoperasi);
      setAnggotaKoperasis(response.data.anggotaKoperasis);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getAnggotaKoperasis = async () => {
    try {
      const response = await axios.post(
        `${tempUrl}/anggotaKoperasisBukaRekeningPagination?search_query=${searchTermAnggotaKoperasi}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTermAnggotaKoperasi);
      setAnggotaKoperasis(response.data.anggotaKoperasis);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      setIsFetchError(true);
    }
  };

  const tampilPdfRekap = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let response = await axios.post(`${tempUrl}/kartuTabunganUmums`, {
        noRekeningTabunganUmum: noRekeningTabunganUmum,
        dariTanggal,
        sampaiTanggal,
        _id: user.id,
        token: user.token,
      });
      setKartuTabunganUmums(response.data);
      setPreviewLaporan(!previewLaporan);
      setLoading(false);
    }
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const list = [];
  let tempSaldo = 0;

  for (let i = 0; i < kartuTabunganUmums.length; i++) {
    if (
      kartuTabunganUmums[i].kodekartutabungan.jenisKodeKartuTabungan ===
      "KREDIT"
    ) {
      tempSaldo += parseInt(kartuTabunganUmums[i].jumlah);
    } else {
      tempSaldo -= parseInt(kartuTabunganUmums[i].jumlah);
    }

    let tglKartuTabungan = new Date();

    if (kartuTabunganUmums[i].tglSetor) {
      tglKartuTabungan = new Date(kartuTabunganUmums[i].tglSetor);
    } else if (kartuTabunganUmums[i].tglTarik) {
      tglKartuTabungan = new Date(kartuTabunganUmums[i].tglTarik);
    } else {
      tglKartuTabungan = new Date(
        kartuTabunganUmums[i].tglPembukaanTabunganUmum
      );
    }

    let hariBulan = 0;

    if (kartuTabunganUmums[i + 1]) {
      // Cari Selisih Hari dengan hari transaksi selanjutnya
      let tglKartuTabunganNext = new Date();

      if (kartuTabunganUmums[i + 1].tglSetor) {
        tglKartuTabunganNext = new Date(kartuTabunganUmums[i + 1].tglSetor);
      } else if (kartuTabunganUmums[i + 1].tglTarik) {
        tglKartuTabunganNext = new Date(kartuTabunganUmums[i + 1].tglTarik);
      } else {
        tglKartuTabunganNext = new Date(
          kartuTabunganUmums[i + 1].tglPembukaanTabunganUmum
        );
      }

      hariBulan = findTotalDays(tglKartuTabunganNext, tglKartuTabungan);
    } else {
      // Cari Selisih Hari dengan hari akhir bulan
      let tglKartuTabunganNext = new Date(
        `${tglKartuTabungan.getFullYear()}-${
          tglKartuTabungan.getMonth() + 1
        }-${lastday(
          tglKartuTabungan.getFullYear(),
          tglKartuTabungan.getMonth()
        )}`
      );

      hariBulan = findTotalDays(tglKartuTabunganNext, tglKartuTabungan);
    }

    let perhitunganBunga =
      ((((tempSaldo * setting.sukuBungaTabunganUmum) /
        100 /
        days_of_a_year(tglKartuTabungan.getFullYear())) *
        hariBulan) /
        5) *
      5;

    list.push(
      <tr style={tableText}>
        <th style={tableBorder}>{formatDate(tglKartuTabungan)}</th>
        <th style={tableBorder}>
          {kartuTabunganUmums[i].kodekartutabungan.kodeKodeKartuTabungan}
        </th>
        <th style={tableBorder}>
          {`${kartuTabunganUmums[i].kodekartutabungan.namaKodeKartuTabungan} - ${kartuTabunganUmums[i].noBukti}`}
        </th>
        {kartuTabunganUmums[i].jumlahSetor ? (
          <>
            <th style={tableBorder}></th>
            <th style={tableBorder}>
              {kartuTabunganUmums[i].jumlah.toLocaleString("en-US")}
            </th>
          </>
        ) : (
          <>
            <th style={tableBorder}>
              {kartuTabunganUmums[i].jumlah.toLocaleString("en-US")}
            </th>
            <th style={tableBorder}></th>
          </>
        )}
        <th style={tableBorder}>{tempSaldo.toLocaleString("en-US")}</th>
        <th style={tableBorder}>{hariBulan.toLocaleString("en-US")}</th>
        <th style={tableBorder}>
          {parseInt(perhitunganBunga).toLocaleString("en-US")}
        </th>
      </tr>
    );
  }

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Tabungan Umum</h3>
      <h5 style={{ fontWeight: 400 }}>Kartu Tabungan Umum</h5>
      <hr />
      <Box sx={spacingTop}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Dari Tanggal <b style={colorRed}>*</b> :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  required
                  dateFormat="dd/MM/yyyy"
                  selected={dariTanggal}
                  customInput={<Form.Control required />}
                  onChange={(date) => {
                    setDariTanggal(date);
                    setPreviewExcel(false);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Sampai Tanggal <b style={colorRed}>*</b> :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  required
                  dateFormat="dd/MM/yyyy"
                  selected={sampaiTanggal}
                  customInput={<Form.Control required />}
                  onChange={(date) => {
                    setSampaiTanggal(date);
                    setPreviewExcel(false);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Anggota Koperasi <b style={colorRed}>*</b> :
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  value={namaAnggota}
                  readOnly
                  placeholder="Pilih..."
                  onClick={() => handleClickOpenAnggotaKoperasi()}
                  isInvalid={noAnggota.length === 0 && true}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <ButtonGroup variant="contained" style={{ marginTop: "20px" }}>
          <Button startIcon={<SearchIcon />} onClick={tampilPdfRekap}>
            PDF
          </Button>
        </ButtonGroup>
      </Box>

      <div style={{ marginTop: "10px" }}>
        {previewLaporan && (
          <div>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={handleGeneratePdf}
            >
              CETAK
            </Button>
            <div ref={reportTemplateRef} id="content" style={pdfContainer}>
              <div style={containerContent}>
                <div style={wrapperContent}>
                  <h5 style={titleCetak}>KARTU TABUNGAN UUO-SPI</h5>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <b>Wilayah {namaWilayah}</b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "40px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ width: "380px" }}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "100px" }}>
                          <b>No. Tab</b>
                        </div>
                        <b style={{ marginLeft: "20px" }}>:</b>
                        <div style={divAnggotaDataCetak}>
                          <p>{noRekeningTabunganUmum}</p>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "100px" }}>
                          <b>Nama</b>
                        </div>
                        <b style={{ marginLeft: "20px" }}>:</b>
                        <div style={divAnggotaDataCetak}>
                          <p>{namaAnggota}</p>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "100px" }}>
                          <b>Catatan</b>
                        </div>
                        <b style={{ marginLeft: "20px" }}>:</b>
                        <div style={divAnggotaDataCetak}></div>
                      </div>
                    </div>
                    <div style={{ width: "400px" }}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "150px" }}>
                          <b>Suku Bunga</b>
                        </div>
                        <b style={{ marginLeft: "20px" }}>:</b>
                        <div style={divAnggotaDataCetak}>
                          <p>{setting.sukuBungaTabunganUmum}</p>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "150px" }}>
                          <b>No. Anggota</b>
                        </div>
                        <b style={{ marginLeft: "20px" }}>:</b>
                        <div style={divAnggotaDataCetak}>
                          <p>{noAnggota}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <tbody id="table">
                    <tr>
                      <th style={tableBorder} rowspan="2">
                        TGL
                      </th>
                      <th style={tableBorder} rowspan="2">
                        KODE
                      </th>
                      <th style={tableBorder} rowspan="2">
                        KETERANGAN
                      </th>
                      <th style={tableBorder} colspan="2">
                        MUTASI
                      </th>
                      <th style={tableBorder} rowspan="2">
                        SALDO
                      </th>
                      <th style={tableBorder} rowspan="2">
                        HB
                      </th>
                      <th style={tableBorder} rowspan="2">
                        PERHIT. BUNGA
                      </th>
                    </tr>
                    <tr>
                      <th style={tableBorder}>DEBET</th>
                      <th style={tableBorder}>KREDIT</th>
                    </tr>
                    {list}
                  </tbody>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={openAnggotaKoperasi}
        onClose={handleCloseAnggotaKoperasi}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Anggota Koperasi`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Rekening
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anggotaKoperasis?.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNoAnggota(user.noAnggota);
                        setNamaAnggota(user.namaAnggota);
                        setNoRekeningTabunganUmum(user.noRekeningTabunganUmum);
                        setNamaWilayah(user.unitAktif.wilayah.namaWilayah);

                        handleCloseAnggotaKoperasi();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noRekeningTabunganUmum}
                      </TableCell>
                      <TableCell>{user.namaAnggota}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default KartuTabunganUmum;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableBorderTitle = {
  textAlign: "left",
};

const tableBorderSubTitle = {
  textAlign: "left",
};

const tableBorder = {
  border: "1px solid black",
  textAlign: "center",
  minWidth: "100px",
};

const tableBorderTextRight = {
  border: "1px solid black",
  textAlign: "right",
  minWidth: "100px",
};

const tableBorderTextRightColor = {
  border: "1px solid black",
  textAlign: "right",
  backgroundColor: "#96D4D4",
  minWidth: "100px",
};

const tableBorderColor = {
  border: "1px solid black",
  backgroundColor: "#96D4D4",
  textAlign: "left",
};

const pdfContainer = {
  padding: "20px",
  marginLeft: "70px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

const containerContent = {
  display: "flex",
};

const wrapperContent = {
  marginTop: "20px",
  width: "800px",
  fontSize: "15px",
};

const titleCetak = {
  textAlign: "center",
  fontWeight: "bold",
  marginTop: "20px",
};

const divAnggotaDataCetak = {
  marginLeft: "4px",
  width: "100%",
  height: "20px",
  borderBottom: "1px solid black",
};
