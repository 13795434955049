import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableShuSimpananWajib } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { formatDate } from "../../../constants/helper";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Box, Pagination, Button, ButtonGroup } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const TampilShuSimpananWajib = () => {
  const tableRef = useRef(null);
  const { user, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [tglShuSimpananWajib, setTglShuSimpananWajib] = useState(new Date());
  const [noShuSimpananWajib, setNoShuSimpananWajib] = useState("");
  const [shuSimpananWajib, setShuSimpananWajib] = useState("");

  const [shuSimpananWajibReport, setShuSimpananWajibReport] = useState([]);

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [shuSimpananWajibs, setShuSimpananWajibs] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");
  const PER_PAGE = 20;

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id && getShuSimpananWajibsById();
  }, [id]);

  useEffect(() => {
    getShuSimpananWajibs();
  }, [page, searchTerm]);

  const getShuSimpananWajibs = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/shuSimpananWajibsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setShuSimpananWajibs(response.data.shuSimpananWajibs);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getShuSimpananWajibReportData = async () => {
    try {
      const response = await axios.post(`${tempUrl}/shuSimpananWajibs`, {
        _id: user.id,
        token: user.token,
      });
      setShuSimpananWajibReport(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const getShuSimpananWajibsById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/shuSimpananWajibs/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoShuSimpananWajib(response.data.noShuSimpananWajib);
      setTglShuSimpananWajib(formatDate(response.data.tglShuSimpananWajib));
      setShuSimpananWajib(
        response.data.shuSimpananWajib.toLocaleString("en-US")
      );
    }
    setLoading(false);
  };

  const deleteShuSimpananWajib = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteShuSimpananWajib/${id}`, {
        _id: user.id,
        token: user.token,
      });
      getShuSimpananWajibs();
      setTglShuSimpananWajib("");
      setNoShuSimpananWajib("");
      setShuSimpananWajib("");
      navigate("/shuSimpananWajib");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(
          `${tglShuSimpananWajib} tidak bisa dihapus karena sudah ada data!`
        );
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar SHU Simpanan Wajib`, 75, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "ShuSimpananWajib",
    sheet: "DaftarShuSimpananWajib",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      <h3>Simpan</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar SHU Simpanan Wajib</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              setPreviewPdf(!previewPdf);
              getShuSimpananWajibReportData();
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              setPreviewExcel(!previewExcel);
              getShuSimpananWajibReportData();
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>No</th>
                <th>Tanggal</th>
                <th>Saldo</th>
              </tr>
            </thead>
            <tbody>
              {shuSimpananWajibReport.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.noShuSimpananWajib}</td>
                  <td>{formatDate(user.tglShuSimpananWajib)}</td>
                  <td>{user.shuSimpananWajib.toLocaleString("en-US")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>No</th>
                <th>Tanggal</th>
                <th>Saldo</th>
              </tr>
              {shuSimpananWajibReport.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.noShuSimpananWajib}</td>
                  <td>{user.tglShuSimpananWajib}</td>
                  <td>{user.shuSimpananWajib}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/shuSimpananWajib/tambahShuSimpananWajib`}
          editLink={`/shuSimpananWajib/${id}/edit`}
          deleteUser={deleteShuSimpananWajib}
          nameUser={tglShuSimpananWajib}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    No. :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={noShuSimpananWajib}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Tanggal :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={tglShuSimpananWajib}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Saldo :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={shuSimpananWajib} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableShuSimpananWajib currentPosts={shuSimpananWajibs} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilShuSimpananWajib;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};
