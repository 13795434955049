import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader, SearchBar } from "../../../../components";
import { FetchErrorHandling } from "../../../../components/FetchErrorHandling";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Colors } from "../../../../constants/styles";
import {
  Box,
  Paper,
  Alert,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NumericFormat } from "react-number-format";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahPembukaanTabunganUmum = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const [tglPembukaanTabunganUmum, setTglPembukaanTabunganUmum] = useState("");
  const [noRekeningTabunganUmum, setNoRekeningTabunganUmum] = useState("");
  const [jumlahSetor, setJumlahSetor] = useState("");
  const [noBuktiSetor, setNoBuktiSetor] = useState("");
  const [kodeKartu, setKodeKartu] = useState("");
  const [hariBulan, setHariBulan] = useState(0);

  const [batasMinValidasiPembukaanTab, setBatasMinValidasiPembukaanTab] = useState(
    `${setting.batasMinValidasiPembukaanTab}`
  );

  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");

  const [anggotaKoperasis, setAnggotaKoperasis] = useState([]);
  const [kodeKartuTabungans, setKodeKartuTabungans] = useState([]);

  const [searchTermAnggotaKoperasi, setSearchTermAnggotaKoperasi] = useState("");
  const [openAnggotaKoperasi, setOpenAnggotaKoperasi] = useState(false);

  const [searchTermKodeKartuTabungan, setSearchTermKodeKartuTabungan] = useState("");
  const [openKodeKartuTabungan, setOpenKodeKartuTabungan] = useState(false);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  let [pageKodeTabungan, setPageKodeTabungan] = useState(0);
  const [limitKodeTabungan, setLimitKodeTabungan] = useState(10);
  const [pagesKodeTabungan, setPagesKodeTabungan] = useState(0);
  const [rowsKodeTabungan, setRowsKodeTabungan] = useState(0);
  const [queryKodeTabungan, setQueryKodeTabungan] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const handleChangeKodeTabungan = (e, p) => {
    setPageKodeTabungan(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermAnggotaKoperasi(query);
  };

  const searchDataKodeTabungan = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermKodeKartuTabungan(queryKodeTabungan);
  };

  const handleClickOpenAnggotaKoperasi = () => {
    setOpenAnggotaKoperasi(true);
  };

  const handleCloseAnggotaKoperasi = () => {
    setOpenAnggotaKoperasi(false);
  };

  const handleClickOpenKodeKartuTabungan = () => {
    setOpenKodeKartuTabungan(true);
  };

  const handleCloseKodeKartuTabungan = () => {
    setOpenKodeKartuTabungan(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const lastday = (tglSetor) => {
    let year = tglSetor.getFullYear();
    let month = tglSetor.getMonth();

    let date = new Date(year, month + 1, 0);
    return date.getDate();
  };

  useEffect(() => {
    getAnggotaKoperasisFirstTime();
  }, []);

  useEffect(() => {
    getKodeKartuTabunganFirstTime();
  }, []);

  useEffect(() => {
    getAnggotaKoperasis();
  }, [page, searchTermAnggotaKoperasi]);

  useEffect(() => {
    getKodeKartuTabungan();
  }, [pageKodeTabungan, searchTermKodeKartuTabungan]);

  const getAnggotaKoperasisFirstTime = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/anggotaKoperasisBlmBukaRekeningPagination?search_query=${searchTermAnggotaKoperasi}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTermAnggotaKoperasi);
      setAnggotaKoperasis(response.data.anggotaKoperasis);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getAnggotaKoperasis = async () => {
    try {
      const response = await axios.post(
        `${tempUrl}/anggotaKoperasisBlmBukaRekeningPagination?search_query=${searchTermAnggotaKoperasi}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTermAnggotaKoperasi);
      setAnggotaKoperasis(response.data.anggotaKoperasis);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const getKodeKartuTabunganFirstTime = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/kodeKartuTabungansPagination?search_query=${searchTermKodeKartuTabungan}&page=${pageKodeTabungan}&limit=${limitKodeTabungan}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQueryKodeTabungan(searchTermKodeKartuTabungan);
      setKodeKartuTabungans(response.data.kodeKartuTabungans);
      setPageKodeTabungan(response.data.page);
      setPagesKodeTabungan(response.data.totalPage);
      setRowsKodeTabungan(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getKodeKartuTabungan = async () => {
    try {
      const response = await axios.post(
        `${tempUrl}/kodeKartuTabungansPagination?search_query=${searchTermKodeKartuTabungan}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQueryKodeTabungan(searchTermKodeKartuTabungan);
      setKodeKartuTabungans(response.data.kodeKartuTabungans);
      setPageKodeTabungan(response.data.page);
      setPagesKodeTabungan(response.data.totalPage);
      setRowsKodeTabungan(response.data.totalRows);
    } catch (error) {
      setIsFetchError(true);
    }
  };

  const getPembukaanTabunganUmumNextKode = async () => {
    try {
      const response = await axios.post(`${tempUrl}/pembukaanTabunganUmumNextKode`, {
        _id: user.id,
        token: user.token,
      });
      setNoRekeningTabunganUmum(response.data);
    } catch (error) {
      setIsFetchError(true);
    }
  };

  const getNoBuktiSetoranNextKode = async (noAnggota) => {
    try {
      const response = await axios.post(`${tempUrl}/buktiSetorTabunganUmumNextKode`, {
        noAnggota: noAnggota,
        tglSetor: new Date(),
        _id: user.id,
        token: user.token,
      });
      setNoBuktiSetor(response.data);
    } catch (error) {
      setIsFetchError(true);
    }
  };

  const savePembukaanTabunganUmum = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    if (form.checkValidity() && noRekeningTabunganUmum.length != 0 && tglPembukaanTabunganUmum !== null) {
      setLoading(true);

      try {
        setLoading(true);

        let isApprove = false;
        if (parseInt(jumlahSetor.replace(/,/g, "")) <= parseInt(batasMinValidasiPembukaanTab)) {
          isApprove = true;
        }

        await axios.post(`${tempUrl}/savePembukaanTabunganUmum`, {
          noRekeningTabunganUmum,
          tglPembukaanTabunganUmum,
          noAnggota,
          jumlahSetor: jumlahSetor.replace(/,/g, ""),
          hariBulan,
          noBuktiSetor,
          kodeKartu,
          isApprove: isApprove,
          tglApprove: isApprove === true && tglPembukaanTabunganUmum,
          userIdApprove: isApprove === true && user.id,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/pembukaanTabunganUmum");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Pembukaan Tabungan Umum</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Pembukaan Tabungan Umum</h5>
      <hr />
      <Card>
        <Card.Header>Pembukaan Tabungan Umum</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={savePembukaanTabunganUmum}>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    Tanggal <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      customInput={<Form.Control required />}
                      selected={tglPembukaanTabunganUmum}
                      onChange={(date) => {
                        setTglPembukaanTabunganUmum(date);
                        setHariBulan(lastday(date));
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    No. Anggota <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={noAnggota}
                      readOnly
                      placeholder="Pilih..."
                      onClick={() => {
                        handleClickOpenAnggotaKoperasi();
                      }}
                      isInvalid={noAnggota.length === 0 && true}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    Nama Anggota :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={namaAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    No. Rekening Tabungan Umum <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      disabled
                      required
                      value={noRekeningTabunganUmum}
                      onChange={(e) => setNoRekeningTabunganUmum(e.target.value.toUpperCase())}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    Jumlah Setor <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <NumericFormat
                      required
                      value={jumlahSetor}
                      thousandSeparator
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setJumlahSetor(values.formattedValue);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    No. Bukti Setor <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      disabled
                      required
                      value={noBuktiSetor}
                      onChange={(e) => setNoBuktiSetor(e.target.value.toUpperCase())}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="5" style={textRight}>
                    Kode Kartu Tabungan UUO-SPI <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={kodeKartu}
                      readOnly
                      placeholder="Pilih..."
                      onClick={() => handleClickOpenKodeKartuTabungan()}
                      isInvalid={kodeKartu.length === 0 && true}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/pembukaanTabunganUmum")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button variant="contained" startIcon={<SaveIcon />} type="submit">
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openAnggotaKoperasi}
        onClose={handleCloseAnggotaKoperasi}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Anggota Koperasi`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button variant="contained" type="submit" color="primary" disableElevation>
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} className={classes.tableRightBorder}>
                      No.
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anggotaKoperasis.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNoAnggota(user.noAnggota);
                        setNamaAnggota(user.namaAnggota);
                        getPembukaanTabunganUmumNextKode(user.noAnggota);
                        getNoBuktiSetoranNextKode(user.noAnggota);
                        handleCloseAnggotaKoperasi();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noAnggota}
                      </TableCell>
                      <TableCell>{user.namaAnggota}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openKodeKartuTabungan}
        onClose={handleCloseKodeKartuTabungan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Kode Kartu Tabungan`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchDataKodeTabungan}>
              <Box sx={searchBarContainer}>
                <SearchBar value={queryKodeTabungan} setSearchTerm={setQueryKodeTabungan} />
                <Button variant="contained" type="submit" color="primary" disableElevation>
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} className={classes.tableRightBorder}>
                      Kode Kartu Tabungan
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama Kode Kartu Tabungan</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kodeKartuTabungans.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setKodeKartu(user.kodeKodeKartuTabungan);
                        handleCloseKodeKartuTabungan();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.kodeKodeKartuTabungan}
                      </TableCell>
                      <TableCell>{user.namaKodeKartuTabungan}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pagesKodeTabungan}
                page={pageKodeTabungan + 1}
                onChange={handleChangeKodeTabungan}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TambahPembukaanTabunganUmum;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
