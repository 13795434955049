import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader, SearchBar } from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Colors } from "../../../constants/styles";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Paper,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahMutasiUnit = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noSkMutasiUnit, setNoSkMutasiUnit] = useState("");
  const [tglMutasiUnit, setTglMutasiUnit] = useState(null);
  const [simpananPokokMutasiUnit, setSimpananPokokMutasiUnit] = useState(0);
  const [simpananWajibMutasiUnit, setSimpananWajibMutasiUnit] = useState(0);
  const [totalMutasiUnit, setTotalMutasiUnit] = useState(0);
  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");
  const [unitLama, setUnitLama] = useState("");
  const [kodeUnitBaru, setKodeUnitBaru] = useState("");

  const [anggotaKoperasis, setAnggotaKoperasis] = useState([]);
  const [units, setUnits] = useState([]);

  const [searchTermAnggotaKoperasi, setSearchTermAnggotaKoperasi] =
    useState("");
  const [openAnggotaKoperasi, setOpenAnggotaKoperasi] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermAnggotaKoperasi(query);
  };

  const handleClickOpenAnggotaKoperasi = () => {
    setOpenAnggotaKoperasi(true);
  };

  const handleCloseAnggotaKoperasi = () => {
    setOpenAnggotaKoperasi(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const unitOption = units.map((unit) => ({
    label: `${unit.kodeUnit} - ${unit.namaUnit}`,
  }));

  useEffect(() => {
    getUnits();
  }, []);

  useEffect(() => {
    getAnggotaKoperasis();
  }, [page, searchTermAnggotaKoperasi]);

  const getAnggotaKoperasis = async () => {
    try {
      const response = await axios.post(
        `${tempUrl}/anggotaKoperasisPagination?search_query=${searchTermAnggotaKoperasi}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTermAnggotaKoperasi);
      setAnggotaKoperasis(response.data.anggotaKoperasis);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const getUnits = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/units`, {
        _id: user.id,
        token: user.token,
      });
      setUnits(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getMutasiUnitNextKode = async (noAnggota) => {
    try {
      const response = await axios.post(`${tempUrl}/mutasiUnitNextKode`, {
        noAnggota,
        tglMutasiUnit,
        _id: user.id,
        token: user.token,
      });
      setNoSkMutasiUnit(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const getSumSimpananWajibChildsByAnggota = async (
    anggotaKoperasiId,
    simpananPokok
  ) => {
    try {
      // Find Total Simpanan Wajib Anggota (filter tanggal)
      const findTotalSimpananWajibAnggota = await axios.post(
        `${tempUrl}/sumSimpananWajibChildsByAnggota`,
        {
          anggotaKoperasiId,
          tglKeluar: tglMutasiUnit,
          tglRapatTahunan: setting.tglRapatTahunan,
          _id: user.id,
          token: user.token,
        }
      );

      let tempTotalSimpananWajib =
        findTotalSimpananWajibAnggota.data["SUM(`simpananWajib`)"];

      if (!tempTotalSimpananWajib) {
        tempTotalSimpananWajib = 0;
      }

      setSimpananWajibMutasiUnit(tempTotalSimpananWajib);
      setTotalMutasiUnit(simpananPokok + tempTotalSimpananWajib);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const getMutasiUnitNextKodeByTglMutasiUnit = async (tglMutasiUnit) => {
    try {
      const response = await axios.post(`${tempUrl}/mutasiUnitNextKode`, {
        noAnggota,
        tglMutasiUnit,
        _id: user.id,
        token: user.token,
      });
      setNoSkMutasiUnit(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
  };

  const saveMutasiUnit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      noAnggota.length != 0 &&
      tglMutasiUnit !== null &&
      kodeUnitBaru.length != 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveMutasiUnit`, {
          noSkMutasiUnit,
          tglMutasiUnit,
          simpananPokokMutasiUnit,
          simpananWajibMutasiUnit,
          noAnggota,
          kodeUnitBaru,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/mutasiUnit");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Mutasi Anggota</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Mutasi Unit</h5>
      <hr />
      <Card>
        <Card.Header>Mutasi Unit</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveMutasiUnit}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tanggal <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      customInput={<Form.Control required />}
                      selected={tglMutasiUnit}
                      onChange={(date) => {
                        setTglMutasiUnit(date);
                        getMutasiUnitNextKodeByTglMutasiUnit(date);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Anggota <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noAnggota}
                      readOnly
                      placeholder="Pilih..."
                      onClick={() => handleClickOpenAnggotaKoperasi()}
                      isInvalid={noAnggota.length === 0 && true}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Anggota :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaAnggota} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Simpanan Pokok :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={simpananPokokMutasiUnit.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Simpanan Wajib :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={simpananWajibMutasiUnit.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalMutasiUnit.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Unit Lama :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={unitLama} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Unit Baru <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={unitOption}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          error={error && kodeUnitBaru.length === 0 && true}
                          helperText={
                            error &&
                            kodeUnitBaru.length === 0 &&
                            "Unit Baru harus diisi!"
                          }
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeUnitBaru(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. SK <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={noSkMutasiUnit}
                      onChange={(e) =>
                        setNoSkMutasiUnit(e.target.value.toUpperCase())
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/mutasiUnit")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openAnggotaKoperasi}
        onClose={handleCloseAnggotaKoperasi}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Anggota Koperasi`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No.
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {anggotaKoperasis.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNoAnggota(user.noAnggota);
                        setNamaAnggota(user.namaAnggota);
                        setUnitLama(
                          `${user.unitAktif.kodeUnit} - ${user.unitAktif.namaUnit}`
                        );
                        setSimpananPokokMutasiUnit(user.simpananPokok);
                        getMutasiUnitNextKode(user.noAnggota);
                        getSumSimpananWajibChildsByAnggota(
                          user.id,
                          user.simpananPokok
                        );

                        handleCloseAnggotaKoperasi();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noAnggota}
                      </TableCell>
                      <TableCell>{user.namaAnggota}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TambahMutasiUnit;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
