import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableSimpananWajib } from "../../../components/ShowTable";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import {
  Box,
  Typography,
  Divider,
  Pagination,
  ButtonGroup,
  Button,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Container, Col, Row, Form } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

const TampilSimpananWajib = () => {
  const { user, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [noBuktiSetor, setNoBuktiSetor] = useState("");
  const [tglSimpananWajib, setTglSimpananWajib] = useState("");
  const [kodeUnit, setKodeUnit] = useState("");
  const [totalSimpananWajib, setTotalSimpananWajib] = useState("");
  const [bulanTahunSimpananWajib, setBulanTahunSimpananWajib] = useState("");

  const [simpananWajibReport, setSimpananWajibReport] = useState([]);

  const [previewPdf, setPreviewPdf] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [simpananWajibChildsData, setSimpananWajibChildsData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    getSimpananWajibById();
  }, [id]);

  useEffect(() => {
    getSimpananWajibChild();
  }, [page, searchTerm]);

  const getSimpananWajibById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/simpananWajibs/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoBuktiSetor(response.data.noBuktiSetor);
      setTglSimpananWajib(formatDate(response.data.tglSimpananWajib));
      setKodeUnit(response.data.unit);
      setTotalSimpananWajib(response.data.totalSimpananWajib);

      let tempTglSimpananWajib = new Date(response.data.tglSimpananWajib);
      let tempTodayMonth;
      switch (tempTglSimpananWajib.getMonth() + 1) {
        case 1:
          tempTodayMonth = "JANUARI";
          break;
        case 2:
          tempTodayMonth = "FEBRUARI";
          break;
        case 3:
          tempTodayMonth = "MARET";
          break;
        case 4:
          tempTodayMonth = "APRIL";
          break;
        case 5:
          tempTodayMonth = "MEI";
          break;
        case 6:
          tempTodayMonth = "JUNI";
          break;
        case 7:
          tempTodayMonth = "JULI";
          break;
        case 8:
          tempTodayMonth = "AGUSTUS";
          break;
        case 9:
          tempTodayMonth = "SEPTEMBER";
          break;
        case 10:
          tempTodayMonth = "OKTOBER";
          break;
        case 11:
          tempTodayMonth = "NOVEMBER";
          break;
        case 12:
          tempTodayMonth = "DESEMBER";
          break;
        default:
          break;
      }
      setBulanTahunSimpananWajib(
        `${tempTodayMonth} ${tempTglSimpananWajib.getFullYear()}`
      );
    }
    setLoading(false);
  };

  const getSimpananWajibChild = async () => {
    if (id) {
      const response2 = await axios.post(
        `${tempUrl}/simpananWajibChildsPaginationBySimpananWajib?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          simpananWajibId: id,
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setSimpananWajibChildsData(response2.data.simpananWajibChilds);
      setPage(response2.data.page);
      setPages(response2.data.totalPage);
      setRows(response2.data.totalRows);
    }
  };

  const getSimpananChildData = async () => {
    const response = await axios.post(
      `${tempUrl}/simpananWajibChildsBySimpananWajib`,
      {
        simpananWajibId: id,
        _id: user.id,
        token: user.token,
      }
    );
    setSimpananWajibReport(response.data);
  };

  const deleteSimpananWajib = async (id) => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/deleteSimpananWajib/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setLoading(false);
      navigate("/daftarSimpananWajib");
    } catch (error) {
      console.log(error);
    }
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  if (loading) {
    return <Loader />;
  }

  const tableText = {
    letterSpacing: "0.01px",
  };

  const list = [];
  let tempTotalSimpok = 0;
  let tempTotalSimwa = 0;
  let tempTotalUut = 0;
  let tempTotalAll = 0;

  for (let i = 0; i < simpananWajibReport.length; i++) {
    let tempTglMasuk = new Date(
      simpananWajibReport[i].anggotakoperasis.tglMasuk
    );
    let tempTglSimpananWajib = new Date(
      simpananWajibReport[i].simpananwajib.tglSimpananWajib
    );

    let tempSimpananPokok = 0;
    // If TglMasuk == TglSimpananWajib
    if (
      `${tempTglMasuk.getFullYear()}-${tempTglMasuk.getMonth() + 1}` ===
      `${tempTglSimpananWajib.getFullYear()}-${
        tempTglSimpananWajib.getMonth() + 1
      }`
    ) {
      tempSimpananPokok = simpananWajibReport[i].anggotakoperasis.simpananPokok;
    }

    let tempTotal = simpananWajibReport[i].simpananWajib + tempSimpananPokok;

    list.push(
      <tr style={tableText}>
        <td style={tableBorderTextLeft}>{i + 1}</td>
        <td style={tableBorderTextLeft}>
          {simpananWajibReport[i].anggotakoperasis.noAnggota}
        </td>
        <td style={tableBorderTextLeft}>
          {simpananWajibReport[i].anggotakoperasis.namaAnggota}
        </td>
        <td style={tableBorderTextRight}>
          {tempSimpananPokok.toLocaleString("en-US")}
        </td>
        <td style={tableBorderTextRight}>
          {simpananWajibReport[i].simpananWajib.toLocaleString("en-US")}
        </td>
        <td style={tableBorderTextRight}>0</td>
        <td style={tableBorderTextRight}>
          {tempTotal.toLocaleString("en-US")}
        </td>
      </tr>
    );

    tempTotalSimpok += tempSimpananPokok;
    tempTotalSimwa += simpananWajibReport[i].simpananWajib;
    tempTotalUut += 0;
    tempTotalAll += tempTotal;
  }

  list.push(
    <tr style={tableText}>
      <td></td>
      <td></td>
      <td></td>
      <td style={tableBorderTextRightBold}>
        {tempTotalSimpok.toLocaleString("en-US")}
      </td>
      <td style={tableBorderTextRightBold}>
        {tempTotalSimwa.toLocaleString("en-US")}
      </td>
      <td style={tableBorderTextRightBold}>
        {tempTotalUut.toLocaleString("en-US")}
      </td>
      <td style={tableBorderTextRightBold}>
        {tempTotalAll.toLocaleString("en-US")}
      </td>
    </tr>
  );

  return (
    <Container>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarSimpananWajib")}
        sx={{ marginRight: 2 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Simpan</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Simpanan Wajib
        </Typography>
        <Box sx={downloadButtons}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button
              color="primary"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPreviewPdf(!previewPdf);
                getSimpananChildData();
              }}
            >
              PDF
            </Button>
          </ButtonGroup>
        </Box>
        {previewPdf && (
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="outlined"
              startIcon={<PrintIcon />}
              onClick={handleGeneratePdf}
            >
              CETAK
            </Button>

            <div ref={reportTemplateRef} id="content" style={pdfContainer}>
              <p style={cetakTitle}>{kodeUnit.namaUnit}</p>
              <p style={cetakTitle}>
                SETORAN SIMPANAN POKOK DAN SIMPANAN WAJIB {setting.namaPerusahaan}
              </p>
              <p style={cetakTitle}>BULAN : {bulanTahunSimpananWajib}</p>
              <table class="styled-table">
                <thead>
                  <tr>
                    <th style={tableBorder} rowspan="2">
                      No
                    </th>
                    <th style={tableBorder} rowspan="2">
                      NIK
                    </th>
                    <th style={tableBorder} rowspan="2">
                      Nama Karyawan
                    </th>
                    <th style={tableBorder} colspan="4">
                      Pemotongan
                    </th>
                  </tr>
                  <tr>
                    <th style={tableBorder}>Simpok</th>
                    <th style={tableBorder}>Simwa</th>
                    <th style={tableBorder}>UUT</th>
                    <th style={tableBorder}>Total</th>
                  </tr>
                </thead>
                <tbody>{list}</tbody>
              </table>
              <table class="styled-table" style={{ width: "40%" }}>
                <thead>
                  <tr style={tableText}>
                    <th style={tableBorder}>Dibuat</th>
                    <th style={tableBorder}>Diperiksa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: "100px" }}>
                    <th></th>
                    <th rowspan="4"></th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        <Box sx={buttonModifierContainer}>
          <ButtonModifier
            id={id}
            kode={"test"}
            addLink={`/daftarSimpananWajib/simpananWajib/${id}/tambahSimpananWajibChild`}
            deleteUser={deleteSimpananWajib}
            nameUser={noBuktiSetor}
          />
        </Box>
        <Divider sx={dividerStyle} />
        <Divider sx={{ marginBottom: 2 }} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={noBuktiSetor}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Tgl. Simpanan Wajib :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={tglSimpananWajib}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Kode Unit :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={`${kodeUnit.kodeUnit} - ${kodeUnit.namaUnit}`}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Total Simpanan Wajib :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      value={totalSimpananWajib.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
        <Divider sx={dividerStyle} />
        <Form onSubmit={searchData}>
          <Box sx={searchBarContainer}>
            <SearchBar value={query} setSearchTerm={setQuery} />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disableElevation
            >
              Cari
            </Button>
          </Box>
        </Form>
        <Box sx={tableContainer}>
          <ShowTableSimpananWajib
            id={id}
            currentPosts={simpananWajibChildsData}
          />
        </Box>
        <Box sx={tableContainer}>
          <Pagination
            count={pages}
            page={page + 1}
            onChange={handleChange}
            color="primary"
            size={screenSize <= 600 ? "small" : "large"}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default TampilSimpananWajib;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const cetakTitle = {
  fontWeight: "bold",
  fontSize: "15px",
};

const tableBorder = {
  border: "1px solid black",
  textAlign: "center",
  minWidth: "100px",
};

const tableBorderTextLeft = {
  border: "1px solid black",
  textAlign: "left",
  minWidth: "100px",
};

const tableBorderTextRight = {
  border: "1px solid black",
  textAlign: "right",
  minWidth: "100px",
};

const tableBorderTextRightBold = {
  border: "1px solid black",
  textAlign: "right",
  minWidth: "100px",
  fontWeight: "bold",
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};
