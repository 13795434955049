import { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../contexts/AuthContext';
import { tempUrl, useStateContext } from '../../../contexts/ContextProvider';
import { Loader } from '../../../components';
import { FetchErrorHandling } from '../../../components/FetchErrorHandling';
import { formatDate } from '../../../constants/helper';
import { Container, Card, Form, Row, Col } from 'react-bootstrap';
import { Box, Alert, Button, Autocomplete, TextField, Snackbar } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { NumericFormat } from 'react-number-format';

const UbahAnggotaKoperasiKeluar = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noAnggota, setNoAnggota] = useState('');
  const [namaAnggota, setNamaAnggota] = useState('');
  const [noRekeningBpr, setNoRekeningBpr] = useState('');
  const [noRekeningSimpanPinjam, setNoRekeningSimpanPinjam] = useState('');
  const [noTelp, setNoTelp] = useState('');
  const [tglMasuk, setTglMasuk] = useState(new Date());
  const [simpananWajib, setSimpananWajib] = useState('');
  const [simpananPokok, setSimpananPokok] = useState('');
  const [unitAwal, setUnitAwal] = useState('');
  const [unitAktif, setUnitAktif] = useState('');

  // Detail Data Anggota
  const [tempatLahirAnggota, setTempatLahirAnggota] = useState('');
  const [tglLahirAnggota, setTglLahirAnggota] = useState('');
  const [jenisKelaminAnggota, setJenisKelaminAnggota] = useState('');
  const [golonganDarahAnggota, setGolonganDarahAnggota] = useState('');
  const [alamatAnggota, setAlamatAnggota] = useState('');
  const [rtAnggota, setRtAnggota] = useState('');
  const [rwAnggota, setRwAnggota] = useState('');
  const [kelurahanAnggota, setKelurahanAnggota] = useState('');
  const [kecamatanAnggota, setKecamatanAnggota] = useState('');
  const [agamaAnggota, setAgamaAnggota] = useState('');
  const [statusPerkawinanAnggota, setStatusPerkawinanAnggota] = useState('');
  const [kewarganegaraanAnggota, setKewarganegaraanAnggota] = useState('');

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const jenisKelaminOption = ['LAKI-LAKI', 'PEREMPUAN'];
  const golonganDarahOption = ['A', 'B', 'AB', 'O'];
  const agamaOption = ['ISLAM', 'KRISTEN', 'KATOLIK', 'HINDU', 'BUDDHA', 'KHONGHUCU'];
  const statusperkawinanOption = ['KAWIN', 'BELUM KAWIN'];
  const kewarganegaraanOption = ['WNI', 'WNA'];

  useEffect(() => {
    getAnggotaKoperasiById();
  }, []);

  const getAnggotaKoperasiById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/anggotaKoperasis/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setNoAnggota(response.data.noAnggota);
      setNamaAnggota(response.data.namaAnggota);
      setNoRekeningBpr(response.data.noRekeningBpr);
      setNoRekeningSimpanPinjam(response.data.noRekeningSimpanPinjam);
      setNoTelp(response.data.noTelp);
      setTglMasuk(new Date(response.data.tglMasuk));
      setSimpananWajib(response.data.simpananWajib.toLocaleString('en-US'));
      setSimpananPokok(response.data.simpananPokok.toLocaleString('en-US'));
      setUnitAwal(response.data.unitAwal.namaUnit);
      setUnitAktif(response.data.unitAktif.namaUnit);

      // Detail Data Anggota
      setTempatLahirAnggota(response.data.tempatLahirAnggota);
      if (formatDate(response.data.tglLahirAnggota).substring(0, 3) !== 'NaN') {
        setTglLahirAnggota(new Date(response.data.tglLahirAnggota));
      }
      setJenisKelaminAnggota(response.data.jenisKelaminAnggota);
      setGolonganDarahAnggota(response.data.golonganDarahAnggota);
      setAlamatAnggota(response.data.alamatAnggota);
      setRtAnggota(response.data.rtAnggota);
      setRwAnggota(response.data.rwAnggota);
      setKelurahanAnggota(response.data.kelurahanAnggota);
      setKecamatanAnggota(response.data.kecamatanAnggota);
      setAgamaAnggota(response.data.agamaAnggota);
      setStatusPerkawinanAnggota(response.data.statusPerkawinanAnggota);
      setKewarganegaraanAnggota(response.data.kewarganegaraanAnggota);
    }
  };

  const updateAnggotaKoperasi = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    if (simpananWajib.length === 0) {
      alert(`Simpanan Wajib belum diisi!`);
      return;
    }

    if (simpananPokok.length === 0) {
      alert(`Simpanan Pokok belum diisi!`);
      return;
    }

    if (form.checkValidity() && tglMasuk !== null) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateAnggotaKoperasi/${id}`, {
            noAnggota,
            namaAnggota,
            noRekeningBpr,
            noRekeningSimpanPinjam,
            noTelp,
            tglMasuk,
            simpananWajib: simpananWajib.replace(/,/g, ''),
            simpananPokok: simpananPokok.replace(/,/g, ''),

            // Detail Data Anggota
            tempatLahirAnggota,
            tglLahirAnggota,
            jenisKelaminAnggota,
            golonganDarahAnggota,
            alamatAnggota,
            rtAnggota,
            rwAnggota,
            kelurahanAnggota,
            kecamatanAnggota,
            agamaAnggota,
            statusPerkawinanAnggota,
            kewarganegaraanAnggota,

            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/anggotaKoperasiKeluar/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && 'right',
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Anggota Koperasi Keluar</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={updateAnggotaKoperasi}>
        <Card>
          <Card.Header>Anggota Koperasi</Card.Header>
          <Card.Body>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        No. Anggota <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control required value={noAnggota} onChange={(e) => setNoAnggota(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Nama Anggota <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control required value={namaAnggota} onChange={(e) => setNamaAnggota(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        No. Rekening BPR <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control required value={noRekeningBpr} onChange={(e) => setNoRekeningBpr(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        No. Rekening SimTab UUO-SPI <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control required value={noRekeningSimpanPinjam} onChange={(e) => setNoRekeningSimpanPinjam(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        No. Telepon (HP) :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={noTelp} onChange={(e) => setNoTelp(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Tanggal Masuk <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <DatePicker dateFormat="dd/MM/yyyy" customInput={<Form.Control required />} selected={tglMasuk} onChange={(date) => setTglMasuk(date)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Simpanan Wajib <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <NumericFormat
                          value={simpananWajib}
                          thousandSeparator
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setSimpananWajib(values.formattedValue);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Simpanan Pokok <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <NumericFormat
                          value={simpananPokok}
                          thousandSeparator
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setSimpananPokok(values.formattedValue);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Unit Awal <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={unitAwal} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Unit Aktif <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={unitAktif} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: '15px' }}>
          <Card.Header>Detail Data Anggota</Card.Header>
          <Card.Body>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Nama Anggota :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control required value={namaAnggota} onChange={(e) => setNamaAnggota(e.target.value.toUpperCase())} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Tempat/Tgl. Lahir :
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control value={tempatLahirAnggota} onChange={(e) => setTempatLahirAnggota(e.target.value.toUpperCase())} />
                      </Col>
                      <Col sm="4">
                        <DatePicker dateFormat="dd/MM/yyyy" customInput={<Form.Control required />} selected={tglLahirAnggota} onChange={(date) => setTglLahirAnggota(date)} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={jenisKelaminOption}
                          value={jenisKelaminAnggota}
                          renderInput={(params) => <TextField size="small" {...params} />}
                          onInputChange={(e, value) => {
                            setJenisKelaminAnggota(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Golongan Darah :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={golonganDarahOption}
                          value={golonganDarahAnggota}
                          renderInput={(params) => <TextField size="small" {...params} />}
                          onInputChange={(e, value) => {
                            setGolonganDarahAnggota(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Agama :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={agamaOption}
                          value={agamaAnggota}
                          renderInput={(params) => <TextField size="small" {...params} />}
                          onInputChange={(e, value) => {
                            setAgamaAnggota(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={statusperkawinanOption}
                          value={statusPerkawinanAnggota}
                          renderInput={(params) => <TextField size="small" {...params} />}
                          onInputChange={(e, value) => {
                            setStatusPerkawinanAnggota(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="5" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="7">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={kewarganegaraanOption}
                          value={kewarganegaraanAnggota}
                          renderInput={(params) => <TextField size="small" {...params} />}
                          onInputChange={(e, value) => {
                            setKewarganegaraanAnggota(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={alamatAnggota} onChange={(e) => setAlamatAnggota(e.target.value.toUpperCase())} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            RT/RW :
                          </Form.Label>
                          <Col sm="3">
                            <Form.Control value={rtAnggota} onChange={(e) => setRtAnggota(e.target.value.toUpperCase())} />
                          </Col>
                          <Col sm="5">
                            <Form.Control value={rwAnggota} onChange={(e) => setRwAnggota(e.target.value.toUpperCase())} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Kel/Desa :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={kelurahanAnggota} onChange={(e) => setKelurahanAnggota(e.target.value.toUpperCase())} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                          <Form.Label column sm="5" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control value={kecamatanAnggota} onChange={(e) => setKecamatanAnggota(e.target.value.toUpperCase())} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>
        <Box sx={spacingTop}>
          <Button variant="outlined" color="secondary" onClick={() => navigate(`/anggotaKoperasiKeluar/${id}`)} sx={{ marginRight: 2 }}>
            {'< Kembali'}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahAnggotaKoperasiKeluar;

const colorRed = {
  color: 'red',
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: '100%',
};

const showDataContainer = {
  mt: 4,
  display: 'flex',
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
};

const showDataWrapper = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  maxWidth: {
    md: '40vw',
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
