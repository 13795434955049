import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";

const UbahUnit = () => {
  const [isFetchError, setIsFetchError] = useState(false);
  const { screenSize } = useStateContext();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [namaWilayah, setNamaWilayah] = useState("");
  const [namaUnit, setNamaUnit] = useState("");
  const [kodeUnit, setKodeUnit] = useState("");
  const [tipeUnit, setTipeUnit] = useState("");
  const [telepon, setTelepon] = useState("");
  const [fax, setFax] = useState("");
  const [wilayahs, setWilayahs] = useState([]);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getUnitsById();
    getWilayahs();
  }, []);

  const tipeUnitOption = ["KANTOR PUSAT", "KANTOR CABANG", "KANTOR KAS"];

  const getWilayahs = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/wilayahs`, {
        _id: user.id,
        token: user.token,
      });
      setWilayahs(response.data);
    } catch (err) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getUnitsById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/units/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeUnit(response.data.kodeUnit);
      setNamaUnit(response.data.namaUnit);
      setTipeUnit(response.data.tipeUnit);
      setNamaWilayah(response.data.wilayah.namaWilayah);
      setTelepon(response.data.telepon);
      setFax(response.data.fax);
    }
  };

  const updateUnit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && tipeUnit.length != 0) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateUnit/${id}`, {
            namaUnit,
            tipeUnit,
            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/unit/${id}`);
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      } catch (error) {
        alert(error);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const wilayahOption = wilayahs.map((wil) => ({
    id: wil.id,
    label: `${wil.id} - ${wil.namaWilayah}`,
  }));

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Unit</h5>
      <hr />
      <Card>
        <Card.Header>Unit</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateUnit}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Wilayah :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control disabled value={namaWilayah} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Unit :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control disabled value={kodeUnit} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama Unit <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={namaUnit}
                      onChange={(e) =>
                        setNamaUnit(e.target.value.toUpperCase())
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tipe Unit <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={tipeUnitOption}
                      defaultValue={tipeUnit}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          error={error && tipeUnit.length === 0 && true}
                          helperText={
                            error &&
                            tipeUnit.length === 0 &&
                            "Tipe Unit harus diisi!"
                          }
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setTipeUnit(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Telepon :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="number"
                      value={telepon}
                      onChange={(e) => setTelepon(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Fax :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="number"
                      value={fax}
                      onChange={(e) => setFax(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/unit")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahUnit;

const colorRed = {
  color: "red",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};
