import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { ShowTableSetoranTabunganUmum } from "../../../../components/ShowTable";
import { FetchErrorHandling } from "../../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../../components";
import { formatDate, formatTime } from "../../../../constants/helper";
import { Colors } from "../../../../constants/styles";
import { Container, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Pagination,
  Button,
  ButtonGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Chip,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const TampilSetoranTabunganUmum = () => {
  const tableRef = useRef(null);
  const reportTemplateRef = useRef(null);
  const { user, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [tglSetor, setTglSetor] = useState("");
  const [noRekeningTabunganUmum, setNoRekeningTabunganUmum] = useState("");
  const [jumlahSetor, setJumlahSetor] = useState("");
  const [noBuktiSetor, setNoBuktiSetor] = useState("");
  const [kodeKartu, setKodeKartu] = useState("");
  const [hariBulan, setHariBulan] = useState(0);
  const [isKolektif, setIsKolektif] = useState(false);
  const [usernameApprove, setUsernameApprove] = useState("");
  const [tglApprove, setTglApprove] = useState("");
  const [isApprove, setIsApprove] = useState(false);
  const [createdAt, setCreatedAt] = useState("");

  const [namaKodeKartuTabungan, setNamaKodeKartuTabungan] = useState("");

  const [noAnggota, setNoAnggota] = useState("");
  const [namaAnggota, setNamaAnggota] = useState("");

  const [namaWilayah, setNamaWilayah] = useState("");
  const [namaUnit, setNamaUnit] = useState("");

  const [setoranTabunganUmums, setSetoranTabunganUmums] = useState([]);
  const [setoranTabunganUmumsKolektif, setSetoranTabunganUmumsKolektif] =
    useState([]);

  const [setoranTabunganUmumReport, setSetoranTabunganUmumReport] = useState(
    []
  );
  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [previewTandaTerima, setPreviewTandaTerima] = useState(false);
  const [previewValidasi, setPreviewValidasi] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const [urut, setUrut] = useState("Semua");

  const handleChangeUrut = (event) => {
    setUrut(event.target.value);
  };

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    getSetoranTabunganUmumById();
    getSetoranTabunganUmumsKolektif();
  }, [id]);

  useEffect(() => {
    if (urut === "Belum Approve") {
      getSetoranTabunganUmumsBelumApprove();
    } else if (urut === "Sudah Approve") {
      getSetoranTabunganUmumsSudahApprove();
    } else {
      getSetoranTabunganUmums();
    }
  }, [page, searchTerm, urut]);

  const getSetoranTabunganUmums = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/setoranTabunganUmumsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setSetoranTabunganUmums(response.data.setoranTabunganUmums);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getSetoranTabunganUmumsSudahApprove = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/setoranTabunganUmumsSudahApprove?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setSetoranTabunganUmums(response.data.setoranTabunganUmums);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getSetoranTabunganUmumsBelumApprove = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/setoranTabunganUmumsBelumApprove?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setSetoranTabunganUmums(response.data.setoranTabunganUmums);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getSetoranTabunganUmumReportData = async () => {
    try {
      const response = await axios.post(`${tempUrl}/setoranTabunganUmums`, {
        _id: user.id,
        token: user.token,
      });
      setSetoranTabunganUmumReport(response.data);
    } catch (error) {
      setIsFetchError(true);
    }
  };

  const getSetoranTabunganUmumById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(
        `${tempUrl}/setoranTabunganUmums/${id}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setNoRekeningTabunganUmum(response.data.noRekeningTabunganUmum);

      setTglSetor(formatDate(response.data.tglSetor));
      setJumlahSetor(response.data.jumlahSetor);
      setNoBuktiSetor(response.data.noBuktiSetor);
      setHariBulan(response.data.hariBulan);
      setCreatedAt(new Date(response.data.createdAt));
      setUsernameApprove(response.data.usernameApprove);
      setTglApprove(
        response.data.tglApprove ? formatDate(response.data.tglApprove) : ""
      );
      setIsApprove(response.data.isApprove);

      setNamaAnggota(response.data.anggotakoperasis.namaAnggota);
      setNoAnggota(response.data.anggotakoperasis.noAnggota);

      setNamaUnit(response.data.anggotakoperasis.unitAktif.namaUnit);
      setNamaWilayah(
        response.data.anggotakoperasis.unitAktif.wilayah.namaWilayah
      );

      setKodeKartu(response.data.kodekartutabungan.kodeKodeKartuTabungan);
      setNamaKodeKartuTabungan(
        response.data.kodekartutabungan.namaKodeKartuTabungan
      );
      setIsKolektif(response.data.isKolektif);
    }
    setLoading(false);
  };

  const getSetoranTabunganUmumsKolektif = async () => {
    if (id) {
      const response2 = await axios.post(
        `${tempUrl}/setoranTabunganUmumsKolektif`,
        {
          setorId: id,
          noBuktiSetor,
          _id: user.id,
          token: user.token,
        }
      );
      setSetoranTabunganUmumsKolektif(response2.data.setoranTabunganUmums);
    }
  };

  const batalApproveSetoranTabunganUmum = async (id) => {
    setLoading(true);
    try {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/batalApproveSetoranTabunganUmum/${id}`, {
          _id: user.id,
          token: user.token,
        });
        setIsApprove(false);
        setLoading(false);
        alert("Berhasil batal approve setoran tabungan umum!");
        navigate(`/setoranTabunganUmum`);

        if (urut === "Belum Approve") {
          getSetoranTabunganUmumsBelumApprove();
        } else if (urut === "Sudah Approve") {
          getSetoranTabunganUmumsSudahApprove();
        } else {
          getSetoranTabunganUmums();
        }
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  const deleteSetoranTabunganUmum = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteSetoranTabunganUmum/${id}`, {
        noBuktiSetor: noBuktiSetor,
        noAnggota: noAnggota,
        _id: user.id,
        token: user.token,
      });
      getSetoranTabunganUmums();
      setTglSetor("");
      setNoRekeningTabunganUmum("");
      setJumlahSetor("");
      setNoBuktiSetor("");
      navigate("/setoranTabunganUmum");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${noRekeningTabunganUmum} tidak bisa dihapus!`);
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Setoran Tabungan Umum`, 85, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Setoran Tabungan Umum",
    sheet: "SetoranPembukaanTabunganUmum",
  });

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.44 },
    });
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container>
      {id && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            navigate("/setoranTabunganUmum");
            setIsKolektif(false);
          }}
          sx={{ marginRight: 2 }}
        >
          {"< Kembali"}
        </Button>
      )}
      <h3>Setoran Tabungan Umum</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Setoran Tabungan Umum</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          {id ? (
            <>
              <Button
                color="primary"
                startIcon={<PrintIcon />}
                onClick={() => {
                  setPreviewTandaTerima(!previewTandaTerima);
                  if (previewValidasi) {
                    setPreviewValidasi(!previewValidasi);
                  }
                }}
              >
                Cetak Tanda Terima
              </Button>
              {isApprove && (
                <Button
                  color="secondary"
                  startIcon={<PrintIcon />}
                  onClick={() => {
                    setPreviewValidasi(!previewValidasi);
                    if (previewTandaTerima) {
                      setPreviewTandaTerima(!previewTandaTerima);
                    }
                  }}
                >
                  Cetak Validasi
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                color="primary"
                startIcon={<SearchIcon />}
                onClick={() => {
                  setPreviewPdf(!previewPdf);
                  getSetoranTabunganUmumReportData();
                  setPreviewExcel(false);
                }}
              >
                PDF
              </Button>
              <Button
                color="secondary"
                startIcon={<SearchIcon />}
                onClick={() => {
                  setPreviewExcel(!previewExcel);
                  getSetoranTabunganUmumReportData();
                  setPreviewPdf(false);
                }}
              >
                Excel
              </Button>
            </>
          )}
        </ButtonGroup>
      </Box>
      {previewTandaTerima && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} id="content" style={pdfContainer}>
            <div style={containerContent}>
              <div style={wrapperContent}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <b>UNIT USAHA OTONOM - SIMPAN PINJAM INSANI</b>
                  <b>Wilayah: {namaWilayah}</b>
                </div>
                <h5 style={titleCetak}>SLIP PENYETORAN UANG</h5>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ width: "400px" }}>
                    <div style={{ display: "flex" }}>
                      <p>Tanggal</p>
                      <p style={{ marginLeft: "50px" }}>: {tglSetor}</p>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <p>No. Tabungan</p>
                      <p style={{ marginLeft: "10px" }}>
                        : {noRekeningTabunganUmum}
                      </p>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <p>Nama</p>
                      <p style={{ marginLeft: "62px" }}>: {namaAnggota}</p>
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid black",
                      padding: "10px",
                      width: "350px",
                    }}
                  >
                    <div>
                      <p>
                        Setoran tunai: Rp {jumlahSetor.toLocaleString("en-US")}
                      </p>
                      <p style={{ marginTop: "-10px" }}>
                        Terbilang : {angkaTerbilang(jumlahSetor)} rupiah
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Diterima oleh</p>
                    <p style={{ marginTop: "-10px" }}>UUO-SPI,</p>
                    <p style={{ marginTop: "50px" }}>
                      (........................................................)
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Penyetor,</p>
                    <p style={{ marginTop: "50px" }}>
                      (........................................................)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {previewValidasi && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} id="content" style={pdfContainer}>
            <div style={containerContentRight}>
              <div style={wrapperContent}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                      <b>Validasi: </b>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                      }}
                    >
                      <b>
                        {formatDate(createdAt.toISOString().split("T")[0])}{" "}
                        {formatTime(createdAt)} {noBuktiSetor} {namaAnggota}
                      </b>
                      <b>
                        {kodeKartu} {namaKodeKartuTabungan}
                      </b>
                      <b>Rp {jumlahSetor.toLocaleString("en-US")},00</b>
                    </div>
                  </div>
                  <b style={{ marginTop: "10px" }}>oleh: {user.username}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>Tgl. Setoran</th>
                <th>No. Rekening</th>
                <th>No. Anggota</th>
                <th>Nama. Anggota</th>
                <th>Jumlah Setor</th>
                <th>No. Bukti Setor</th>
                <th>Hari Bulan</th>
                <th>Kode Kartu Tabungan</th>
              </tr>
            </thead>
            <tbody>
              {setoranTabunganUmumReport.map((setoran, index) => {
                return (
                  <tr key={setoran.id}>
                    <td>{formatDate(setoran.tglSetor)}</td>
                    <td>{setoran.noRekeningTabunganUmum}</td>
                    <td>{setoran.anggotakoperasis.noAnggota}</td>
                    <td>{setoran.anggotakoperasis.namaAnggota}</td>
                    <td>{setoran.jumlahSetor.toLocaleString("en-US")}</td>
                    <td>{setoran.noBuktiSetor}</td>
                    <td>{setoran.hariBulan}</td>
                    <td>{setoran.kodekartutabungan.kodeKodeKartuTabungan}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>Tgl. Setoran</th>
                <th>No. Rekening</th>
                <th>No. Anggota</th>
                <th>Nama. Anggota</th>
                <th>Jumlah Setor</th>
                <th>No. Bukti Setor</th>
                <th>Hari Bulan</th>
                <th>Kode Kartu Tabungan</th>
              </tr>
              {setoranTabunganUmumReport.map((setoran, index) => {
                return (
                  <tr key={setoran.id}>
                    <td>{formatDate(setoran.tglSetor)}</td>
                    <td>{setoran.noRekeningTabunganUmum}</td>
                    <td>{setoran.anggotakoperasis.noAnggota}</td>
                    <td>{setoran.anggotakoperasis.namaAnggota}</td>
                    <td>{setoran.jumlahSetor.toLocaleString("en-US")}</td>
                    <td>{setoran.noBuktiSetor}</td>
                    <td>{setoran.hariBulan}</td>
                    <td>{setoran.kodekartutabungan.kodeKodeKartuTabungan}</td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonGroup variant="contained">
          <Button
            color="success"
            sx={{ bgcolor: "success.light", textTransform: "none" }}
            startIcon={<AddCircleOutlineIcon />}
            size="small"
            onClick={() => {
              navigate(`/setoranTabunganUmum/tambahSetoranTabunganUmum`);
            }}
          >
            Tambah
          </Button>
          <Button
            color="info"
            sx={{ bgcolor: "info.light", textTransform: "none" }}
            startIcon={<AddCircleOutlineIcon />}
            size="small"
            onClick={() => {
              navigate(
                `/setoranTabunganUmum/tambahSetoranTabunganUmumKolektif`
              );
            }}
          >
            Tambah Setoran Kolektif
          </Button>
        </ButtonGroup>
      </Box>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          editLink={`/setoranTabunganUmum/${id}/edit`}
          approveLink={`/setoranTabunganUmum/${id}/approve`}
          isApprove={isApprove}
          batalApprove={batalApproveSetoranTabunganUmum}
          deleteUser={deleteSetoranTabunganUmum}
          nameUser={namaAnggota}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Tanggal :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={tglSetor} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No. Rekening :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control disabled value={noRekeningTabunganUmum} />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Nama Anggota:
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={namaAnggota} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No Anggota:
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={noAnggota} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Jumlah Setor :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={jumlahSetor.toLocaleString("en-US")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        No. Bukti Setor :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={noBuktiSetor} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Hari Bulan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={hariBulan} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Kode Kartu Tabungan :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={kodeKartu} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        User Approve :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control
                          value={usernameApprove}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="5" style={textRight}>
                        Tanggal Approve :
                      </Form.Label>
                      <Col sm="7">
                        <Form.Control value={tglApprove} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Form>
        </Container>
      )}
      <hr />
      {!id && (
        <>
          <FormControl sx={{ marginTop: 1, marginBottom: 2 }}>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Filter
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              defaultValue="Semua"
              value={urut}
              onChange={handleChangeUrut}
            >
              <FormControlLabel
                value="Semua"
                control={<Radio />}
                label="Semua"
              />
              <FormControlLabel
                value="Belum Approve"
                control={<Radio />}
                label="Belum Approve"
              />
              <FormControlLabel
                value="Sudah Approve"
                control={<Radio />}
                label="Sudah Approve"
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            <Chip
              label="Sudah Approve"
              sx={{
                backgroundColor: Colors.green400,
              }}
            />
          </Box>
          <Form onSubmit={searchData}>
            <Box sx={searchBarContainer}>
              <SearchBar value={query} setSearchTerm={setQuery} />
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disableElevation
              >
                Cari
              </Button>
            </Box>
          </Form>
          <Box sx={tableContainer}>
            <ShowTableSetoranTabunganUmum currentPosts={setoranTabunganUmums} />
          </Box>
          <Box sx={tableContainer}>
            <Pagination
              count={pages}
              page={page + 1}
              onChange={handleChange}
              color="primary"
              size={screenSize <= 600 ? "small" : "large"}
            />
          </Box>
        </>
      )}
      {id && isKolektif && (
        <>
          <FormControl sx={{ marginTop: 1, marginBottom: 2 }}>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Filter
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              defaultValue="Semua"
              value={urut}
              onChange={handleChangeUrut}
            >
              <FormControlLabel
                value="Semua"
                control={<Radio />}
                label="Semua"
              />
              <FormControlLabel
                value="Belum Approve"
                control={<Radio />}
                label="Belum Approve"
              />
              <FormControlLabel
                value="Sudah Approve"
                control={<Radio />}
                label="Sudah Approve"
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            <Chip
              label="Sudah Approve"
              sx={{
                backgroundColor: Colors.green400,
              }}
            />
          </Box>
          <Form onSubmit={searchData}>
            <Box sx={searchBarContainer}>
              <SearchBar value={query} setSearchTerm={setQuery} />
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disableElevation
              >
                Cari
              </Button>
            </Box>
          </Form>
          <Box sx={tableContainer}>
            <ShowTableSetoranTabunganUmum
              currentPosts={setoranTabunganUmumsKolektif}
            />
          </Box>
          <Box sx={tableContainer}>
            <Pagination
              count={pages}
              page={page + 1}
              onChange={handleChange}
              color="primary"
              size={screenSize <= 600 ? "small" : "large"}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default TampilSetoranTabunganUmum;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

const titleCetak = {
  textAlign: "center",
  fontWeight: "bold",
  marginTop: "20px",
};

const noTandaTerimaCetak = {
  textAlign: "center",
  fontWeight: "500",
};

const containerContent = {
  display: "flex",
  marginLeft: "120px",
};

const containerContentRight = {
  display: "flex",
  marginLeft: "460px",
  marginTop: "-20px",
};

const wrapperContent = {
  marginTop: "20px",
  width: "800px",
  fontSize: "15px",
};

const numberContent = {
  marginLeft: "20px",
  width: "100px",
  display: "flex",
  justifyContent: "right",
};

const thirdNumberContent = {
  display: "flex",
  marginLeft: "20px",
  marginTop: "-15px",
};

const totalContainer = {
  display: "flex",
  marginLeft: "20px",
  marginTop: "-15px",
};

const totalText = {
  marginLeft: "110px",
  marginRight: "46px",
  fontWeight: "bold",
};

const totalWrapper = {
  display: "flex",
  height: "20px",
  borderBottom: "1px solid black",
};

const totalNumber = {
  marginLeft: "20px",
  width: "100px",
  display: "flex",
  justifyContent: "right",
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
